import React from 'react';

import './CSS/TablePage.scss';

import { AuthContext } from '../context/context';
import { getMonthDetails, getQuarter, getYear } from '../utils/utils';

const TablePage = (props) => {
  const {
    state: {
      revenues, inputs, data, reports,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [miscData, setMiscData] = React.useState(null);

  const [localData, setLocalData] = React.useState([]);
  const sales = inputs.filter((i) => i.title === 'sales')[0];
  const ganda = inputs.filter((i) => i.title === 'ganda')[0];
  const randd = inputs.filter((i) => i.title === 'randd')[0];
  const marketing = inputs.filter((i) => i.title === 'marketing')[0];

  React.useEffect(() => {
    if (data && data.datasets) {
      const newData = data.datasets
        .map((id, i) => id.data
          .filter((prevData, i) => i !== data.labels.length)
          .map((iid) => ({
            value: iid,
            percentage: ((iid * 100) / id.data.filter((iidrevData, i) => i !== data.labels.length).reduce((a, b) => a + b, 0)).toFixed(2),
          })))
        .reduce((arr, o) => {
          o.forEach((obj, i) => {
            arr[i] = { value: ((arr[i] && arr[i].value) || 0) + obj.value, percentage: ((arr[i] && parseFloat(arr[i].percentage)) || 0) + parseFloat(obj.percentage) };
          });
          return arr;
        }, []);

      setLocalData(newData);
    }
  }, []);

  React.useEffect(() => {
    if (data && data.datasets) {
      const newData = data.datasets
        .map((id, i) => id.data
          .filter((prevData, i) => i !== data.labels.length)
          .map((iid) => ({
            value: iid,
            percentage: ((iid * 100) / id.data.filter((iidrevData, i) => i !== data.labels.length).reduce((a, b) => a + b, 0)).toFixed(2),
          })))
        .reduce((arr, o) => {
          o.forEach((obj, i) => {
            arr[i] = { value: ((arr[i] && parseFloat(arr[i].value)) || 0) + parseFloat(obj.value), percentage: ((arr[i] && parseFloat(arr[i].percentage)) || 0) + parseFloat(obj.percentage) };
          });
          return arr;
        }, []);

      setLocalData(newData);
    }
  }, [data]);






  React.useEffect(() => {
    let salesData = [];
    let marketingData = [];
    let gandaData = [];
    let randaData = [];
    if (props.chartValue === 'P&L by Year') {
      salesData = [];
      marketingData = [];
      gandaData = [];
      randaData = [];
      if (sales && sales.inputs) {
        Object.keys(getYear(sales.inputs).total).map((data, id) => salesData.push(`$${parseInt(getYear(sales.inputs).total[data].toFixed(2)).toLocaleString()}.00`));
      }
      if (marketing && marketing.inputs) {
        Object.keys(getYear(marketing.inputs).total).map((data, id) => marketingData.push(`$${parseInt(getYear(marketing.inputs).total[data].toFixed(2)).toLocaleString()}.00`));
      }
      if (ganda && ganda.inputs) {
        Object.keys(getYear(ganda.inputs).total).map((data, id) => gandaData.push(`$${parseInt(getYear(ganda.inputs).total[data].toFixed(2)).toLocaleString()}.00`));
      }
      if (randd && randd.inputs) {
        Object.keys(getYear(randd.inputs).total).map((data, id) => `$${parseInt(getYear(randd.inputs).total[data].toFixed(2)).toLocaleString()}.00`);
      }
    } else if (props.chartValue === 'P&L by Quarter') {
      salesData = [];
      marketingData = [];
      gandaData = [];
      randaData = [];
      if (sales && sales.inputs && sales.inputs.length > 0 && getQuarter(sales.inputs)) {
        Object.keys(getQuarter(sales.inputs).total).map((quarter, id) => salesData.push(`$${parseInt(getQuarter(sales.inputs).total[quarter].toFixed(2)).toLocaleString()}.00`));
      }
      if (marketing && marketing.inputs && marketing.inputs.length > 0 && getQuarter(marketing.inputs)) {
        Object.keys(getQuarter(marketing.inputs).total).map((quarter, id) => marketingData.push(`$${parseInt(getQuarter(marketing.inputs).total[quarter].toFixed(2)).toLocaleString()}.00`));
      }
      if (ganda && ganda.inputs && ganda.inputs.length > 0 && getQuarter(ganda.inputs)) {
        Object.keys(getQuarter(ganda.inputs).total).map((quarter, id) => gandaData.push(`$
						${parseInt(getQuarter(ganda.inputs).total[quarter].toFixed(2)).toLocaleString()}.00
					`));
      }

      if (randd && randd.inputs && randd.inputs.length > 0 && getQuarter(randd.inputs)) {
        Object.keys(getQuarter(randd.inputs).total).map((quarter, id) => randaData.push(`$
						${parseInt(getQuarter(randd.inputs).total[quarter].toFixed(2)).toLocaleString()}.00
					`));
      }
    } else {
      salesData = [];
      marketingData = [];
      gandaData = [];
      randaData = [];
      if (sales && sales.inputs && sales.inputs.length > 0 && getMonthDetails(sales.inputs)) {
        Object.keys(getMonthDetails(sales.inputs).total).map((month, id) => salesData.push(`$${parseInt(getMonthDetails(sales.inputs).total[month].toFixed(2)).toLocaleString()}.00`));
      }

      if (marketing && marketing.inputs && marketing.inputs.length > 0 && getMonthDetails(marketing.inputs)) {
        Object.keys(getMonthDetails(marketing.inputs).total).map((month, id) => marketingData.push(`$
						${parseInt(getMonthDetails(marketing.inputs).total[month].toFixed(2)).toLocaleString()}.00`));
      }

      if (ganda && ganda.inputs && ganda.inputs.length > 0 && getMonthDetails(ganda.inputs)) {
        Object.keys(getMonthDetails(ganda.inputs).total).map((month, id) => gandaData.push(`$
						${parseInt(getMonthDetails(ganda.inputs).total[month].toFixed(2)).toLocaleString()}.00
					`));
      }
      if (randd && randd.inputs && randd.inputs.length > 0 && getMonthDetails(randd.inputs)) {
        Object.keys(getMonthDetails(randd.inputs).total).map((month, id) => randaData.push(`$
						${parseInt(getMonthDetails(randd.inputs).total[month].toFixed(2)).toLocaleString()}.00
					`));
      }
    }


    const data = {
      sales: salesData,
      marketing: marketingData,
      ganda: gandaData,
      randa: randaData,
    };
    setMiscData(data);
  }, [sales, marketing, randd, ganda]);
  return (
    <>
      <div className="table_conatiner">
        <table>
          <tr>
            <th />
            {data && data.labels && data.labels.map((label) => <th key={label}>{label}</th>)}
          </tr>
          <tr>
            <th>Revenue</th>
            {data && data.labels && data.labels.map((label) => <th key={label} />)}
          </tr>
          {data
            && data.datasets
            && data.datasets.map((d, id) => (
              <tr key={id}>
                <td className="headingtd">{d.label}</td>
                {d.data
                  && d.data
                    .filter((prevData, i) => i !== data.labels.length)
                    .map((p) => (
                      <td>
                        <div className="precentageTd">
                          <div className="dataset">
                            $
                            {parseInt(p.toFixed(2)).toLocaleString(2)}
                            .00
                          </div>

                          <div className="precentage">
                            {((p * 100) / d.data.filter((prevData, i) => i !== data.labels.length).reduce((a, b) => a + b, 0)).toFixed(2)}
                            %
                          </div>
                        </div>

                      </td>
                    ))}
              </tr>
            ))}
          <tr>
            <td className="totalheading">Total Revenue</td>

            {localData.map((d) => (
              <td>
                <div className="precentageTd">
                  <div className="total dataset">
                    $
                    {parseInt(d.value).toLocaleString(2)}
                    .00
                  </div>
                  <div className="total precentage">
                    {d.percentage.toFixed()}
                    %
                  </div>
                </div>
              </td>
            ))}
          </tr>

          <br />

          <tr>
            <th>Expenses</th>
            {reports.labels && reports.labels.map((label) => <th />)}
          </tr>

          <tr>
            <th className="totalheading">Sales Hire</th>
            {data && data.labels && data.labels.map((label, id) => (
              <td key={label}>
                <div>{miscData && miscData.sales && miscData.sales[id] ? miscData.sales[id] : '$0'}</div>

              </td>
            ))}
          </tr>
          <tr>
            <th className="totalheading">Marketing Hire</th>
            {data && data.labels && data.labels.map((label, id) => <td key={label}>{miscData && miscData.marketing && miscData.marketing[id] ? miscData.marketing[id] : '$0'}</td>)}
          </tr>
          <tr>
            <th className="totalheading">R&amp;D</th>
            {data && data.labels && data.labels.map((label, id) => <td key={label}>{miscData && miscData.randa && miscData.randa[id] ? miscData.randa[id] : '$0'}</td>)}
          </tr>
          <tr>
            <th className="totalheading">G&amp;A</th>
            {data && data.labels && data.labels.map((label, id) => <td key={label}>{miscData && miscData.ganda && miscData.ganda[id] ? miscData.ganda[id] : '$0'}</td>)}
          </tr>
          <tr>
            <td className="totalheading">Operating Expense</td>
            {reports.datasets
              && reports.datasets.filter((ds) => ds.label === 'Expenses').length > 0
              && reports.datasets.filter((ds) => ds.label === 'Expenses')[0].data
              && reports.datasets
                .filter((ds) => ds.label === 'Expenses')[0]
                .data.map((d) => (
                  <td>
                    <div className="precentageTd">
                      <div className="total">
                        $
                        {parseInt(d.toFixed(2)).toLocaleString(2)}
                        .00
                      </div>
                    </div>
                  </td>
                ))}
          </tr>
          <br />
          <tr>
            <th className="headingtd"> Operating Profit </th>
            {reports.datasets
              && reports.datasets.filter((ds) => ds.label === 'Operating Profit')[0].data
              && reports.datasets
                .filter((ds) => ds.label === 'Operating Profit')[0]
                .data.map((d) => (
                  <td>
                    <div className="precentageTd">
                      <div className="total dataset">
                        $
                        {parseInt(d.toFixed(2)).toLocaleString(2)}
                        .00
                      </div>
                      <div className="total precentage">
                        {((d * 100) / reports.datasets.filter((ds) => ds.label === 'Operating Profit')[0].data.reduce((a, b) => a + b, 0)).toFixed(2)}
                        %
                      </div>
                    </div>
                  </td>
                ))}
          </tr>
          <br />
          <tr>
            <th className="headingtd"> EBIT </th>
            {reports.datasets
              && reports.datasets.filter((ds) => ds.label === 'EBIT')[0].data
              && reports.datasets
                .filter((ds) => ds.label === 'EBIT')[0]
                .data.map((d) => (
                  <td>
                    <div className="precentageTd">
                      <div className="total">
                        $
                        {parseInt(d.toFixed(2)).toLocaleString(2)}
                        .00
                      </div>

                    </div>
                  </td>
                ))}
          </tr>
        </table>
      </div>
    </>
  );
};

export default TablePage;
