/* eslint-disable */
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { AuthContext } from '../../context/context';
import { getInputs, getRevenue } from '../../context/fetch-service';
import { updateGAChurn } from '../../context/revenue-service';


const PREFIX = 'GAandChurnEdit';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '400px' }
}));


export default function GAandChurnEdit(props) {

  const {
    state: {
      switchUser, currentScenario, projects, selectedProject, selectedScenario, revenues,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [grateYearly, setGAYearly] = React.useState(revenues.grateYearly ?? 0);
  const [grateQuarterly, setGAQuaterly] = React.useState(revenues.grateQuarterly ?? 0);
  const [grateMonthly, setGAMonthly] = React.useState(revenues.grateMonthly ?? 0);
  const [crateYearly, setChurnYearly] = React.useState(revenues.crateYearly ?? 0);
  const [crateQuarterly, setChurnQuaterly] = React.useState(revenues.crateQuarterly ?? 0);
  const [crateMonthly, setGChurnMonthly] = React.useState(revenues.crateMonthly ?? 0);

  const updateGAChurnValues = async () => {

    let scenarioId = projects[selectedProject].scenarios[selectedScenario]._id;
    let update = await updateGAChurn(scenarioId, grateYearly, grateQuarterly, grateMonthly, crateYearly, crateQuarterly, crateMonthly);

    const revenues = await getRevenue(switchUser.userName, currentScenario);
    dispatch({
      type: 'SET_REVENUE',
      payload: revenues,
    });
    const inputs = await getInputs(switchUser.userName, currentScenario);

    dispatch({
      type: 'SET_INPUTS',
      payload: inputs,
    });

    props.handleCloseBackDrop();
  };

  const updateGA = (Value, Type) => {
    if (Type === 'Yearly') {
      setGAYearly(Value);
      setGAQuaterly(Value / 4);
      setGAMonthly(Value / 12);
    } else if (Type === 'Quaterly') {
      setGAYearly(Value * 4);
      setGAQuaterly(Value);
      setGAMonthly(Value / 2);
    } else {
      setGAYearly(Value * 12);
      setGAQuaterly(Value * 4);
      setGAMonthly(Value);
    }
  };

  const updateChurn = (Value, Type) => {
    if (Type === 'Yearly') {
      setChurnYearly(Value);
      setChurnQuaterly(Value / 4);
      setGChurnMonthly(Value / 12);
    } else if (Type === 'Quaterly') {
      setChurnYearly(Value * 4);
      setChurnQuaterly(Value);
      setGChurnMonthly(Value / 2);
    } else {
      setChurnYearly(Value * 12);
      setChurnQuaterly(Value * 4);
      setGChurnMonthly(Value);
    }
  };

  return (
    (<Root>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.backDropOpen}
        onClose={props.handleCloseBackDrop}
      >
        <Dialog classes={{ paper: classes.paper }} onClose={props.handleCloseBackDrop} open={props.backDropOpen}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: '75px 75px 75px 75px' }}>
              <h2 style={{
                paddingBottom: '15px', width: '100%', color: '#55616E',
              }}
              >
                {' '}
                Scenario Name -
                {projects[selectedProject].scenarios[selectedScenario].scenarioName}
              </h2>
              <h3 style={{
                paddingBottom: '15px', width: '100%', color: '#55616E',
              }}
              >
                {' '}
                <TrendingUpIcon />
                {' '}
                Projected Growth Variables
                {' '}
              </h3>
              <div className="row">
                <div className="col-sm-4">
                  <TextField
                    suffix="%"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ paddingBottom: '15px', width: '100%' }}
                    onChange={() => updateGA(event.target.value, 'Yearly')}
                    label="Yearly"
                    value={grateYearly}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ paddingBottom: '15px', width: '100%' }}
                    onChange={() => updateGA(event.target.value, 'Quaterly')}
                    label="Quaterly"
                    value={grateQuarterly}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ paddingBottom: '15px', width: '100%' }}
                    onChange={() => updateGA(event.target.value, 'Monthly')}
                    label="Monthly"
                    value={grateMonthly}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
              </div>
              <h3 style={{
                paddingBottom: '15px', width: '100%', color: '#55616E',
              }}
              >
                {' '}
                <TrendingDownIcon />
                {' '}
                Projected Churn Variables
                {' '}
              </h3>
              <div className="row">
                <div className="col-sm-4">
                  <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ paddingBottom: '15px', width: '100%' }}
                    onChange={() => updateChurn(event.target.value, 'Yearly')}
                    label="Yearly"
                    value={crateYearly}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ paddingBottom: '15px', width: '100%' }}
                    onChange={() => updateChurn(event.target.value, 'Quaterly')}
                    label="Quaterly"
                    value={crateQuarterly}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ paddingBottom: '15px', width: '100%' }}
                    onChange={() => updateChurn(event.target.value, 'Monthtly')}
                    label="Monthtly"
                    value={crateMonthly}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </div>
              </div>
              <Button
                style={{
                  paddingTop: '10px', paddingBottom: '10px', marginTop: '50px', width: '100%', background: '#79A1DD',
                }}
                onClick={() => updateGAChurnValues()}
                variant="contained"
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </Dialog>
      </Backdrop>
    </Root>)
  );
}
