/* eslint-disable */
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { AuthContext } from '../../../context/context';
import { getInputs } from '../../../context/fetch-service';
import { addInputs, deleteInputs, updateInputs } from '../../../context/input-service';
import { stateTaxes } from '../Table/Utils';

export default function HiringEntry(props) {
  const { state, dispatch } = React.useContext(AuthContext);
  const { switchUser, currentScenario } = state;

  const [fte, setFte] = React.useState(true);
  const [contract, setContract] = React.useState(false);
  const [inputForm, setInputForm] = React.useState({
    title: '',
    name: '',
    category: '',
    role: '',
    salary: '',
    location: '',
    startDate: '',
    endDate: '',
    taxes: '',
    sharedId: switchUser.userName,
    scenarioId: currentScenario,
  });

  const [updateValue, setUpdateValue] = React.useState(false);



  React.useEffect(() => {
    if (props.value) {
      setInputForm(props.value);
      setUpdateValue(true);
    }
    if (props.title) {
      setInputForm((prevState) => ({
        ...prevState,
        title: props.title,
      }));
    }

  }, []);



  const handleInputFormChange = (e) => {
    const { name, value } = e.target;
    setInputForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "location") {
      setInputForm((prevState) => ({
        ...prevState,
        taxes: stateTaxes[value],
      }));
    }
  };

  const handleRadioChange = (event) => {
    if (event.target.value === 'fte') {
      setFte(true);
      setContract(false);
    } else {
      setContract(true);
      setFte(false);
    }
  };

  function setStartDate(startDate) {
    setInputForm((prevState) => ({
      ...prevState,
      startDate,
    }));
  }
  function setEndDate(endDate) {
    setInputForm((prevState) => ({
      ...prevState,
      endDate,
    }));
  }

  const [panelState, setPanelState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
      return;
    }
    setPanelState({ ...panelState, [anchor]: open });
  };

  const handleSubmit = async () => {
    try {
      if (contract) {
        setInputForm((prevState) => ({
          ...prevState,
          salary: prevState.salary * 40 * 52,
        }));
      }

      if (updateValue) {
        const update = await updateInputs({ inputMainId: props.id, inputId: props.value._id, data: inputForm });
        if (update.status === 200 || update.status === 201) {
          const inputs = await getInputs(switchUser.userName, currentScenario);
          dispatch({
            type: 'SET_INPUTS',
            payload: inputs,
          });

        }

      } else {
        const add = await addInputs(inputForm);
        if (add.status === 200 || add.status === 201) {
          const inputs = await getInputs(switchUser.userName, currentScenario);
          dispatch({
            type: 'SET_INPUTS',
            payload: inputs,
          });

        }

      }
      toggleDrawer('right', false);
    } catch (err) {

    }
  };

  const handleDelete = async () => {
    try {
      const deleteInput = await deleteInputs({ inputMainId: props.id, inputId: props.value._id });
      if (deleteInput.status === 200 || deleteInput.status === 201) {
        const inputs = await getInputs(switchUser.userName, currentScenario);
        dispatch({
          type: 'SET_INPUTS',
          payload: inputs,
        });
      }
      toggleDrawer('right', false);
    } catch (err) {

    }
  };

  const list = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

      <Box
        sx={{ width: 500 }}
        role="sidebar"
      >
        <List style={{ paddingBottom: '0px', paddingTop: '50px', marginBottom: '0px' }}>
          <div className="row" style={{ padding: '0px 50px 50px 50px' }}>
            <div className="row">
              <div className="col-sm-11">
                <h2>
                  {props.type}
                  {' '}
                  Entry
                </h2>
              </div>
              <div className="col-sm-1">
                {updateValue &&
                  <Button
                    onClick={() => handleDelete()}
                  >
                    <DeleteIcon />
                  </Button>
                }
              </div>
            </div>
            <div className="pt-3">
              <FormControl>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="fte"
                    control={<Radio checked={fte} size="small" />}
                    label={(
                      <Typography sx={{ fontSize: 12 }}>
                        Full Time
                      </Typography>
                    )}
                  />
                  <FormControlLabel
                    value="contract"
                    control={<Radio checked={contract} size="small" />}
                    label={(
                      <Typography sx={{ fontSize: 12 }}>
                        Contractors
                      </Typography>
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="pt-3">
              <TextField
                suffix="%"
                style={{ paddingBottom: '15px', width: '400px' }}
                name="name"
                onChange={handleInputFormChange}
                label="Name"
                value={inputForm.name}
              />
              <Select
                name="title"
                onChange={handleInputFormChange}
                label="Category"
                value={inputForm.title}
                style={{ marginBottom: '15px', width: '400px' }}
                disabled={updateValue}
              >
                <MenuItem value="sales">Sales</MenuItem>
                <MenuItem value="marketing">Marketing</MenuItem>
                <MenuItem value="randd">R&D</MenuItem>
                <MenuItem value="ganda">G&A</MenuItem>
              </Select>
              <TextField
                suffix="%"
                style={{ paddingBottom: '15px', width: '400px' }}
                name="role"
                onChange={handleInputFormChange}
                label="Role"
                value={inputForm.role}
              />
              <TextField
                suffix="%"
                style={{ paddingBottom: '15px', width: '400px' }}
                name="salary"
                onChange={handleInputFormChange}
                label={fte ? 'Salary' : 'Rate'}
                value={inputForm.salary}
              />
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                style={{ marginBottom: '15px', width: '400px' }}
                name="location"
                onChange={handleInputFormChange}
                label="Location"
                value={inputForm.location}
              >
                {Object.keys(stateTaxes).map((key, index) => {
                  return (
                    <MenuItem value={key}>{key}</MenuItem>
                  );
                })}
              </Select>
              <TextField
                suffix="%"
                style={{ paddingBottom: '15px', width: '400px' }}
                name="taxes"
                onChange={handleInputFormChange}
                label="Taxes"
                value={inputForm.taxes}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
              <div className="row" style={{ paddingRight: '0px' }}>
                <div className="col-sm-6">
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="MM/dd/yyyy"
                    value={inputForm.startDate || null}
                    name="startDate"
                    onChange={(e) => setStartDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
                <div className="col-sm-6">
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="MM/dd/yyyy"
                    value={inputForm.endDate || null}
                    name="endDate"
                    onChange={(e) => setEndDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>

            </div>
          </div>
        </List>
        <div className="row" style={{ padding: '0px 50px 50px 50px' }}>
          <div className="col-sm-6">
            <Button
              variant="outlined"
              style={{
                whiteSace: 'nowrap', minWidth: '100%', fontSize: '10px', paddingTop: '10px',
              }}
              onClick={toggleDrawer('right', false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-sm-6">

            <Button
              variant="contained"
              style={{
                whiteSace: 'nowrap', minWidth: '100%', fontSize: '10px', paddingTop: '10px',
              }}
              onClick={() => handleSubmit()}
            >

              Save
            </Button>
          </div>
        </div>
      </Box>
    </LocalizationProvider>
  );

  return (
    <>
      {props.type === 'Edit'
        ? (
          <Button onClick={toggleDrawer('right', true)}>
            <MoreHorizIcon />
            {' '}
          </Button>
        )

        : (
          <Button
            variant="text"
            onClick={toggleDrawer('right', true)}
            style={{
              whiteSace: 'nowrap', minWidth: 'max-content', fontSize: '10px', paddingTop: '10px',
            }}
          >
            + New Entry
          </Button>
        )}
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={panelState.right}
          onClose={toggleDrawer('right', false)}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </>
  );
}
