/* eslint-disable */
import axios from 'axios';

axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
const instance = axios.create({
  // baseURL: 'http://localhost:3000',
  // baseURL: process.env.BASE_URL,
  // Dev
  // baseURL: 'https://a38boyexz5.execute-api.us-east-1.amazonaws.com',
  // // Prod
  baseURL: 'https://by2718lk0g.execute-api.us-east-1.amazonaws.com',
  // baseURL: 'http://localhost:3000',
});

// export const BASE_URL = 'https://finpro-api-test.herokuapp.com/';
// export const BASE_URL = 'http://localhost:3000/';
export const BASE_URL = 'https://a38boyexz5.execute-api.us-east-1.amazonaws.com';
export default instance;

/* test credentials
 *email
 *test@test.com
 *pass
 *$aA12345
 */
