import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { AuthContext } from '../../context/context';
import GAandChurnButton from '../Scenarios/GAandChurnButton';
import ScenarioButton from '../Scenarios/ScenarioButton';
import RevenueEntry from './InputPanels/RevenueEntry';
import RevenueTable from './Table/RevenueTable';

const Revenue = () => {
  const {
    state: {
      revenues,
    },
  } = React.useContext(AuthContext);

  function TabPanel(props) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <div className="row">
              <div className="col-sm-6">
                <Tabs value={value} onChange={handleChange} aria-label="Revenue Table Tabs">
                  <Tab style={{ textTransform: 'none' }} label="Revenue" {...a11yProps(0)} />
                  {/* <Tab style={{ textTransform: 'none' }} label="Expenses" {...a11yProps(1)} /> */}
                </Tabs>
              </div>
              <div className="col-sm-6">
                <div style={{ float: 'right', paddingTop: '15px', paddingBottom: '0px' }}>
                  {/* <ProjectsButton /> */}
                  <RevenueEntry />
                  <ScenarioButton />
                  {revenues === null ? <div /> : <GAandChurnButton />}
                </div>
              </div>
            </div>

          </Box>
          <CardContent style={{ padding: '0px' }}>
            <TabPanel style={{ textTransform: 'none', padding: '0px' }} value={value} index={0}>
              <CardContent style={{ padding: '0px' }}>
                <RevenueTable revenues={revenues} />
              </CardContent>
            </TabPanel>
            {/* <TabPanel style={{ textTransform: 'none', padding: '0px' }} value={value} index={1}>
              <CardContent style={{ padding: '0px' }} />
              <ExpensesTable revenues={revenues} />
            </TabPanel> */}
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default Revenue;
