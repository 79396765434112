/* eslint-disable */
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import * as React from 'react';
import { AuthContext } from '../../../context/context';
import { getTotalValue } from './InputUtils';
import RevenueEntry from './RevenueEntry';
import DisplayInputs from './RevnueInputs';

export default function RevenueDrawer(props) {
  const {
    state: {
      revenues,
    },
  } = React.useContext(AuthContext);
  const [basic, setBasic] = React.useState(true);
  const [compound, setCompound] = React.useState(false);


  const handleRadioChange = (event) => {
    if (event.target.value === 'basic') {
      setBasic(true);
      setCompound(false);
    } else {
      setCompound(true);
      setBasic(false);
    }
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = () => (
    <Box
      sx={{ width: 500 }}
      role="sidebar"
    >
      <List style={{ paddingBottom: '0px', paddingTop: '50px', marginBottom: '0px' }}>
        <div className="row" style={{ padding: '0px 50px 50px 50px' }}>
          {/* <h2> August 2021 - Revenue</h2> */}
          <h2>Revenue</h2>
          <div className="row">
            <div className="col-sm-9">
              <h2>
                {' '}
                {getTotalValue(revenues, props.model)}
                {' '}
              </h2>
            </div>
            <div className="col-sm-3">
              <RevenueEntry type="New" />
            </div>
          </div>
          <hr />
          <div className="pt-3">
            <DisplayInputs model={props.model} />
          </div>
        </div>
      </List>
    </Box>
  );

  return (
    <>
      <Button
        variant="text"
        onClick={toggleDrawer('right', true)}
        style={{
          whiteSace: 'nowrap', minWidth: 'max-content', fontSize: '10px', paddingTop: '10px',
        }}
      >
        <MoreHorizIcon
          onClick={toggleDrawer('right', true)} />
        {' '}
      </Button>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </>
  );
}
