/* eslint-disable */
import * as React from 'react';
import { AuthContext } from '../../../context/context';
import HiringEntry from './HiringEntry';
import { convertDate } from './InputUtils';

export default function HiringInputs(props) {
  const {
  } = React.useContext(AuthContext);


  return (
    <div>
      {props.inputs
        && (
          <>
            {props.inputs.inputs.map((value) => (
              <div>
                {(value.category === props.model || props.model === undefined) ?

                  <div style={{
                    borderRadius: '10px',
                    backgroundColor: 'rgba(196,196,196,0.1)',
                    padding: '10px 10px 10px 10px',
                    marginTop: '10px',
                  }}
                  >
                    <div className="row">
                      <div className="col-sm-9 pt-1" style={{ color: '#55616E', fontSize: '12px' }}>
                        {convertDate(value.startDate)}

                      </div>
                      <div className="col-sm-3" style={{ textAlign: 'right' }}>
                        <HiringEntry type="Edit" value={value} title={props.inputs.title} id={props.inputs._id} />
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-sm-9" style={{ fontSize: '14px' }}>
                        {value.role}
                      </div>
                      <div className="col-sm-3" style={{ textAlign: 'center' }}>
                        $
                        {value.salary}
                      </div>
                    </div>
                  </div>
                  :

                  <></>

                }
              </div>
            ))}
          </>
        )}
    </div>
  );
}
