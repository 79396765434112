/* eslint-disable */
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import NewProject from '../../components/Projects/NewProject';
import NewBoard from '../../components/ClientPerformance/NewBoard.js';
import { AuthContext } from '../../context/context';
import Integrations from './Integrations';
import Profile from './Profile';
import Project from './Project';
import Board from './Board';

import Subscription from './Subscription';
import Team from './Team';
import { useStyles } from './styles.js';
import { Box, Card, Container, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';

function Settings() {
  const {
    state: {
      presetComponent, allBoards
    },
    dispatch,
  } = React.useContext(AuthContext);

  const classes = useStyles();
  const [component, setComponent] = React.useState(<Subscription />);
  // const { projects, boards, allBoards, selectedBoard } = state;
  const [isSelected, setSelectedBoard] = useState(null);

  useEffect(() => {
    if (presetComponent !== null) {
      setComponent(presetComponent);
      dispatch({
        type: "SET_PRESET_COMPONENT",
        payload: null,
      });
    }
  }, [presetComponent]);


  const changeComponent = (componentType, index) => {

    if (componentType === 'Profile') {
      setComponent(<Profile />);
    } else if (componentType === 'Subscription') {
      setComponent(<Subscription />);
    } else if (componentType === 'Team') {
      setComponent(<Team />);
    } else if (componentType === 'Board') {
      setComponent(<Board index={index} />);
    } else if (componentType === 'Integrations') {
      setComponent(<Integrations />);
    }
  };

  const [backDropOpen, setBackDropOpen] = React.useState(false);
  const handleCloseBackDrop = () => {
    setBackDropOpen(false);
  };
  const handleToggleBackDrop = () => {
    setBackDropOpen(!backDropOpen);
  };

  const newProject = <Button variant="text" style={{ textTransform: 'none', color: '' }} onClick={handleToggleBackDrop}>+ Create new</Button>;
  const newBoard = <Button variant="text" style={{ textTransform: 'none', color: '' }} onClick={handleToggleBackDrop}>+ Create new</Button>;
  const liRef = useRef(null);



  return (
    <Container maxWidth="xl" >
        <Typography varriant="h1" sx={{ fontWeight: 900, fontSize: '32px', lineHeight: '58px', textAlign: 'left', color: '#55616E', margin: '30px 30px 30px 0', paddingTop: '30px' }}>
          Settings
        </Typography>


      <Card sx={{
        background: '#FFFFFF',
        borderRadius: '8px',
      }}>

        <div className="row"
          style={{
            gap: "10px" 
          }}
        >
          <div className="col-2" style={{ borderRight: '1px solid rgb(222, 222, 223)'}}>

            <Box sx={{
              paddingTop: '40px',
              paddingLeft: '20px',
              }}
            >
              <Box sx={{ marginBottom: '20px' }}>
                <Button
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: '#000',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '',
                      color: 'white'
                    },
                    background: '#fff',
                    width: '100%',
                  }}
                  onClick={() => changeComponent('Profile')}
                >
                  Profile Settings
                </Button>
                <Button
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: '#000',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '',
                      color: 'white'
                    },
                    background: '#fff',
                    width: '100%',
                  }}
                  onClick={() => changeComponent('Subscription')}
                >
                  Subscription Settings
                </Button>
                <Button
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: '#000',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '',
                      color: 'white'
                    },
                    background: '#fff',
                    width: '100%',
                  }}
                  onClick={() => changeComponent('Team')}
                >
                  Team Settings
                </Button>
                <Button
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: '#000',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '',
                      color: 'white'
                    },
                    background: '#fff',
                    width: '100%',
                  }}
                  onClick={() => changeComponent('Integrations')}
                >
                  Manage Integrations
                </Button>
              </Box>
              <Divider component="div"/>
              <Box sx={{
                marginTop: '20px',
              }}>
              <Typography className={classes.buttonStyle}> 
                CLIENTS SETTINGS 
              </Typography>
              {/* {boards.map((board, index) => (
                <p>
                  {' '}
                  <button
                    className={classes.buttonStyle}
                    style={{ color: '#55616E' }}
                    onClick={() => changeComponent('Board', index)}
                  >
                    {' '}
                    {board.boardName}
                    {' '}

                  </button>
                </p>

              ))} */}
              {allBoards && allBoards.boards && allBoards.boards.map((board, index) => {

                            return (
                              <>{board && board.favorite === "false" && board.owner === allBoards.userId &&
                                <>
                                  <List>
                                    <ListItem class="board-list" 
                                      ref={liRef}
                                      onClick={() => changeComponent('Board', index)}>
                                        <ListItemText class="col-sm-9">
                                            <Button class="board-button" 
                                            >
                                              <span class="board-name" style={{ textAlign: "left"}}>{board.boardName}</span>
                                            </Button>
                                        </ListItemText>
                                    </ListItem>
                                  </List>
                                </>
                              }</>
                            )
                          })}

              {newBoard}
              </Box>
              <Box>
                {backDropOpen && <NewBoard backDropOpen={backDropOpen} handleCloseBackDrop={handleCloseBackDrop} />}
              </Box>
            </Box>
          </div>
          <div className="col-8" style={{ padding: '40px', color: '#626D7D', }}>
            <div sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', // Centers the component vertically
              width: '100%'
            }}>
              {component}
            </div>
          </div>
        </div>
      </Card>
    </Container>
  );
}

export default Settings;
