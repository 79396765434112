/* eslint-disable */
import { default as CardContent, default as TextField } from '@mui/material/CardContent';

import { styled } from "@mui/material/styles";
const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);


const CustomTextField = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '25px',
  },
}));


export { CardContentNoPadding, CustomTextField };
