/* eslint-disable */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import HiringDrawer from '../InputPanels/HiringDrawer';
import { createHiringData, formatter } from './Utils';


function Row(props) {
  const { row, colSpan } = props;
  const [open, setOpen] = React.useState(true);
  const titles = {
    marketing: "Marketing",
    sales: "Sales",
    randd: "R&D",
    ganda: "G&A"
  }


  return (
    <>
      <tr >
        <td style={{ width: "500px", whiteSpace: "nowrap" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            style={{ display: "inline-block" }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span style={{ display: "inline-block" }}>{titles[row.model]}</span>
          <span style={{ display: "inline-block", float: "right" }}>
            <HiringDrawer type="Edit" model={row.model} />
          </span>
        </td>
        {row.compoundValues.map((amount) => (
          <td className={"pl-5"}> {formatter.format(amount)}</td>

        ))}
      </tr>

      {row.categoriesValue.map((subRow) => (
        <tr hidden={open} style={{ width: "100%" }}>
          <td>{subRow.role}</td>
          {subRow.amounts.map((amount) => (
            <td className={"pl-5"}> {formatter.format(amount)}</td>

          ))}

        </tr>
      ))}

    </>
  );
}

const HiringTable = (props) => {
  let rows = [];

  if (props.inputs) {
    rows = createHiringData(props.inputs);

  }
  const createTabs = () => {
    let Months = ['Hiring Plan Categories ', 'Jan', "Feb", "Mar", "April", "May", 'Jun', "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    //let Months = ['Jan', 'Feb', 'Mar', 'April'];
    return (
      <>
        {Months.map((month) => (
          <td style={{ width: "500px", whiteSpace: "nowrap", backgroundColor: "rgb(241, 244, 247)" }}>
            {month}&nbsp;2022
          </td>
        ))}

      </>
    );
  };

  return (
    <>
      <div class="scrolling content">
        <table className="ui selectable celled table">
          <thead>
            <tr>
              {createTabs()}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <Row key={row.model} row={row} colSpan={rows.colSpan} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HiringTable;
