import React from 'react';

import { styled } from '@mui/material/styles';

import { makeStyles, withStyles } from 'tss-react/mui';

import { InputBase } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import axios from '../../context/axios';
import { AuthContext } from '../../context/context';
import { getRevenue } from '../../context/fetch-service';
import ExpenseInputs from './ExpenseInputs';
import RevenueInputs from './RevenueInputs';
import StartingCapitalInput from './StartingCapitalInput';

const PREFIX = 'ForecastInputs';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  root2: `${PREFIX}-root2`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root2}`]: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BootstrapInput = InputBase;

const ForecastInputs = () => {

  const history = useNavigate();
  const {
    state: {
      user, purchasing, revenues, data, isAuthenticated, switchUser, currentScenario,
    },
    dispatch,
  } = React.useContext(AuthContext);
  const userSub = purchasing && purchasing.length > 0 ? purchasing.filter((sub) => sub.status === 'active') : [];
  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [alertClass, setAlertClass] = React.useState(userSub && userSub.length > 0 && Date.parse(new Date()) < Date.parse(new Date(userSub[0].purchaseDate)) + (userSub[0].planType === 'purchased' ? 30 : 7) * 24 * 60 * 60 * 1000 ? '' : 'show');
  const [chartLoader, setChartLoader] = React.useState(true);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      {children}
    </span>
  ));

  // Growth Variable Items
  const [growthRate, setGrowthRate] = React.useState({
    grate: user ? user.grate : '',
    grateQuarterly: user ? user.grateQuarterly : '',
    grateMonthly: user ? user.grateMonthly : '',
  });
  const [loader, setLoader] = React.useState(false);
  const [loader2, setLoader2] = React.useState(false);
  const [loader3, setLoader3] = React.useState(false);

  const handleSetting = (e) => {
    const { name, value } = e.target;
    setGrowthRate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const update = await axios.patch('/setting', growthRate);
      if (update.status === 200 || update.status === 201) {
        const user = await axios.get('/user');

        setErr('');
        setAlertClass('show');
        setGrowthRate({
          grate: user ? user.grate : '',
          grateQuarterly: user ? user.grateQuarterly : '',
          grateMonthly: user ? user.grateMonthly : '',
        });
        dispatch({
          type: 'SET_USER',
          payload: user.data.user,
        });
        setMsg(update.data.message);
        setLoader(false);
      }
      setTimeout(() => {
        // 
        if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
          dispatch({ type: 'VIEW_DATA', payload: chartValue });
        }
        setChartLoader(false);
      }, 1000);
    } catch (e) {
      setAlertClass('show');
      setMsg('');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };

  const handleSubmit2 = async (e) => {
    setLoader2(true);
    e.preventDefault();
    try {
      const update = await axios.patch('/setting', growthRate);
      if (update.status === 200 || update.status === 201) {
        const user = await axios.get('/user');

        setErr('');
        setAlertClass('show');
        setGrowthRate({
          grate: user ? user.grate : '',
          grateQuarterly: user ? user.grateQuarterly : '',
          grateMonthly: user ? user.grateMonthly : '',
        });
        dispatch({
          type: 'SET_USER',
          payload: user.data.user,
        });
        setMsg(update.data.message);
        setLoader2(false);
      }
      setTimeout(() => {
        // 
        if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
          dispatch({ type: 'VIEW_DATA', payload: chartValue });
        }
        setChartLoader(false);
      }, 1000);
    } catch (e) {
      setAlertClass('show');
      setMsg('');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader2(false);
    }
  };

  const handleSubmit3 = async (e) => {
    setLoader3(true);
    e.preventDefault();
    try {
      const update = await axios.patch('/setting', growthRate);
      if (update.status === 200 || update.status === 201) {
        const user = await axios.get('/user');

        setErr('');
        setAlertClass('show');
        setGrowthRate({
          grate: user ? user.grate : '',
          grateQuarterly: user ? user.grateQuarterly : '',
          grateMonthly: user ? user.grateMonthly : '',
        });
        dispatch({
          type: 'SET_USER',
          payload: user.data.user,
        });
        setMsg(update.data.message);
        setLoader3(false);
      }
      setTimeout(() => {
        // 
        if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
          dispatch({ type: 'VIEW_DATA', payload: chartValue });
        }
        setChartLoader(false);
      }, 1000);
    } catch (e) {
      setAlertClass('show');
      setMsg('');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader3(false);
    }
  };

  // Revenue Items
  const [chartValue, setChartValue] = React.useState('year');
  const handleChange = (event) => {
    setChartLoader(true);
    setChartValue(event.target.value);
  };

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const revenues = await getRevenue(switchUser.userName, currentScenario);
      dispatch({
        type: 'SET_REVENUE',
        payload: revenues,
      });
      setTimeout(() => {
        if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
          dispatch({ type: 'VIEW_DATA', payload: chartValue });
        }
        setChartLoader(false);
      }, 1000);
    }
    fetchRevenue();
  }, [isAuthenticated, history, dispatch, chartValue]);

  const generatePdf = () => {
    if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
      let type = 'Yearly';
      if (chartValue === 'quarter') {
        type = 'Quarterly';
      } else if (chartValue === 'month') {
        type = 'Monthly';
      }
      const doc = new jsPDF();

      // define the columns we want and their titles
      const tableColumn = ['Id', 'Plan', 'Price', 'Purchasers', 'type'];
      // define an empty array of rows
      const tableRows = [];
      // for each ticket pass all its data into an array
      revenues.revenuInputs
        .filter((rev) => rev.type === 'Quarterly' || 'Yearly' || 'Monthly')
        .forEach((reve) => {
          const reveData = [
            reve._id,
            reve.plan,
            reve.price,
            reve.purchasers,
            reve.type,
            // called date-fns to format the date on the ticket
            // format(new Date(), 'yyyy-MM-dd'),
          ];
          // push each tickcet's info into a row
          tableRows.push(reveData);
        });

      // startY is basically margin-top
      doc.autoTable(tableColumn, tableRows, { startY: 20 });
      const date = Date().split(' ');
      // we use a date string to generate our filename.
      const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
      // ticket title. and margin-top + margin-left
      doc.text('Revenue Variables', 14, 15);
      // we define the name of our PDF file.
      doc.save(`report_${dateStr}.pdf`);
    }
  };

  // 
  // 

  return (
    (<Root>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-12">

            <div className="row" style={{ padding: '0' }}>
              <div className="col-5 col-xl-6" style={{ paddingLeft: '0' }}>
                <h4>Revenue Variables</h4>
                <RevenueInputs chartValue={chartValue} revenues={revenues} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />
              </div>
              <div className="col-3 col-xl-3" style={{ backgroundColor: '#edf2f9', padding: '10px', borderRadius: '20px' }}>
                <h4>Growth Variable</h4>
                <div className="container">
                  <form onSubmit={handleSubmit}>
                    <div className="mt-5 mb-5" />
                    <div className="form-group">
                      <label className="form-label">Yearly revenue growth variable (%)</label>
                      <input type="number" className="form-control mb-3" name="grate" value={growthRate.grate} onChange={handleSetting} required placeholder="50" />
                      {switchUser.userAccess === 'CAN_MODIFY' && (
                        <button disabled={loader} className="btn btn-custom btn-padd">
                          {loader && (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {!loader && 'Save changes'}
                        </button>
                      )}
                    </div>
                  </form>

                  <form onSubmit={handleSubmit2}>
                    <div className="mt-5 mb-5" />
                    <div className="form-group">
                      <label className="form-label">Quarterly revenue growth variable (%)</label>
                      <input type="number" className="form-control mb-3" name="grateQuarterly" value={growthRate.grateQuarterly} onChange={handleSetting} required placeholder="50" />
                      {switchUser.userAccess === 'CAN_MODIFY' && (
                        <button disabled={loader2} className="btn btn-custom btn-padd">
                          {loader2 && (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {!loader2 && 'Save changes'}
                        </button>
                      )}
                    </div>
                  </form>

                  <form onSubmit={handleSubmit3}>
                    <div className="mt-5 mb-5" />
                    <div className="form-group">
                      <label className="form-label">Monthly revenue growth variable (%)</label>
                      <input type="number" className="form-control mb-3" name="grateMonthly" value={growthRate.grateMonthly} onChange={handleSetting} required placeholder="50" />
                      {switchUser.userAccess === 'CAN_MODIFY' && (
                        <button disabled={loader3} className="btn btn-custom btn-padd">
                          {loader3 && (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {!loader3 && 'Save changes'}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
                {/* <GrowthRates setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} /> */}
              </div>
              <div className="col-3 col-xl-3" style={{ paddingRight: '0' }}>
                <h4>Major Expense Variables</h4>
                {revenues && revenues._id && <ExpenseInputs revenueId={revenues._id} expenseInputs={revenues.majorExpenseInput} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />}
              </div>
            </div>
            <div className="row">
              <div className="col-8 col-xl-8">
                <h4>Startup Capital Variables</h4>
                {revenues && revenues._id && <StartingCapitalInput revenueId={revenues._id} startingCapital={revenues.startingCapital} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>)
  );
};

export default ForecastInputs;
