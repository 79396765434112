/* eslint-disable */

export const colors = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f',
    '#cab2d6', '#ffff99', '#1f78b4', '#33a02c'];

export const options = {
    sankey: {
        node: {
            colors: colors,
        },
        link: {
            colorMode: 'gradient',
            colors: colors
        },
    },
    annotations: {
        textStyle: {
            fontSize: 12,
            suffix: '%'
        }
    }
};

