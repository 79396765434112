/* eslint-disable */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import * as React from 'react';
import { AuthContext } from '../../context/context';
import { getInputs, getProjects, getRevenue } from '../../context/fetch-service';
import NewProject from './NewProject';

export default function ProjectsButton(props) {
  const {
    state: {
      switchUser, projects, currentScenario, selectedProject,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const [backDropOpen, setBackDropOpen] = React.useState(false);
  const handleCloseBackDrop = () => {
    setBackDropOpen(false);
  };
  const handleToggleBackDrop = () => {
    setBackDropOpen(!backDropOpen);
  };
  const newProject = (
    <>
      <Button
        variant="text"
        style={{
          whiteSace: 'nowrap', textTransform: 'none', minWidth: 'max-content', fontSize: '1rem', color: 'black', paddingLeft: '0px', paddingBottom: '0px',
        }}
        onClick={handleToggleBackDrop}
      >
        <p style={{ textTransform: 'none', marginBottom: '0px' }}>+ New Project</p>
      </Button>
    </>
  );
  const handleClick = () => {
    ;
  };

  const handleMenuItemClick = async (event, index) => {
    setOpen(false);
    dispatch({
      type: 'SET_SCENARIO',
      payload: '' + projects[index].scenarios[0]._id,
    });
    dispatch({
      type: 'SET_PROJECT',
      payload: index,
    });
    dispatch({
      type: 'SET_SCENARIOID',
      payload: 0,
    });
    const revenues = await getRevenue(switchUser.userName, projects[index].scenarios[0]._id);
    dispatch({
      type: 'SET_REVENUE',
      payload: revenues,
    });
    const inputs = await getInputs(switchUser.userName, projects[index].scenarios[0]._id);
    dispatch({
      type: 'SET_INPUTS',
      payload: inputs,
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    getProjects(switchUser.userName)
      .then((projects) => {
        if (projects.length === 0) {
          dispatch({
            type: 'SET_PROJECTS',
            payload: [],
          });
        } else {

          dispatch({
            type: 'SET_PROJECTS',
            payload: projects,
          });
        }
      })
      .catch((err) => {

      });
  }, [dispatch]);

  return (
    <>
      <ButtonGroup style={{ boxShadow: 'none', zIndex: "100" }} ref={anchorRef} aria-label="split button">
        {projects.length !== 0
          && (
            <Button
              variant="text"
              style={{
                whiteSace: 'nowrap', minWidth: 'max-content', fontSize: '12px', paddingBottom: '0px', marginTop: '15px', paddingRight: '0px', textTransform: 'none', color: 'black',
              }}
              onClick={handleClick}
            >
              <div className="text">
                {projects[selectedProject].projectName.icon ?
                  <i className="info circle icon"></i>
                  :
                  <i className="info circle icon"></i>

                }
                {selectedProject < projects.length ?
                  projects[selectedProject].projectName
                  :
                  projects[0].projectName
                }
              </div>
            </Button>
          )}
        {projects.length === 0 &&
          <div
            style={{
              whiteSace: 'nowrap', minWidth: 'max-content', fontSize: '12px', paddingBottom: '0px', marginTop: '15px', paddingRight: '0px', textTransform: 'none', color: 'black',
            }}
          >{newProject} </div>
        }
        <Button
          variant="text"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{ paddingBottom: '0px', marginTop: '15px', paddingRight: '0px' }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {projects.map((project, index) => (
                    <MenuItem
                      key={project._id}
                      selected={index === selectedProject}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {projects[selectedProject].projectName.icon ?
                        <i className="info circle icon mb-2"></i>
                        :
                        <i className="info circle icon mb-2"></i>

                      }
                      {project.projectName}
                    </MenuItem>
                  ))}
                  <MenuItem>
                    {newProject}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {backDropOpen
        && <NewProject backDropOpen={backDropOpen} handleCloseBackDrop={handleCloseBackDrop} />}
    </>
  );
}
