/*eslint-disable*/
import React from "react";

function LoadingTab() {
    return(
       <div className="col-sm-3">
        <div className="">
            <div
                className="header"
                style={{
                    maxHeight: '70px',
                    fontWeight: 'bold',
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    fontSize: '20px',
                    display: 'grid',
                }}
            >
                <div className="loading-row " />
            </div>
        </div>
       </div>
   
    );
   }
export default LoadingTab;

   