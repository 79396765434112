import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from 'tss-react/mui';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventNoteIcon from '@mui/icons-material/EventNote';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TimelineIcon from '@mui/icons-material/Timeline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import { getRevenue } from '../../context/fetch-service';
import ScenarioButton from '../Scenarios/ScenarioButton';
import BarChart from './Chart/BarChart';
import LineChart from './Chart/LineChart';
import DatePicker from './DatePickerComponent';

const PREFIX = 'ForecastsBarChart';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  root2: `${PREFIX}-root2`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root2}`]: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const BootstrapInput = InputBase;

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    (<Root
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Root>)
  );
}

const ForecastsBarChart = () => {
  const history = useNavigate();
  const {
    state: {
      purchasing, revenues, data, isAuthenticated, switchUser, projects, currentScenario,
    }, dispatch,
  } = React.useContext(AuthContext);
  const [chartLoader, setChartLoader] = React.useState(true);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>{children}</span>));
  const [chartValue, setChartValue] = React.useState('year');
  const [value, setValue] = React.useState(0);
  const [lineChart, setLineChart] = React.useState(true);
  const forecastCharts = ['Revenue Forecast', 'Sales Spend Forecast', 'Marketing Spend Forecast', 'R&D Spend Forecast', 'G&A Spend Forecast', 'Starting Capital'];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const revenues = await getRevenue(switchUser.userName, currentScenario);
      dispatch({
        type: 'SET_REVENUE',
        payload: revenues,
      });
      setTimeout(() => {
        if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
          dispatch({ type: 'VIEW_DATA', payload: chartValue });
        }
        setChartLoader(false);
      }, 1000);
    }
    fetchRevenue();
  }, [isAuthenticated, history, dispatch, chartValue]);

  const generatePdf = () => {
    if (revenues && revenues.revenuInputs && revenues.revenuInputs.length > 0) {
      let type = 'Yearly';
      if (chartValue === 'quarter') {
        type = 'Quarterly';
      } else if (chartValue === 'month') {
        type = 'Monthly';
      }
      const doc = new jsPDF();

      // define the columns we want and their titles
      const tableColumn = ['Id', 'Plan', 'Price', 'Purchasers', 'type'];
      // define an empty array of rows
      const tableRows = [];
      // for each ticket pass all its data into an array
      revenues.revenuInputs
        .filter((rev) => rev.type === 'Quarterly' || 'Yearly' || 'Monthly')
        .forEach((reve) => {
          const reveData = [
            reve._id,
            reve.name,
            reve.price,
            reve.purchasers,
            reve.type,
            // called date-fns to format the date on the ticket
            // format(new Date(), 'yyyy-MM-dd'),
          ];
          // push each tickcet's info into a row
          tableRows.push(reveData);
        });

      // startY is basically margin-top
      doc.autoTable(tableColumn, tableRows, { startY: 20 });
      const date = Date().split(' ');
      // we use a date string to generate our filename.
      const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
      // ticket title. and margin-top + margin-left
      doc.text('Revenue Variables', 14, 15);
      // we define the name of our PDF file.
      doc.save(`report_${dateStr}.pdf`);
    }
  };
  return (
    <div className="row">
      <div className="col-sm-5">
        <nav className="navbar navbar-expand-sm date-nav" style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
          <h1 style={{ paddingRight: '20px', paddingTop: '15px' }}>Forecast</h1>
          <ScenarioButton />
          <Button onClick={() => setLineChart(!lineChart)}>{lineChart ? <BarChartIcon htmlColor="black" /> : <TimelineIcon htmlColor="black" />}</Button>
        </nav>
      </div>
      <div className="col-sm-7">
        <nav className="navbar date-nav navbar-expand-sm" style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
          <Dropdown style={{ paddingRight: '20px', paddingTop: '20px' }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                <EventNoteIcon />
                &nbsp; Next Week &nbsp;
                <KeyboardArrowDownIcon />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item> Next Week</Dropdown.Item>
              <Dropdown.Item> Next Month</Dropdown.Item>
              <Dropdown.Item> Next Quarter</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span style={{ paddingRight: '20px', paddingTop: '20px' }}>or </span>
          <DatePicker />
        </nav>
      </div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} textColor="black" indicatorColor="primary" variant="scrollable" onChange={handleTabChange} aria-label="basic tabs example">
            {forecastCharts.map((chart, index) => (
              <Tab style={{ textTransform: 'none' }} label={chart} {...a11yProps(index)} />

            ))}
          </Tabs>
        </Box>
        <div style={{ width: '100%' }}>
          {forecastCharts.map((chart, index) => (
            <TabPanel value={value} index={index}>
              {lineChart
                ? <LineChart name={chart.toUpperCase()} amount="$8,384,210.49" data={data} chartLoader={chartLoader} />
                : <BarChart name={chart.toUpperCase()} amount="$8,384,210.49" data={data} chartLoader={chartLoader} />}
            </TabPanel>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default ForecastsBarChart;
