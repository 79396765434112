/*eslint-disable */
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { makeStyles, withStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import axios from '../../context/axios';
import { AuthContext } from '../../context/context';
import Alerts from '../Misc/Alerts';
import LoadingBtn from '../Misc/LoadingBtn';

const PREFIX = 'InviteTeam';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  closeButton: `${PREFIX}-closeButton`,
  btn: `${PREFIX}-btn`,
  formdiv: `${PREFIX}-formdiv`,
  nestdiv: `${PREFIX}-nestdiv`,
  invite_btn: `${PREFIX}-invite_btn`,
  datadiv: `${PREFIX}-datadiv`,
  buttonStyle: `${PREFIX}-buttonStyle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.btn}`]: {
    color: '#aaa',
    margin: '0 0 0 0',

    '& svg': {
      fontSize: '2rem',
    },
  },
  [`& .${classes.formdiv}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.nestdiv}`]: {
    border: '2px solid #5abcdc',
    borderRadius: '10px',
    padding: '.5rem .5rem',
    '& input': {
      margin: '0 5rem 0 1rem',
    },
  },
  [`& .${classes.invite_btn}`]: {
    width: '15%',
    backgroundColor: '#5abcdc',
    fontWeight: 'bold',
    fontFamily: 'cerebri sans',
    padding: '.5rem 3.5rem',
    borderRadius: '1rem',
    fontSize: '1rem',
    margin: '0 1rem',
  },

  [`& .${classes.datadiv}`]: {
    margin: '2.5rem 1rem',
  },
  [`& .${classes.buttonStyle}`]: {
    backgroundColor: 'transparent', color: '#220FFE', border: 'none', whiteSpace: 'normal', overflow: 'visible', textAlign: 'left',
  },

});

const DialogTitle = ((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    (<MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1
        }}>
        <Typography variant="h6">{children}</Typography>
      </Box>
    </MuiDialogTitle>)
  );
});

const DialogContent = MuiDialogContent;

const InviteTeam = (props) => {
  const {
    state: {
      user, billingDetails, purchasing, isAuthenticated,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const {
    state,
  } = React.useContext(AuthContext);

  const { companyName } = state;


  const classes = MStyles();
  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');

  const handleClickOpen = () => {
    setType('success');
    setMsg('');
    setOpenMsg(false);
    setOpen(true);
  };
  const handleClose = () => {
    setType('success');
    setMsg('');
    setOpenMsg(false);
    setOpen(false);
  };

  const addTeamMemberInfo = async () => {
    setProg(false);
    const url = '/addTeamMember';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
    await axios
      .post(url, { recipientId: email, 
        firstName: user.firstName, 
        lastName: user.lastName,
        companyName: state.companyName,
 })
      .then((res) => {
        setType('success');
        setMsg(res.data.message);
        props.fetchData();
      })
      .catch((err) => {
        setType('error');
        if (err.response && err.response.data) {
          setMsg(err.response.data.message);
        } else {
          setMsg(err.message);
        }
      });
    setOpenMsg(true);
    setProg(true);
  };

  return (
    (<Root>
      <div className="share_container">
        <div>
          <button className={classes.buttonStyle} onClick={handleClickOpen}>
            + Add new member
          </button>
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Invite
            </DialogTitle>
            <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} />
            <DialogContent
              dividers
              classes={{
                root: classes.root
              }}>
              <div className={classes.formdiv}>
                <div className={classes.nestdiv}>
                  <InputBase placeholder="Enter Email " value={email} name="email" onChange={() => setEmail(event.target.value)} />
                </div>
                <>
                  <LoadingBtn prog={prog} styleprop={classes.invite_btn} onclick={addTeamMemberInfo} name="Invite" />
                </>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </Root>)
  );
};

export default InviteTeam;
