/* eslint-disable */
export const parseQuickbooksData = (data) => {
    let mainData = [];
    function createBalance(data, name) {
        let yValues = data.Rows.Row;
        let xName = name;
        for (let j = 0; j < yValues.length; j = j + 1) {
            let yVal = yValues[j];
            let yName = "TOTAL";
            if (yVal.group) {
                yName = yVal.group;
            } else if (yVal.ColData) {
                yName = yVal.ColData[0].value
            } else {
                yName = yVal.type
            }
            let totalVal = "";
            if (yVal.Summary) {
                if (yVal.Summary.ColData.length > 1) {
                    totalVal = yVal.Summary.ColData[1].value;
                }
            }
            let dataRow = [xName, yName, parseInt(totalVal)];
            mainData.push(dataRow);
            if (yVal.Rows) {
                createBalance(yVal, yName);
            }
        }
        return data;
    }
    createBalance(data, "TOTAL");
    let newData = clearNulls(mainData);
    newData = modifyArray(newData);
    newData.unshift(["From", "To", "$"]);
    return newData;
}

function modifyArray(array) {
    return array.map(item => {
        if (item[2] < 0) {
            return [item[0], `(Negative) ${item[1]}`, Math.abs(item[2])];
        }
        return item;
    });
}


export const parseQuickbooksDataTable = (quickbooksData) => {
    function createRows(data) {
        let condensedRows = [];
        function condenseRows(rows, header) {
            // if (rows && rows.Row) {
            rows.Row.forEach(row => {
                if (row.Rows) {
                    if (row.Summary) {
                        let data = {
                            name: row.Summary.ColData[0].value,
                            amount: [0],
                            totalAmount: 0,
                            header: true
                        }
                        if (row.Summary.ColData[1] && row.Summary.ColData[1].value != '') {
                            data.amount = [row.Summary.ColData[1].value];
                            data.totalAmount = row.Summary.ColData[1].value;

                        }
                        condensedRows.push(data);
                        condenseRows(row.Rows, row.Summary.ColData[0].value);

                    } else {
                        condenseRows(row.Rows, "");
                    }
                } else {
                    if (row.Summary) {

                    } else {
                        let data = {
                            name: row.ColData[0].value,
                            header: false,
                            headerVal: header,
                        }

                        if (row.ColData.length > 1 && row.ColData[1].value != '') {
                            data.amount = [row.ColData[1].value];
                            data.totalAmount = row.ColData[1].value;

                        } else {
                            data.amount = [0];
                            data.totalAmount = 0;

                        }
                        condensedRows.push(data);
                    }
                }
            });
            // }
            // return;
        }
        condenseRows(data.Rows, "")
        return condensedRows;
    }

    let tableData = createRows(quickbooksData);
    return tableData;
}

function clearNulls(data) {
    let clearedData = [];
    for (let i = 0; i < data.length; i = i + 1) {
        let foundNull = false;
        let currData = data[i];
        if (isNaN(currData[2])) {
        } else {
            if (currData[0] === currData[1]) {

            } else {
                clearedData.push(currData);

            }
        }
    }
    return clearedData;
}


const parseCashFlow = (data) => {
    let headers = data.Columns.Column.map(c => {
        if (c.ColTitle === "") {
            return 'empty_value'
        }
        return c.ColTitle
    });

    let rows = data.Rows.Row;
    let rowList = []

    const iterate = (obj) => {
        Object.keys(obj).forEach(key => {
            if (key === "ColData") {
                rowList.push(obj[key]);
            }

            if (typeof obj[key] === 'object') {
                iterate(obj[key])
            }
        })
    }
    if (rows) {
        iterate(rows);
    }
    let mainDataset = {
        headers,
        rows: rowList
    }
    return mainDataset;
}

//////////////////////// DataTable stuff
export const combineArrays = (Array1, Array2, shift) => {
    for (let i = 0; i < Array2.length; i++) {
        let found = false;
        for (let j = 0; j < Array1.length; j++) {
            if (Array2[i].name === Array1[j].name) {
                Array1[j].amount.push(Array2[i].amount[0]);
                found = true;
                break;
            }
        }
        if (!found) {
            let temp = shift;
            while (temp > 0) {
                Array2[i].amount.unshift(0)
                temp--;
            }
            Array1.push(Array2[i]);
        }
    }
    return Array1;
}
export const processArray = (array, length) => {
    let result = [];
    let headers = [];
    let subHeaders = [];
    array.forEach((item) => {
        if (item.amount.length < length) {
            let temp = length - item.amount.length;
            while (temp > 0) {
                item.amount.push(0)
                temp--;
            }
            // item.amount.push(0)
        }
        if (item.header) {
            headers.push(item);
        } else {
            subHeaders.push(item);
        }
    });
    headers.forEach((item) => {
        result.push(item);
        result[result.length - 1].items = [];
        subHeaders.forEach((subItem) => {
            if (subItem.headerVal === item.name) {
                // result.push(subItem);
                result[result.length - 1].items.push(subItem);

                // .push(subItem)
                // result.item.push(subItem);
            }
        });
    });
    subHeaders.forEach((item) => {
        if (item.headerVal === "") {
            result.push(item);
        }
    });
    result.forEach(item => {
        let totalAmount = 0;
        item.amount.forEach(amount => {
            if (typeof amount === 'number' || !isNaN(amount)) {
                totalAmount += Number(amount);
            }
        });
        item.totalAmount = totalAmount;
    });
    return result;
}

// export const  calculatePercentage = (data) => {
//     const percentageData = data.map(row => {
//       let parentValue = 0;
//       if (row[0] === "TOTAL") {
//         parentValue = row[2];
//       } else {
//         // find the parent value
//         const parentRow = data.find(d => d[1] === row[0] && d[0] === "TOTAL");
//         if (parentRow) {
//           parentValue = parentRow[2];
//         }
//       }
//       // check if the parent value is not zero
//       if (parentValue === 0) {
//         return [row[0], row[1], 0];
//       }
//       // calculate the percentage
//       let percentage = (row[2] / parentValue) * 100;
//       // round to 2 decimal places
//       percentage = percentage.toFixed(2);
//       return [row[0], row[1], parseFloat(percentage)];
//     });
//     percentageData[0][2] = "%";
//     return percentageData;
//   }

export const parseDate = (date) => {

    let [startDateString, endDateString] = date.split(" - ");
    let query = ""
    if (startDateString && endDateString) {
        query = `?start_date=${startDateString}&end_date=${endDateString}`;
    }
    return query;
}
export const calculatePercentage = (data) => {
    let percentages = [];
    for (let i = 1; i < data.length; i++) {
        let fromVal = data[i][0];
        let toVal = data[i][1];
        let value = data[i][2];
        let parentVal = 0;
        for (let j = 1; j < data.length; j++) {
            if (data[j][1] === fromVal) {
                parentVal = data[j][2];
                break;
            }
        }
        let percentage = (value / parentVal) * 100;
        if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 100;
        }
        percentage = percentage.toFixed(2);
        percentage = parseInt(percentage)
        percentages.push([fromVal, toVal, percentage]);
    }
    percentages.unshift(["From", "To", "%"]);
    return percentages;
}





// export const createDateRangeStrings = (startDateStr, endDateStr) => {
//
//     const [startYear, startMonth, startDay] = startDateStr.split('-').map(Number);
//     const [endYear, endMonth, endDay] = endDateStr.split('-').map(Number);
//
//     let startDate = new Date(startYear, startMonth - 1, startDay);
//     let endDate = new Date(endYear, endMonth - 1, endDay);
//
//     // Rest of the function...
//
//
//     let dateRangeStrings = [];
//
//     if (startDate.getFullYear() === endDate.getFullYear()) {
//         return [`?start_date=${startDateStr}&end_date=${endDateStr}`];
//     } else {
//         let currentDate = startDate;
//
//         while (currentDate.getFullYear() <= endDate.getFullYear()) {
//             let yearStartDate = new Date(currentDate.getFullYear(), 0, 1);
//             if (currentDate.getFullYear() === startDate.getFullYear()) {
//                 yearStartDate = startDate;
//             }
//
//             let yearEndDate = new Date(currentDate.getFullYear(), 11, 31);
//             if (yearEndDate > endDate || currentDate.getFullYear() === endDate.getFullYear()) {
//                 yearEndDate = endDate;
//             }
//
//             let yearStartDateStr = yearStartDate.toISOString().slice(0, 10);
//             let yearEndDateStr = yearEndDate.toISOString().slice(0, 10);
//
//             dateRangeStrings.push(`?start_date=${yearStartDateStr}&end_date=${yearEndDateStr}`);
//
//             currentDate = new Date(currentDate.getFullYear() + 1, 0, 1);
//         }
//
//         return dateRangeStrings;
//     }
// };

export const createDateRangeStrings = (startDateStr, endDateStr) => {
    const [startYear, startMonth, startDay] = startDateStr.split('-').map(Number);
    const [endYear, endMonth, endDay] = endDateStr.split('-').map(Number);

    let startDate = new Date(startYear, startMonth - 1, startDay);
    let endDate = new Date(endYear, endMonth - 1, endDay);

    let dateRangeStrings = [];

    if (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth()) {
        return [`?start_date=${startDateStr}&end_date=${endDateStr}`];
    } else {
        let currentDate = startDate;

        while (currentDate <= endDate) {
            let monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            if (currentDate.getFullYear() === startDate.getFullYear() && currentDate.getMonth() === startDate.getMonth()) {
                monthStartDate = startDate;
            }

            let monthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            if (monthEndDate > endDate || (currentDate.getFullYear() === endDate.getFullYear() && currentDate.getMonth() === endDate.getMonth())) {
                monthEndDate = endDate;
            }

            let monthStartDateStr = monthStartDate.toISOString().slice(0, 10);
            let monthEndDateStr = monthEndDate.toISOString().slice(0, 10);

            dateRangeStrings.push(`?start_date=${monthStartDateStr}&end_date=${monthEndDateStr}`);

            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        }

        return dateRangeStrings;
    }
};
