/* eslint-disable */

import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material'; 
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import C2QB from '../../assets/C2QB_green_btn_med_default.png';
import { intuitLogin } from '../../context/intuit-service';


const Alerts = (props) => {

  const goToIntuit = async () => {
    const sub = await intuitLogin();
    if (sub.status === 200 || sub.status === 201) {
      window.location.href = sub.data.url;
    }
    // window.location.href = '/intuitLogin';
  }


  return (


    <>
      <Collapse in={props.openMsg}>
        <Alert
          severity={props.type}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.setOpenMsg(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          {props.msg}

          {props.button &&
            <div className="ui button p-0 mb-0 ml-5" onClick={goToIntuit}><img src={C2QB}></img></div>

          }
        </Alert>
      </Collapse>
    </>

  )
};

export default Alerts;
