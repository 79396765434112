/* eslint-disable */

import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Image } from 'semantic-ui-react';
import Profile from '../../assets/profile.png';
import axios from '../../context/axios';
import { AuthContext } from '../../context/context';
import { getTeams } from '../../context/team-service';
import Alerts from '../Misc/Alerts';
import LoadingBtn from '../Misc/LoadingBtn';
import InviteTeam from './InviteTeam';
import { useStyles } from './styles.js';


function Team() {
  const {
    state,
    dispatch,
  } = React.useContext(AuthContext);
  const { user, purchasing, teams, companyName } = state;
  const classes = useStyles();
  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);

  const [teamMembers, setTeamMembers] = React.useState([]);

  const [maxTeam, setMaxTeam] = React.useState(0);


  const deleteTeamMemberFunction = async (email) => {
    setProg(false);
    const url = '/deleteTeamMember';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
    await axios
      .post(url, { recipientId: email })
      .then((res) => {
        setType('success');
        setMsg(res.data.message);
        setTeamMembers(teamMembers.filter((team) => team.email !== email));
        setProg(true);
      })
      .catch((err) => {
        setType('error');
        if (err.response && err.response.data) {
          setMsg(err.response.data.message);
        } else {
          setMsg(err.message);
        }
      });
    setOpenMsg(true);
    setProg(true);
  };

  const RenderInvites = (props, deleteButton) => (
    <div>
      {props.map((member, i) => (
        <div
          className="row"
          style={{
            margin: '10px',
          }}
        >
          {/* <div className="two wide column">
            <Image
              src={member && member.profile ? `${member.profile}` : Profile}
              size="tiny"
              circular
              style={{
                marginRight: '1rem',
                width: "50px",
                height: "50px"
              }}
            />
          </div> */}
          <div className="col-1">
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                marginRight: '2.5rem',
              }}
              src={(member && member.profile) ? `${member.profile}` : Profile}
            />
          </div>
          <div className="col-8" style={{ marginLeft: '30px' }}>
            <div className="">
              {member.firstName && member.lastName && (
                  <div className="row">
                    {member.firstName + ' ' + member.lastName}
                  </div>
              )}
              <div className="row">
                {member.email}
              </div>
            </div>
          </div>
          <div className="col-1">
            {deleteButton &&


              <LoadingBtn
                prog={prog}
                styleprop={classes.buttonStyle}
                onclick={deleteTeamMemberFunction}
                name="Remove"
                functiondata={member.email}
              />
            }
          </div>
        </div>
      ))}
    </div>
  );

  React.useEffect(() => {

    if (purchasing[0] && purchasing[0].subscriptionDetails) {
      setMaxTeam(purchasing[0].subscriptionDetails.quantity)
    }

    fetchData().catch(console.error);


  }, []);

  const [myTeams, setMyTeams] = React.useState([]);

  const [loadingTeams, setLoadingTeams] = useState(true);

  // const fetchData = async () => {
  //   setLoadingTeams(true)
  //   const teamMembersResponse = await getTeams();
  //   setTeamMembers(teamMembersResponse.data.data[0].teamMembers);
  //   setMyTeams(teamMembersResponse.data.data[0].myTeams ?? []);
  //   setLoadingTeams(false)


  // };

  const fetchData = async () => {
    setLoadingTeams(true);
  
    try {
      const teamMembersResponse = await getTeams();
  
      if (teamMembersResponse?.data?.data?.[0]) {
        const { teamMembers, myTeams } = teamMembersResponse.data.data[0];
  
        setTeamMembers(teamMembers || []);
        setMyTeams(myTeams || []);
      } else {
        setTeamMembers([]);
        setMyTeams([]);
      }
    } catch (error) {
      console.error('Failed to fetch teams:', error);
      // Optionally, set error state here
    } finally {
      setLoadingTeams(false);
    }
  };
  

  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  const handleIconHover1 = () => {
    setHovered1(!hovered1);
  };

  const handleIconHover2 = () => {
    setHovered2(!hovered2);
  };

  const tooltip1 = (
    <Tooltip id="tooltip">Team Members can be added using your seats</Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip">Being part of a team gives you access to your account</Tooltip>
  );

  return (
    <div className="col-8">
      <div className={classes.mainCard}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2>Your Team Members</h2>
            <OverlayTrigger
              placement="right"
              overlay={tooltip1}
            >
              <span
                className="bi bi-info-circle-fill"
                onMouseEnter={handleIconHover1}
                onMouseLeave={handleIconHover1}
                style={{ marginLeft: '8px', marginBottom: "5px", cursor: 'pointer' }}
              >
              </span>
            </OverlayTrigger>
            {/* <p style={{ color: 'black', fontSize: '12px', marginLeft: "20px", marginTop: "5px" }}>
              {`- ${maxTeam - teamMembers.length} Seats left!`}
            </p> */}
            <div style={{ marginLeft: 'auto'}}>
              {teamMembers.length < maxTeam && <InviteTeam fetchData={fetchData} />}
            </div>
          </div>
        </div>

        <div />
        <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} />
        <div className="row" style={{ paddingBottom: '25px', marginBottom: "100px" }}>
          <hr />
          {loadingTeams ?

            <div className="loading-container">
              <div className="loading-row" />
            </div>
            :
            <>
              {teamMembers.length > 0
                ? (
                  <>
                    {RenderInvites(teamMembers, true)}
                    <div className="mt-3">
                      <p style={{ color: 'black', fontSize: '12px' }}>
                        {`You have ${maxTeam - teamMembers.length} ${maxTeam - teamMembers.length === 1 ? "seat" : "seat"} left`}
                      </p>                    </div>

                  </>
                )
                :
                <div className="mt-3">
                  <p>Select Add New Members to invite members to your team!</p>
                  <p style={{ color: 'black', fontSize: '12px' }}>
                    {`You have ${maxTeam - teamMembers.length} seats left`}
                  </p>                </div>

              }
            </>

          }
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: "100px" }}>
          <h2>Your Teams</h2>

          <OverlayTrigger
            placement="right"
            overlay={tooltip2}
          >
            <span
              className="bi bi-info-circle-fill"
              onMouseEnter={handleIconHover2}
              onMouseLeave={handleIconHover2}
              style={{ marginLeft: '8px', marginBottom: "5px", cursor: 'pointer' }}
            >

            </span>
          </OverlayTrigger>
        </div>

        <div />
        <div className="row" style={{ paddingBottom: '25px' }}>
          <hr />
          {loadingTeams ?

            <div className="loading-container">
              <div className="loading-row" />
            </div>
            :
            <>
              {myTeams.length > 0
                ?
                <>
                  {RenderInvites(myTeams, false)}
                </>
                :
                <p> You are currently not part of any teams</p>

              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default Team;
