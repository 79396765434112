const eCommerceBoardData = [
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01",
                "2023-02",
                "2023-03",
                "2023-04",
                "2023-05",
                "2023-06",
                "2023-07",
                "2023-08",
                "2023-09",
                "2023-10",
                "2023-11",
                "2023-12"
              ],
              "datasets": [
                {
                  "label": "Revenue (in millions)",
                  "data": [
                    92,
                    147,
                    83,
                    125,
                    111,
                    85,
                    83,
                    138,
                    115,
                    126,
                    88,
                    107
                  ],
                  "borderColor": "rgba(75, 192, 192, 1)",
                  "backgroundColor": "rgba(75, 192, 192, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "category",
                  "labels": [
                    "2023-01",
                    "2023-02",
                    "2023-03",
                    "2023-04",
                    "2023-05",
                    "2023-06",
                    "2023-07",
                    "2023-08",
                    "2023-09",
                    "2023-10",
                    "2023-11",
                    "2023-12"
                  ],
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate line charts for Revenue(in millions)"
        }
      ],
      "boardFormulas": [],
      "_id": "67521b96707e500fe2c1e4af",
      "boardType": "graph",
      "title": "Revenue (in millions)",
      "boardView": "graph",
      "index": 0,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Inventory Turnover Rate",
                  "data": [
                    10,
                    6,
                    10,
                    18,
                    14,
                    18,
                    8,
                    13,
                    16,
                    11,
                    17,
                    11
                  ],
                  "backgroundColor": [
                    "rgba(63, 81, 181, 1)",
                    "rgba(76, 175, 80, 1)",
                    "rgba(255, 193, 7, 1)",
                    "rgba(121, 85, 72, 1)",
                    "rgba(33, 150, 243, 1)",
                    "rgba(156, 39, 176, 1)",
                    "rgba(244, 67, 54, 1)",
                    "rgba(0, 188, 212, 1)",
                    "rgba(255, 87, 34, 1)",
                    "rgba(205, 220, 57, 1)",
                    "rgba(158, 158, 158, 1)",
                    "rgba(103, 58, 183, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "displayFormats": {
                      "month": "MMM YYYY",
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    },
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond"
                  },
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "type": "linear",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate bar charts for Inventory Turnover Rate"
        }
      ],
      "boardFormulas": [],
      "_id": "67521db2707e500fe2c1e4b3",
      "boardType": "graph",
      "title": "Inventory Turnover Rate",
      "boardView": "graph",
      "index": 4,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Average Order Value (AOV) Over Time",
                  "data": [
                    55,
                    59,
                    64,
                    59,
                    107,
                    91,
                    72,
                    58,
                    59,
                    115,
                    107,
                    101
                  ],
                  "backgroundColor": [
                    "rgba(75, 85, 99, 0.8)",
                    "rgba(78, 70, 93, 0.8)",
                    "rgba(97, 85, 85, 0.8)",
                    "rgba(88, 86, 74, 0.8)",
                    "rgba(90, 105, 78, 0.8)",
                    "rgba(72, 88, 91, 0.8)",
                    "rgba(68, 81, 109, 0.8)",
                    "rgba(62, 75, 85, 0.8)",
                    "rgba(75, 83, 108, 0.8)",
                    "rgba(81, 98, 75, 0.8)",
                    "rgba(94, 69, 80, 0.8)",
                    "rgba(95, 88, 88, 0.8)"
                  ],
                  "borderColor": [
                    "rgba(75, 85, 99, 1)",
                    "rgba(78, 70, 93, 1)",
                    "rgba(97, 85, 85, 1)",
                    "rgba(88, 86, 74, 1)",
                    "rgba(90, 105, 78, 1)",
                    "rgba(72, 88, 91, 1)",
                    "rgba(68, 81, 109, 1)",
                    "rgba(62, 75, 85, 1)",
                    "rgba(75, 83, 108, 1)",
                    "rgba(81, 98, 75, 1)",
                    "rgba(94, 69, 80, 1)",
                    "rgba(95, 88, 88, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "category",
                  "labels": [
                    "2023-01-01",
                    "2023-02-01",
                    "2023-03-01",
                    "2023-04-01",
                    "2023-05-01",
                    "2023-06-01",
                    "2023-07-01",
                    "2023-08-01",
                    "2023-09-01",
                    "2023-10-01",
                    "2023-11-01",
                    "2023-12-01"
                  ],
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate bar charts for Average Order Value(AOV)."
        }
      ],
      "boardFormulas": [],
      "_id": "67521bea707e500fe2c1e4b0",
      "boardType": "graph",
      "title": "Average Order Value (AOV) Over Time",
      "boardView": "graph",
      "index": 1,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>This template offers a clear view of essential metrics like Net Promoter Score, Inventory Turnover, Cart Abandonment Trends, Revenue, and Average Order Value. Simplify decision-making, track progress, and uncover growth opportunities effortlessly.</p>\n<p>Unlock your business&rsquo;s potential with data that drives results!</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "675222f1707e500fe2c1e4b4",
      "index": 5,
      "boardView": "Text",
      "boardType": "Text",
      "title": "eCommerce Performance Insights Template",
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>Average Order Value (AOV) is a key metric that measures the average amount spent by customers per transaction. Tracking AOV over time provides valuable insights into customer purchasing behavior, the effectiveness of upselling and cross-selling strategies, and overall revenue growth.</p>\n<p>In this section, we analyze the trends in AOV across different time periods, highlighting any fluctuations and their potential causes. Understanding these trends helps us identify opportunities to optimize pricing, promotions, and product bundling to enhance customer value and profitability.</p>"
        }
      ],
      "boardFormulas": [],
      "index": 7,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Average Order Value (AOV) Over Time",
      "_id": "675a85ffad4e003d3d386cb0",
      "invited": []
    },

    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Repeat Purchase Rate (%)",
                  "data": [
                    33.8,
                    21.2,
                    23.5,
                    39.1,
                    36.8,
                    20.6,
                    24.1,
                    34.8,
                    39.3,
                    34.4,
                    34.2,
                    25.7
                  ],
                  "borderColor": "rgba(75, 192, 192, 1)",
                  "backgroundColor": "rgba(75, 192, 192, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "ticks": {
                    "source": "labels",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "bounds": "data",
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Line Styling for Repeat Purchase Rate (%)"
        }
      ],
      "boardFormulas": [],
      "_id": "6759f02fad4e003d3d386c8d",
      "boardType": "graph",
      "title": "Repeat Purchase Rate (%)",
      "boardView": "graph",
      "index": 5,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Active Users (thousands)",
                  "data": [
                    743,
                    1313,
                    903,
                    507,
                    505,
                    890,
                    504,
                    1469,
                    1459,
                    1143,
                    504,
                    613
                  ],
                  "borderColor": "rgba(44, 62, 80, 1)",
                  "backgroundColor": "rgba(44, 62, 80, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "displayFormats": {
                      "month": "MMM YYYY",
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    },
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Line chart for Active Users (thousands)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Active Users (thousands)",
      "boardView": "graph",
      "index": 6,
      "_id": "675a851fad4e003d3d386cae",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Net Promoter Score (NPS) Over Time",
                  "data": [
                    56,
                    57,
                    88,
                    78,
                    58,
                    52,
                    61,
                    53,
                    59,
                    83,
                    64,
                    60
                  ],
                  "borderColor": "rgba(75, 192, 192, 1)",
                  "backgroundColor": "rgba(75, 192, 192, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "fill": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "labels": [
                    "2023-01",
                    "2023-02",
                    "2023-03",
                    "2023-04",
                    "2023-05",
                    "2023-06",
                    "2023-07",
                    "2023-08",
                    "2023-09",
                    "2023-10",
                    "2023-11",
                    "2023-12"
                  ],
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate Area chart for Net Promoter Score (NPS) Over Time"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Net Promoter Score (NPS) Over Time",
      "boardView": "graph",
      "index": 6,
      "_id": "675a85bbad4e003d3d386caf",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01",
                "2023-02",
                "2023-03",
                "2023-04",
                "2023-05",
                "2023-06",
                "2023-07",
                "2023-08",
                "2023-09",
                "2023-10",
                "2023-11",
                "2023-12"
              ],
              "datasets": [
                {
                  "label": "Net Promoter Score (NPS)",
                  "data": [
                    56,
                    57,
                    88,
                    78,
                    58,
                    52,
                    61,
                    53,
                    59,
                    83,
                    64,
                    60
                  ],
                  "backgroundColor": [
                    "rgba(75, 85, 99, 0.9)",
                    "rgba(37, 99, 235, 0.9)",
                    "rgba(220, 38, 38, 0.9)",
                    "rgba(16, 185, 129, 0.9)",
                    "rgba(245, 158, 11, 0.9)",
                    "rgba(49, 46, 129, 0.9)",
                    "rgba(124, 58, 237, 0.9)",
                    "rgba(220, 38, 38, 0.9)",
                    "rgba(5, 150, 105, 0.9)",
                    "rgba(217, 119, 6, 0.9)",
                    "rgba(30, 64, 175, 0.9)",
                    "rgba(190, 24, 93, 0.9)"
                  ]
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "stacked": true,
                  "ticks": {
                    "callback": "formatPercentage",
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "type": "category",
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "stacked": true,
                  "beginAtZero": true,
                  "type": "linear",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate stacked bar chart for Net Promoter Score (NPS)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Net Promoter Score (NPS)",
      "boardView": "graph",
      "index": 8,
      "_id": "675a8736ad4e003d3d386cb1",
      "invited": []
    }
  ]

const eCommerceLayout = [
    {
      "w": 10,
      "h": 3,
      "x": 0,
      "y": 12,
      "i": "0",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "1",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 6,
      "i": "2",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "3",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 6,
      "i": "4",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 3,
      "x": 0,
      "y": 9,
      "i": "5",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 0,
      "i": "6",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 3,
      "i": "7",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 7,
      "h": 3,
      "x": 3,
      "y": 9,
      "i": "8",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    }
  ]
  
export { eCommerceBoardData, eCommerceLayout };