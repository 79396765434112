/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../../assets/profile.png";
import axios from "../../context/axios";
import { AuthContext } from "../../context/context";
import { useStyles } from "./styles.js";
import { Box, Button, Card, Modal, TextField, Typography, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Close as CloseIcon, CameraAlt as CameraIcon } from '@mui/icons-material';

function Profile() {
  const {
    // state: { user },
    state,
    dispatch,
  } = React.useContext(AuthContext);

  const classes = useStyles();

  const [deleteModal, setDeleteModal] = useState(false);
  // const [confirmDelete, setConfirmDelete] = useState(0);

  const { user } = state;
  const [userSetting, setUserSetting] = React.useState({
    firstName: user ? user.firstName : "",
    lastName: user ? user.lastName : "",
    email: user ? user.email : "",
    phone: user ? user.phone : "",
    currency: user ? user.currency : "",
  });
  const history = useNavigate();
  const [alertClass, setAlertClass] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [err, setErr] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [fileUrl, setFileUrl] = React.useState("");

  const handleSetting = (e) => {
    const { name, value } = e.target;
    setUserSetting((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setFileUrl(reader.result);
        setFile(file);
      };
    }
  };

  const DeleteAccount = () => {
    setDeleteModal(true);
  };

  const changeProfile = async () => {
    setLoader(true);
    const formData = new FormData();
    try {
      formData.append("image", file, file.name);

      try {
        const profile = await axios.post("/profile", formData);
        if (profile.status === 200) {
          const user = await axios.get("/user");
          if (user.status === 200) {
            setErr("");
            setAlertClass("show");
            setFile("");
            dispatch({
              type: "SET_USER",
              payload: user.data.user,
            });
            setMsg(profile.data.message);
            setLoader(false);
          }
        }
      } catch (e) {
        setAlertClass("show");
        setMsg("");
        if (e.response && e.response.data) {
          if (e.response.data.error) {
            setErr(e.response.data.error.message);
          } else {
            setErr(e.response.data.message);
          }
        } else {
          setErr(e.message);
        }
        setLoader(false);
      }
    } catch (e) {}
  };
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      changeProfile();
      const update = await axios.patch("/setting", userSetting);
      if (update.status === 200) {
        const user = await axios.get("/user");
        if (user.status === 200) {
          setErr("");
          setAlertClass("show");
          setUserSetting({
            firstName: user !== null ? user.data.user.firstName : "",
            lastName: user !== null ? user.data.user.lastName : "",
            email: user !== null ? user.data.user.email : "",
            phone: user !== null ? user.data.user.phone : "",
            currency: user !== null ? user.data.user.currency : "",
          });
          dispatch({
            type: "SET_USER",
            payload: user.data.user,
          });
          setMsg(update.data.message);
          setLoader(false);
        }
      }
    } catch (e) {
      setAlertClass("show");
      setMsg("");
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };
  const handleCloseAlert = () => {
    setAlertClass("hide");
    setErr("");
    setMsg("");
  };
  const cancelProfileChange = () => {
    setFileUrl("");
  };

  const handleDeleteCancel = () => {
    setDeleteModal(false);
  }

  const handleDeleteConfirm = async () => {
    setDeleteModal(false);
     try {
        await axios.post("/deleteAccount");

        dispatch({
          type: "LOGOUT",
        });
        history.push("/login");
      } catch (e) {
        setAlertClass("show");
        setMsg("");
        if (e.response && e.response.data) {
          if (e.response.data.error) {
            setErr(e.response.data.error.message);
          } else {
            setErr(e.response.data.message);
          }
        } else {
          setErr(e.message);
        }
        setLoader(false);
      }

  }

  return (
    <Box className="col-8">
      <Card className={classes.mainCard} sx={{ p: 3, mt: 2 }}>
        
        {/* Success and Error Alerts */}
        {msg && (
          <Box className={`alert alert-success alert-dismissible fade ${alertClass}`} role="alert">
            <Typography variant="body2" component="strong">{msg}</Typography>
            <IconButton aria-label="close" onClick={handleCloseAlert}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {err && (
          <Box className={`alert alert-danger alert-dismissible fade ${alertClass}`} role="alert">
            <Typography variant="body2" component="strong">{err}</Typography>
            <IconButton aria-label="close" onClick={handleCloseAlert}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        
        {/* Profile Image Upload */}
        <Box display="flex" alignItems="center" mb={3}>
          <Box title="Change Profile" className="avatar-container" position="relative">
            <IconButton onClick={cancelProfileChange} sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
            <label htmlFor="file-input">
              <input type="file" id="file-input" name="file" onChange={(e) => onChangeFile(e)} hidden />
              <IconButton component="span">
                <CameraIcon />
              </IconButton>
            </label>
            <Box component="img" src={fileUrl || user?.profile || ProfileImage} alt="profile" sx={{ width: 100, height: 100, borderRadius: '50%' }} />
          </Box>
        </Box>
        
        {/* User Details Form */}
        <Box component="form" onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>

            <TextField label="First Name" name="firstName" value={userSetting.firstName} onChange={handleSetting} required fullWidth />
            <TextField label="Last Name" name="lastName" value={userSetting.lastName} onChange={handleSetting} required fullWidth />
            <TextField label="Email Address" name="email" value={userSetting.email} disabled fullWidth />
            <TextField label="Phone" name="phone" value={userSetting.phone} onChange={handleSetting} required fullWidth />
            
            <FormControl fullWidth>
              <InputLabel>Currency</InputLabel>
              <Select name="currency" value={userSetting.currency} onChange={handleSetting} required fullWidth>
                <MenuItem value="" disabled>Choose currency</MenuItem>
                <MenuItem value="$">United States Dollars</MenuItem>
                <MenuItem value="€">Euro</MenuItem>
                <MenuItem value="£">British Pound</MenuItem>
                <MenuItem value="CHF">Swiss Franc</MenuItem>
                <MenuItem value="₹">Indian Rupee</MenuItem>
                <MenuItem value="¥">Chinese Yuan</MenuItem>
              </Select>
            </FormControl>

          </Box>
          
          {/* Action Buttons */}
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button type="submit" variant="contained" sx={{ backgroundColor: 'black', color: 'white' }} disabled={loader}>
              {loader ? 'Loading...' : 'Save changes'}
            </Button>
            <Button variant="contained" color="error" onClick={DeleteAccount}>Delete Account</Button>
          </Box>

          {/* Delete Account Confirmation Modal */}
          <Modal open={deleteModal} onClose={handleDeleteCancel}>
            <Box sx={{
              position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
              width: 400, bgcolor: 'background.paper', p: 3, boxShadow: 24, borderRadius: 2,
            }}>
              <Typography variant="h6" gutterBottom>Are you sure you want to delete your account?</Typography>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button onClick={handleDeleteConfirm} variant="contained" sx={{ backgroundColor: '#000', color: '#fff' }}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Card>
    </Box>
  );
}

export default Profile;
