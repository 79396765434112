/* eslint-disable */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import * as React from 'react';
import { AuthContext } from '../../context/context';
// import { getIcon } from './helper';

import Freshbooks from '../../assets/freshbooks.png';
import QuickBooks from '../../assets/qb-logo.svg';
import Xero from '../../assets/xero.png';
import { Box } from '@mui/material';

const getIcon = {
  "QuickBooks": QuickBooks,
  "Xero": Xero,
  "Freshbooks": Freshbooks,
}


export default function IntegrationsButtonAddBoard() {
  const {
    state: {
      switchUser, integrationInfo, selectedIntegration, projects, selectedProject
    },
    dispatch,
  } = React.useContext(AuthContext);



  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = () => {
    ;
  };

  const handleClose = (e) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = async (event, index) => {

    setOpen(false);
    dispatch({
      type: 'SET_SELECTEDINTEGRATION',
      payload: index
    });
  };

  // React.useEffect(() => {
  //   getProjects(switchUser.userName)
  //     .then((projects) => {
  //       if (projects.length === 0) {
  //         dispatch({
  //           type: 'SET_PROJECTS',
  //           payload: [],
  //         });
  //       } else {

  //         dispatch({
  //           type: 'SET_PROJECTS',
  //           payload: projects,
  //         });
  //       }
  //     })
  //     .catch((err) => {

  //     });
  // }, [dispatch]);

  return (
    <>

      <ButtonGroup style={{ boxShadow: 'none', zIndex: "100", paddingTop: "5px", paddingBottom: "5px", background: "#fff" }} ref={anchorRef} aria-label="split button">
        {integrationInfo.integrations.length !== 0
          && (
            <Button
              variant="text"
              sx={{
                whiteSpace: 'nowrap', minWidth: 'max-content', fontSize: '1rem', paddingBottom: '0px', marginTop: '0px', paddingRight: '20px', paddingLeft: "20px", textTransform: 'none', color: "black", background: "#fff"
              }}
              onClick={handleClick}
            >
              <Box className="text" style={{ fontSize: '1rem', }}>
                {selectedIntegration < integrationInfo.integrations.length ?
                  <p style={{ fontSize: '1rem', }} >

                    <img src={getIcon[integrationInfo.integrations[selectedIntegration].integrationType]} className="mr-2" style={{ width: '163px', height: '50px', paddingRight: '10px' }} />
                    {integrationInfo.integrations[selectedIntegration].companyName}
                  </p>
                  :
                  <p style={{ fontSize: '1rem', }} >

                    <img src={getIcon[integrationInfo.integrations[0].integrationType]} className="mr-2" style={{ width: '163px', height: '50px',  paddingRight: '10px' }} />
                    {integrationInfo.integrations[0].companyName}
                  </p>
                }
              </Box>
            </Button>
          )}
        <Button
          variant="text"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{ paddingBottom: '0px', marginTop: '0px', paddingRight: '0px', background: '#fff' }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "100" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {integrationInfo.integrations.map((integrationVal, index) => (
                    <MenuItem
                      key={integrationVal._id}
                      selected={index === selectedIntegration}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{
                        whiteSpace: 'nowrap', minWidth: 'max-content', fontSize: '1rem', paddingBottom: '0px', marginTop: '0px', paddingRight: '20px', textTransform: 'none', color: 'black',
                      }}
        
                    >
                      <img src={getIcon[integrationVal.integrationType]} className="mr-2" style={{ width: '163px', height: '50px' }} /> {integrationVal.companyName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
