const SaaSBoardData = [

    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Annual Recurring Revenue (ARR) Over Time",
                  "data": [
                    2756,
                    3599,
                    2524,
                    2561,
                    3252,
                    3026,
                    1737,
                    3070,
                    3453,
                    3546,
                    2517,
                    3309
                  ],
                  "borderColor": "rgba(75, 0, 130, 1)",
                  "backgroundColor": "rgba(75, 0, 130, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "displayFormats": {
                      "month": "MMM YYYY",
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    },
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "type": "linear",
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate line chart for ARR."
        }
      ],
      "boardFormulas": [],
      "_id": "6748f6fa9c1a40e71985bbdd",
      "boardType": "graph",
      "title": "Annual Recurring Revenue (ARR) Over Time",
      "boardView": "graph",
      "index": 1,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "pie",
            "data": {
              "labels": [
                "Month 1",
                "Month 2",
                "Month 3",
                "Month 4",
                "Month 5",
                "Month 6",
                "Month 7",
                "Month 8",
                "Month 9",
                "Month 10",
                "Month 11",
                "Month 12"
              ],
              "datasets": [
                {
                  "label": "Churn Rate",
                  "data": [
                    3.06,
                    2.22,
                    2.15,
                    1.75,
                    2.45,
                    4.33,
                    2.31,
                    2.38,
                    2,
                    4.98,
                    3.81,
                    2.68
                  ],
                  "backgroundColor": [
                    "rgba(63, 81, 181, 1)",
                    "rgba(76, 175, 80, 1)",
                    "rgba(255, 87, 34, 1)",
                    "rgba(156, 39, 176, 1)",
                    "rgba(255, 235, 59, 1)",
                    "rgba(33, 150, 243, 1)",
                    "rgba(233, 30, 99, 1)",
                    "rgba(0, 150, 136, 1)",
                    "rgba(121, 85, 72, 1)",
                    "rgba(158, 158, 158, 1)",
                    "rgba(244, 67, 54, 1)",
                    "rgba(103, 58, 183, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true
            }
          },
          "query": "Generate pie chart for Churn Rate."
        }
      ],
      "boardFormulas": [],
      "_id": "6748f8e7e9123aeb45810c2a",
      "boardType": "graph",
      "title": "Churn Rate",
      "boardView": "graph",
      "index": 3,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01",
                "2023-02",
                "2023-03",
                "2023-04",
                "2023-05",
                "2023-06",
                "2023-07",
                "2023-08",
                "2023-09",
                "2023-10",
                "2023-11",
                "2023-12"
              ],
              "datasets": [
                {
                  "label": "Monthly Active Users (MAU) (%)",
                  "data": [
                    74.2,
                    58.1,
                    68.2,
                    51.5,
                    53,
                    70.1,
                    65.9,
                    70.5,
                    56.3,
                    50.9,
                    76.6,
                    58.6
                  ],
                  "backgroundColor": [
                    "rgba(34, 34, 34, 0.8)",
                    "rgba(44, 44, 44, 0.8)",
                    "rgba(54, 54, 54, 0.8)",
                    "rgba(64, 64, 64, 0.8)",
                    "rgba(74, 74, 74, 0.8)",
                    "rgba(84, 84, 84, 0.8)",
                    "rgba(94, 94, 94, 0.8)",
                    "rgba(104, 104, 104, 0.8)",
                    "rgba(114, 114, 114, 0.8)",
                    "rgba(124, 124, 124, 0.8)",
                    "rgba(134, 134, 134, 0.8)",
                    "rgba(144, 144, 144, 0.8)"
                  ],
                  "borderColor": [
                    "rgba(34, 34, 34, 1)",
                    "rgba(44, 44, 44, 1)",
                    "rgba(54, 54, 54, 1)",
                    "rgba(64, 64, 64, 1)",
                    "rgba(74, 74, 74, 1)",
                    "rgba(84, 84, 84, 1)",
                    "rgba(94, 94, 94, 1)",
                    "rgba(104, 104, 104, 1)",
                    "rgba(114, 114, 114, 1)",
                    "rgba(124, 124, 124, 1)",
                    "rgba(134, 134, 134, 1)",
                    "rgba(144, 144, 144, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "category",
                  "ticks": {
                    "maxRotation": 0,
                    "minRotation": 0,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate bar chart for Monthly Active Users."
        }
      ],
      "boardFormulas": [],
      "_id": "6748fb3fe9123aeb45810c2d",
      "boardType": "graph",
      "title": "Monthly Active Users (MAU) (%)",
      "boardView": "graph",
      "index": 5,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Marketing Spend Over Time",
                  "data": [
                    23,
                    41,
                    27,
                    21,
                    32,
                    41,
                    38,
                    44,
                    33,
                    37,
                    47,
                    30
                  ],
                  "borderColor": "rgba(75, 75, 192, 1)",
                  "backgroundColor": "rgba(75, 75, 192, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate line chart for Marketing Spend."
        }
      ],
      "boardFormulas": [],
      "_id": "6748fe5ee9123aeb45810c2f",
      "boardType": "graph",
      "title": "Marketing Spend Over Time",
      "boardView": "graph",
      "index": 6,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Trial Conversion Rate",
                  "data": [
                    33.31,
                    38.49,
                    25.29,
                    30.47,
                    29.2,
                    29.67,
                    25.25,
                    21.71,
                    34.59,
                    36.36,
                    25.2,
                    27.31
                  ],
                  "backgroundColor": [
                    "rgba(63, 81, 181, 1)",
                    "rgba(0, 150, 136, 1)",
                    "rgba(255, 87, 34, 1)",
                    "rgba(76, 175, 80, 1)",
                    "rgba(156, 39, 176, 1)",
                    "rgba(103, 58, 183, 1)",
                    "rgba(33, 150, 243, 1)",
                    "rgba(0, 188, 212, 1)",
                    "rgba(255, 193, 7, 1)",
                    "rgba(139, 195, 74, 1)",
                    "rgba(233, 30, 99, 1)",
                    "rgba(121, 85, 72, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "type": "linear",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate bar chart for Trial Conversion Rate."
        }
      ],
      "boardFormulas": [],
      "_id": "6748ff98e9123aeb45810c30",
      "boardType": "graph",
      "title": "Trial Conversion Rate",
      "boardView": "graph",
      "index": 7,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>Unlock the power of data with our cutting-edge SaaS business insights report template! Designed to spotlight the metrics that matter most, this app empowers you to track financial growth, understand customer behavior, and optimize product performance&mdash;all in one intuitive platform. Stay ahead of the competition with actionable insights, monitor trends over time, and make confident, data-driven decisions. Whether you're scaling your business or refining your strategy, this is the tool to transform your SaaS operations. Start using it today and watch your business thrive!</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "6749006fe9123aeb45810c31",
      "index": 8,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Revolutionize Your SaaS Insights!",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "scatter",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Net Revenue Retention Rate",
                  "data": [
                    {
                      "x": "2023-01-01",
                      "y": 119
                    },
                    {
                      "x": "2023-02-01",
                      "y": 111.3
                    },
                    {
                      "x": "2023-03-01",
                      "y": 113.8
                    },
                    {
                      "x": "2023-04-01",
                      "y": 118.4
                    },
                    {
                      "x": "2023-05-01",
                      "y": 98.1
                    },
                    {
                      "x": "2023-06-01",
                      "y": 95.7
                    },
                    {
                      "x": "2023-07-01",
                      "y": 114.5
                    },
                    {
                      "x": "2023-08-01",
                      "y": 92.2
                    },
                    {
                      "x": "2023-09-01",
                      "y": 110.7
                    },
                    {
                      "x": "2023-10-01",
                      "y": 92
                    },
                    {
                      "x": "2023-11-01",
                      "y": 112
                    },
                    {
                      "x": "2023-12-01",
                      "y": 95.5
                    }
                  ],
                  "borderColor": "rgba(0, 0, 128, 1)",
                  "backgroundColor": "rgba(0, 0, 128, 0.2)",
                  "pointBackgroundColor": "rgba(0, 0, 128, 1)",
                  "pointBorderColor": "rgba(0, 0, 128, 0.2)"
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Scatter chart for  Net Revenue Retention Rate"
        }
      ],
      "boardFormulas": [],
      "_id": "6759e97dad4e003d3d386c88",
      "boardType": "graph",
      "title": "Net Revenue Retention Rate",
      "boardView": "graph",
      "index": 8,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Customer Acquisition Cost (CAC) in 2023",
                  "data": [
                    1396,
                    814,
                    881,
                    571,
                    518,
                    868,
                    559,
                    1276,
                    717,
                    853,
                    1155,
                    1483
                  ],
                  "borderColor": "rgba(75, 192, 192, 1)",
                  "backgroundColor": "rgba(75, 192, 192, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "title": {
                    "display": true,
                    "text": "Month",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "title": {
                    "display": true,
                    "text": "CAC",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Line Chart for Customer Acquisition Cost (CAC) in 2023"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Customer Acquisition Cost (CAC) in 2023",
      "boardView": "graph",
      "index": 8,
      "_id": "675a7b51ad4e003d3d386ca3",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Annual Recurring Revenue (ARR) in Thousands",
                  "data": [
                    2756,
                    3599,
                    2524,
                    2561,
                    3252,
                    3026,
                    1737,
                    3070,
                    3453,
                    3546,
                    2517,
                    3309
                  ],
                  "borderColor": "rgba(44, 62, 80, 1)",
                  "backgroundColor": "rgba(44, 62, 80, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Line Chart for Annual Recurring Revenue (ARR) in Thousands"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Annual Recurring Revenue (ARR) in Thousands",
      "boardView": "graph",
      "index": 8,
      "_id": "675a7bf8ad4e003d3d386ca4",
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>Customer Acquisition Cost (CAC) is a key performance metric that measures the average expense incurred to acquire a new customer. This includes marketing and sales expenses divided by the total number of new customers acquired. Monitoring CAC is critical for understanding the efficiency of our customer acquisition strategies and maintaining sustainable growth.</p>\n<p>In this section, we provide insights into our CAC trends for 2023, analyzing cost allocation and comparing it against industry benchmarks to identify opportunities for optimization.</p>"
        }
      ],
      "boardFormulas": [],
      "index": 9,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Customer Acquisition Cost (CAC) in 2023",
      "_id": "675a7c65ad4e003d3d386ca5",
      "invited": []
    }
  ]


const SaaSLayout = [
  
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 11,
      "i": "0",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 6,
      "i": "1",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 8,
      "i": "2",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 9,
      "i": "3",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 12,
      "i": "4",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 0,
      "i": "5",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 3,
      "i": "6",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 5,
      "i": "7",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "8",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "9",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    }
  ]

export { SaaSBoardData, SaaSLayout };