
/* eslint-disable */
export const convertToKMB = (value) => {
    let dollarValue = parseInt(value);
    if (dollarValue >= 1000000000) {
        return '$' + (dollarValue / 1000000000).toFixed(2).replace(/\.00$/, '') + 'B';
    } else if (dollarValue >= 1000000) {
        return '$' + (dollarValue / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
    } else if (dollarValue >= 1000) {
        return '$' + (dollarValue / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
    } else {
        return '$' + (dollarValue).toFixed(2).replace(/\.00$/, '');
    }
}