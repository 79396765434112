/* eslint-disable */

import axios from './axios';

export const addCompany = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/company', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});


export const addCompanyName = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/addCompanyName', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});

export const myCompany = async () => new Promise((resolve, reject) => {
  axios
    .get('/mycompany')
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {

      reject(err);
    });
});