/* eslint-disable */

import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { colors } from '../../../context/colors';
import { AuthContext } from '../../../context/context';
import { getRevenue } from '../../../context/fetch-service';
import { addPlan, deletePlan, updatePlan } from '../../../context/revenue-service';
import { getTotalValue } from './InputUtils';
import DisplayInputs from './RevnueInputs';

export default function RevenueEntry(props) {
  const { state, dispatch } = React.useContext(AuthContext);
  const { switchUser, revenues } = state;
  const { currentScenario } = state;
  const [stateDrawer, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [updateValue, setUpdateValue] = React.useState(false);
  const [basic, setBasic] = React.useState(true);
  const [compound, setCompound] = React.useState(false);
  const [inputForm, setInputForm] = React.useState({
    name: '',
    customers: '',
    model: '',
    revenueSource: '',
    price: '',
    startDate: '',
    endDate: '',
    type: 'Yearly',
    sharedId: switchUser.userName,
    scenarioId: currentScenario,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
      return;
    }
    setState({ ...stateDrawer, [anchor]: open });
  };


  React.useEffect(() => {
    if (props.value) {
      setInputForm(props.value);
      setUpdateValue(true);
    }
  }, []);

  function setStartDate(startDate) {
    setInputForm((prevState) => ({
      ...prevState,
      startDate,
    }));
  }
  function setEndDate(endDate) {
    setInputForm((prevState) => ({
      ...prevState,
      endDate,
    }));
  }

  const handleInputFormChange = (e) => {

    const { name, value } = e.target;
    setInputForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (event) => {
    if (event.target.value === 'basic') {
      setBasic(true);
      setCompound(false);
    } else {
      setCompound(true);
      setBasic(false);
    }
  };

  const handleSubmit = async () => {
    try {
      inputForm.color = colors[Math.floor(Math.random() * colors.length - 1)];
      if (updateValue) {

        const update = await updatePlan({ revenueId: props.id, revenueInputId: inputForm._id, data: inputForm });
        if (update.status === 200 || update.status === 201) {
          const revenues = await getRevenue(switchUser.userName, currentScenario);
          dispatch({
            type: 'SET_REVENUE',
            payload: revenues,
          });
        }

      } else {
        const add = await addPlan(inputForm);
        if (add.status === 200 || add.status === 201) {
          const revenues = await getRevenue(switchUser.userName, currentScenario);
          dispatch({
            type: 'SET_REVENUE',
            payload: revenues,
          });
        }
      }
      toggleDrawer('right', false);
    } catch (err) {

    }
  };

  const handleDelete = async () => {
    try {
      inputForm.color = colors[Math.floor(Math.random() * colors.length - 1)];
      const deleteRevenue = await deletePlan({ revenueId: props.id, revenueInputId: inputForm._id });
      if (deleteRevenue.status === 200 || deleteRevenue.status === 201) {
        const revenues = await getRevenue(switchUser.userName, currentScenario);
        dispatch({
          type: 'SET_REVENUE',
          payload: revenues,
        });
      }
      toggleDrawer('right', false);
    } catch (err) {

    }
  };

  const list = (anchor) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{ width: 500 }}
        role="sidebar"
      >
        <List style={{ paddingBottom: '0px', paddingTop: '50px', marginBottom: '0px' }}>
          <div className="row" style={{ padding: '0px 50px 50px 50px' }}>
            <h2> August 2021 - revenue</h2>
            <h2>
              {' '}
              {getTotalValue(revenues)}
              {' '}
            </h2>
            <hr />
            <div className="row">
              <div className="col-sm-11">
                <h2>
                  {props.type}
                  {' '}
                  Entry
                </h2>
              </div>
              <div className="col-sm-1">
                {updateValue &&
                  <Button
                    onClick={() => handleDelete()}
                  >
                    <DeleteIcon />
                  </Button>
                }
              </div>
            </div>

            <div className="pt-3">
              <FormControl>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="basic"
                    control={<Radio checked={basic} size="small" />}
                    label={(
                      <Typography sx={{ fontSize: 12 }}>
                        Basic
                      </Typography>
                    )}
                  />
                  <FormControlLabel
                    value="compound"
                    control={<Radio checked={compound} size="small" />}
                    label={(
                      <Typography sx={{ fontSize: 12 }}>
                        Compound
                      </Typography>
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="pt-3">
              <TextField
                suffix="%"
                style={{ paddingBottom: '15px', width: '400px' }}
                name="name"
                onChange={handleInputFormChange}
                label="Name"
                value={inputForm.name}
              />
              {basic
                ? (
                  <>
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="model"
                      onChange={handleInputFormChange}
                      label="Model"
                      value={inputForm.model}
                    />
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="revenueSource"
                      onChange={handleInputFormChange}
                      label="Revenue Source"
                      value={inputForm.revenueSource}
                    />
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="price"
                      onChange={handleInputFormChange}
                      label="Amount"
                      value={inputForm.price}
                    />
                  </>
                )
                : (
                  <>
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="customers"
                      onChange={handleInputFormChange}
                      label="Customers"
                      value={inputForm.customers}
                    />
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="model"
                      onChange={handleInputFormChange}
                      label="Model"
                      value={inputForm.model}
                    />
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="revenueSource"
                      onChange={handleInputFormChange}
                      label="Revenue Source"
                      value={inputForm.revenueSource}
                    />
                    <TextField
                      suffix="%"
                      style={{ paddingBottom: '15px', width: '400px' }}
                      name="price"
                      onChange={handleInputFormChange}
                      label="Monthly Contract Value"
                      value={inputForm.price}
                    />
                    <div className="row" style={{ paddingRight: '0px' }}>
                      <div className="col-sm-6">
                        <DesktopDatePicker
                          label="Start Date"
                          inputFormat="MM/dd/yyyy"
                          value={inputForm.startDate || null}
                          name="startDate"
                          onChange={(e) => setStartDate(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                      <div className="col-sm-6">
                        <DesktopDatePicker
                          label="End Date"
                          inputFormat="MM/dd/yyyy"
                          value={inputForm.endDate || null}
                          name="endDate"
                          onChange={(e) => setEndDate(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                    </div>

                  </>
                )}
            </div>

          </div>
        </List>
        <div className="row" style={{ padding: '0px 50px 50px 50px' }}>
          <div className="col-sm-6">
            <Button
              variant="outlined"
              style={{
                whiteSace: 'nowrap', minWidth: '100%', fontSize: '10px', paddingTop: '10px',
              }}
              onClick={toggleDrawer('right', false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-sm-6">

            <Button
              variant="contained"
              style={{
                whiteSace: 'nowrap', minWidth: '100%', fontSize: '10px', paddingTop: '10px',
              }}
              onClick={() => handleSubmit()}
            >

              Save
            </Button>
          </div>
        </div>
        <div className="row" style={{ padding: '0px 50px 50px 50px' }}>
          {revenues &&
            <DisplayInputs />
          }

        </div>
      </Box>
    </LocalizationProvider>
  );

  return (
    <>
      {props.type === 'Edit'
        ? (
          <Button onClick={toggleDrawer('right', true)}>
            <MoreHorizIcon />
            {' '}
          </Button>
        )

        : (
          <Button
            variant="text"
            onClick={toggleDrawer('right', true)}
            style={{
              whiteSace: 'nowrap', minWidth: 'max-content', fontSize: '10px', paddingTop: '10px',
            }}
          >
            + New Entry
          </Button>
        )}
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={stateDrawer.right}
          onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
      </React.Fragment>
    </>
  );
}
