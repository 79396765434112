import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../context/axios';
import { AuthContext } from '../../context/context';

function Invites() {
  const history = useNavigate();
  const {
    state: {
      isAuthenticated, user, switchUser,
    },
    dispatch,
  } = React.useContext(AuthContext);
  const [myInvites, setMyInvites] = React.useState([]);

  // React.useEffect(async () => {
  //   const url = '/getInvites';

  //   axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
  //   await axios
  //     .post(url)
  //     .then((res) => {


  //       setMyInvites(res.data.data[0].invites);
  //     })
  //     .catch((err) => {

  //     });
  // }, []);

  React.useEffect(() => {
    const fetchInvites = async () => {
      const url = '/getInvites';
  
      axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
      
      try {
        const res = await axios.post(url);
        setMyInvites(res.data.data[0].invites);
      } catch (err) {
        console.error(err);  // Handle the error as needed
      }
    };
  
    fetchInvites();
  }, []);
  

  const switchUserProfile = (userName, userAccess) => {
    dispatch({
      type: 'SWITCH_USER',
      payload: {
        userName,
        userAccess,
      },
    });
    history.push('/forecasts');
  };

  const CurrentUser = () => (
    <>
      <div>
        <div
          className="row p-2 border-bottom align-middle"
          style={{
            margin: 0,
          }}
        >

          <div className="col-1" />
          <div className="col-5">
            <h2
              style={{
                fontSize: 14,
                color: '#454545',
                marginBottom: 0,
              }}
              className="text-uppercase"
            >
              Currently On:
              {' '}
              {switchUser.userName}
            </h2>
          </div>
          <div className="col-3">
            <h2
              style={{
                fontSize: 14,
                color: '#454545',
                marginBottom: 0,
              }}
              className="text-uppercase"
            >
              {' '}
              Access Type:
              {' '}
              {switchUser.userAccess === 'CAN_MODIFY' ? <> Full Access </> : <> View Only </>}
            </h2>
          </div>

        </div>
      </div>
    </>
  );

  const Header = () => (
    <>
      <div>
        <div
          className="row p-2 border-bottom align-middle"
          style={{
            margin: 0,
          }}
        >

          <div className="col-1">
            #
          </div>
          <div className="col-3">
            <h2
              style={{
                fontSize: 14,
                color: '#454545',
                marginBottom: 0,
              }}
              className="text-uppercase"
            >
              User
            </h2>
          </div>
          <div className="col-3">
            <h2
              style={{
                fontSize: 14,
                color: '#454545',
                marginBottom: 0,
              }}
              className="text-uppercase"
            >
              {' '}
              Access Type
            </h2>
          </div>

        </div>
      </div>
    </>
  );

  const RenderInvites = (props) => (
    <>
      <div>
        {props.invites.map((invite, i) => (
          <div
            className="row p-2 border-bottom align-middle"
            style={{
              margin: 0,
            }}
          >
            <div className="col-1">
              {i + 1}
            </div>
            <div className="col-3">
              <div
                className="text-lowercase"
              >

                {invite.userId}
              </div>
            </div>
            <div className="col-3">
              <div className="k-chip k-chip-filled">
                <div className="k-chip-content">
                  {invite.access === 'CAN_MODIFY' ? <> Full Access </> : <> View Only </>}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="k-chip k-chip-filled">
                <div className="k-chip-content">
                  <Button onClick={() => switchUserProfile(invite.userId, invite.access)}> Switch </Button>
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
    </>
  );
  return (
    <div>
      {myInvites.length < 1
        ? <h1> There are currently no team Members </h1>
        : (
          <>
            <div>
              <CurrentUser />
              <Header />
              <div
                className="row p-2 border-bottom align-middle"
                style={{
                  margin: 0,
                }}
              >
                <div className="col-1">
                  0
                </div>
                <div className="col-3">
                  <div
                    className="text-lowercase"
                  >

                    {user.email}
                  </div>
                </div>
                <div className="col-3">
                  <div className="k-chip k-chip-filled">
                    <div className="k-chip-content">
                      Full Access
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="k-chip k-chip-filled">
                    <div className="k-chip-content">
                      <Button onClick={() => switchUserProfile(user.email, 'CAN_MODIFY')}> Switch </Button>
                    </div>
                  </div>
                </div>

              </div>
              <RenderInvites key="index" invites={myInvites} />
            </div>
          </>
        )}
      {' '}
      <br />
    </div>
  );
}

export default Invites;
