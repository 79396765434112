import React from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import FinproRoutes from './FinproRoutes';
import { AuthContext, initialState, reducer } from './context/context';
import { getCurrentUser } from './service';
import { Box } from '@mui/material';

// Temporary makeStyles import from @mui/material/styles for migration
// import { makeStyles } from 'tss-react/mui';

const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`
};

const StyledStyledEngineProvider = styled(StyledEngineProvider)({
  [`& .${classes.root}`]: {
    // Add any styles here
    backgroundColor: '#f5f5f5',
  },
});

require('./RoundedBars');

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);


  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    dispatch({ type: 'TOGGLE_FULLSCREEN' });
  };

  // Set a timeout for 15 minutes
  const inactivityTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
  let timeoutId;

  // Function to reset the timeout
  const resetInactivityTimeout = () => {
    clearTimeout(timeoutId); // Clear the existing timeout
    timeoutId = setTimeout(() => {
      // Perform logout action after 15 minutes of inactivity
      dispatch({
        type: 'LOGOUT',
      });
      window.location.href = '/';
    }, inactivityTimeout);
  };

  // Call resetInactivityTimeout whenever there's user activity
  const handleUserActivity = () => {
    resetInactivityTimeout();
  };

  // Attach event listeners to monitor user activity
  React.useEffect(() => {
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  // Check for expiration only if the user is logged in
  if (getCurrentUser() && new Date(getCurrentUser().exp * 1000).getTime() < new Date().getTime()) {
    // Perform logout if the token has expired
    dispatch({
      type: 'LOGOUT',
    });
    window.location.href = '/';
  } else {
    // Reset inactivity timeout if the user is still logged in
    resetInactivityTimeout();
  }

  return (
          <Box>
            {/* <Helmet>
              <style>{'body { background-color: white;} h1 { color: black; }'}</style>
            </Helmet> */}
            <AuthContext.Provider
              value={{
                state,
                dispatch,
                toggleFullScreen,
              }}
            >
              <FinproRoutes />
            </AuthContext.Provider>
          </Box>
  );
}

export default App;
