import axios from './axios';

export const addPlan = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/addRevenue', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const deletePlan = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteRevenue', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const updatePlan = async (data) => new Promise((resolve, reject) => {
  axios
    .put('/updateRevenue', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addExpense = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/addExpense', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});
export const updateExpense = async (data) => new Promise((resolve, reject) => {
  axios
    .put('/updateExpense', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const deleteExpense = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteExpense', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addStartingCapital = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/addStartingCapital', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});
export const updateStartingCapital = async (data) => new Promise((resolve, reject) => {
  axios
    .put('/updateStartingCapital', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const deleteStartignCapital = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/deleteStartingCapital', data)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const updateGAChurn = async (scenarioId, grateYearly, grateQuarterly, grateMonthly, crateYearly, crateQuarterly, crateMonthly) => new Promise((resolve, reject) => {
  axios
    .post('/updateGAChurn', {
      scenarioId, grateYearly, grateQuarterly, grateMonthly, crateYearly, crateQuarterly, crateMonthly,
    })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

// export const addGrowthRateInputs = async (data) => new Promise((resolve, reject) => {
//   axios
//     .post('/addGrowthRateInputs', data)
//     .then((rev) => {
//       resolve(rev);
//     })
//     .catch((err) => {
//
//       reject(err);
//     });
// });
// export const updateGrowthRateInputs = async (data) => new Promise((resolve, reject) => {
//   axios
//     .put('/updateGrowthRateInputs', data)
//     .then((rev) => {
//       resolve(rev);
//     })
//     .catch((err) => {
//
//       reject(err);
//     });
// });

// export const deleteGrowthRateInputs = async (data) => new Promise((resolve, reject) => {
//   axios
//     .post('/deleteGrowthRateInputs', data)
//     .then((rev) => {
//       resolve(rev);
//     })
//     .catch((err) => {
//
//       reject(err);
//     });
// });
