/* eslint-disable */

import React, { useEffect, useState } from 'react';
import BS from '../../assets/BS.svg';
import CF from '../../assets/CF.svg';
import PL from '../../assets/PL.svg';
import { AuthContext } from '../../context/context';
import AddFormulas from './AddFormulas';
import SearchData from './SearchData';
import EditFormula from './EditFormula';

export const options = {
    title: "Chart View",
    is3D: true,
};

const BoardFormulas = (props) => {
    let letterIndex = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    const {
        state: {
            integrations, integrationInfo, selectedIntegration, allBoards, selectedBoard, selectedTab
        }, dispatch
    } = React.useContext(AuthContext);

    const [activeTab, setActiveTab] = useState(0);
    const [search, setSearch] = useState(true);
    const [formulas, setFormulas] = useState(true);
    const [selectedList, setSelectedList] = useState(props.selectedList ?? []);
    const [selectedFormulas, setSelectedFormulas] = useState(props.selectedFormulas ?? []);
    

    React.useEffect(() => {
        if (props.dataTable !== null) {
            if (props.dataTable && selectedList && selectedList.length > 0) {
                const updatedSelectedList = selectedList.map((selectedItem) => {
                    const matchingItem = props.dataTable.find(
                        (dataItem) => dataItem.name === selectedItem.name
                    );
                    if (matchingItem) {
                        selectedItem.object.amount = matchingItem.amount
                        selectedItem.object.items = matchingItem.items
                        return selectedItem;
                    }
                    return selectedItem;
                });

                setSelectedList(updatedSelectedList);
                // 
                props.setListItemsParsed(updatedSelectedList, props.dataTable)
                props.setListItemsFormulasParsed(selectedFormulas, null, props.dataTable);
            }
        }
    }, [props.dataTable]);

    useEffect(() => {
        if (props.edit) {
            const currentBoard = allBoards.boards[selectedBoard].tabs[selectedTab].boardData.find(board => board._id === props.boardId);
            setSelectedList(currentBoard.boardData);
            setSelectedFormulas(currentBoard.boardFormulas)
            let i = 2;
            // if (currentBoard.boardView === "Income Statement") {
            //     i = 0;
            // } else if (currentBoard.boardView === "Balance Sheet") {
            //     i = 1;
            // } else {
            //     i = 2;
            // }
            setActiveTab(i)
            setSelectedList(currentBoard.boardData)
            setSelectedFormulas(currentBoard.boardFormulas)
        }
    }, [props.edit])

    const [msg, setMsg] = React.useState("")
    const [type, setType] = React.useState('warning');
    const [openMsg, setOpenMsg] = React.useState(false);
    
    const [editMsg, setEditMsg] = React.useState("")
    const [editType, setEditType] = React.useState('warning');
    const [editOpenMsg, setEditOpenMsg] = React.useState(false);

    function toggleSearch() {
        setSearch(!search);
    }

    function toggleFormulas() {
        setFormulas(!formulas);
    }



    function handleActiveTab(i) {
        // if (!props.loadingIncome && !props.loadingBalance && !props.loadingCashflow)
        //     if (i !== activeTab) {
        //         if (i === 0) {
        //             props.setCurrentViewPage("Income Statement")
        //         } else if (i === 1) {
        //             props.setCurrentViewPage("Balance Sheet")
        //         } else {
        //             props.setCurrentViewPage("Cash Flow")
        //         }
        //         setActiveTab(i)
        //         // setSelectedList([])
        //         // setSelectedFormulas([])
        //     }
    }

    function checkDuplicateName(array, data) {
        return array.some(obj => obj.name === data.name);
    }

    const addToList = (data) => {
        if (!checkDuplicateName(selectedList, data)) {
            const newList = [...selectedList]; 
            let newData = {
                id: letterIndex[newList.length],
                name: data.name,
                object: data,
                hidden: false
            }
            newList.push(newData);
            setSelectedList(newList)
            props.setListItemsParsed(newList);
            // props.setListItemsFormulasParsed(selectedFormulas);
        }
    }

    const toggleHidden = (i) => {
        let newList = [...selectedList];
        if (newList[i] && newList[i].hidden !== undefined) {
            newList[i].hidden = !newList[i].hidden;
        }
        //  else {
        //     newList[i] = { ...newList[i], hidden: true }; // Add the `.hidden` property if it doesn't exist
        // }
        setSelectedList(newList)
        props.setListItemsParsed(newList);
    }

    const removeList = (i) => {
        let newList = [...selectedList];
        if (i > -1) {
            newList.splice(i, 1);
        }
        setSelectedList(newList)
        props.setListItemsParsed(newList);
        // props.setListItemsFormulasParsed(selectedFormulas);

    }



    const addToListFormulas = (data) => {
        // Check if data.name already exists in selectedFormulas
        const nameExists = selectedFormulas.some((item) => item.name === data.name);

        if (!nameExists) {
            // If data.name does not exist, proceed with adding the data to selectedFormulas
            data.id = selectedFormulas.length + 1;
            const newList = [...selectedFormulas];
            newList.push(data);
            setSelectedFormulas(newList);
            props.setListItemsFormulasParsed(newList);
            setMsg(`Formula with name ${data.name} Added!`)
            setType('success')
            setOpenMsg(true)


        } else {
            // If data.name already exists, handle the situation (e.g., show an error message)
            // ;
            setMsg(`Formula with name ${data.name} already exists!`)
            setType("warning")
            setOpenMsg(true)
            // Or you can choose to do nothing and not add the duplicate entry.
        }
    };

    const editToListFormulas = (data, name) => {
        selectedFormulas.forEach((item, index) => {
          if(item.name === name) {
            let newList = [...selectedFormulas];
            removeListFormulas(index);
            newList[index] = data;
            setSelectedFormulas(newList);
            props.setListItemsFormulasParsed(newList);
          }
        });
        setEditMsg(`Formula with name ${data.name} Edited!`)
        setEditType('success')
        setEditOpenMsg(true)
    };

    const removeListFormulas = (i) => {
        let newList = [...selectedFormulas];
        let removeVal = selectedFormulas[i].name;

        if (i > -1) {
            newList.splice(i, 1);
        }
        setSelectedFormulas(newList)
        props.setListItemsFormulasParsed(newList, removeVal);
    }

    return (
        <>
            <div style={{ width: "401px" }}>
            <div class="ui top attached tabular menu">
                <div onClick={() => handleActiveTab(2)} class="active item" style={{ width: '100%', borderBottom: "1px solid #d4d4d5" }}>
                    <h3>Select Financial Data</h3>
                </div>
            </div>
                {props.loadingIncome || props.loadingBalance || props.loadingCashflow ?
                    <div class={"ui bottom attached active tab segment"}>
                        <div>
                            <div class="ui container pb-3">
                                <div className="loading-container">
                                    <div className="loading-row" />
                                    <div className="loading-row" />
                                    <div className="loading-row" />
                                    <div className="loading-row" />
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div class={"ui bottom attached active tab segment"}>
                        <div>
                            <div class="ui container pb-3">
                                <div className="row" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: "300px" }}>
                                    <p style={{ fontWeight: "Bold" }}> Data </p>
                                    <button class="circular icon button ui"
                                        onClick={() => toggleSearch()} style={{ width: "40px", marginLeft: "100%" }}>
                                        <i class="icon plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div>
                                {selectedList && selectedList.map((list, i) => {
                                    return (
                                        <div className="board-select"
                                            style={{
                                                paddingTop: "5px", marginBottom: "10px", marginRight: "25px", paddingLeft: "10px",
                                                display: 'grid', gridTemplateColumns: '0.5fr 3fr 1fr',
                                            }}
                                            key={`selectedList-${i}`}
                                        >
                                            <i class="circular icon ui" style={{ backgroundColor: "#01E98F", color: "#FFFFFF", border: 0 }} >
                                                {list.id}
                                            </i>
                                            <div style={{ paddingTop: "5px" }}>
                                                {list.name}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button class="ui button" style={{ backgroundColor: "transparent" }} onClick={() => toggleHidden(i)}>
                                                    <i class={list.hidden ? "eye slash icon" : "eye icon"}></i>
                                                </button>
                                                <button class="ui button" style={{ backgroundColor: "transparent" }} onClick={() => removeList(i)}>
                                                    <i class="minus icon"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div hidden={search} style={{ paddingTop: "2px" }}>
                                <SearchData content={props.dataTable}
                                            indices = {props.indices}
                                            addToList={addToList} />
                            </div>
                            {selectedList && selectedList.length > 0 &&
                                <div class="ui container pb-3 mt-5">
                                    <div className="row" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: "300px" }}>
                                        <p style={{ fontWeight: "Bold" }}> Formulas </p>
                                        <AddFormulas type={type} msg={msg} openMsg={openMsg} setOpenMsg={setOpenMsg} addToListFormulas={addToListFormulas} />
                                    </div>
                                </div>
                            }
                            <div>
                                {selectedFormulas && selectedFormulas.map((list, i) => {
                                    return (
                                        <div className="board-select"
                                            style={{
                                                paddingTop: "5px", paddingBottom: "5px",marginBottom: "10px", marginRight: "25px", paddingLeft: "10px",
                                                display: 'grid', gridTemplateColumns: '0.5fr 3fr 1fr',
                                            }}
                                            key={`selectedFormulas-${i}`}
                                        >
                                            <i class="circular icon ui" style={{ backgroundColor: "#01E98F", color: "#FFFFFF", border: 0 }} >
                                                {list.id}
                                            </i>
                                            <div style={{ paddingTop: "5px" }}>
                                                {list.name}
                                            </div>
                                            {/* <button class="ui button" style={{ backgroundColor: "transparent" }} onClick={() => toggleHidden(i)}>
                                                <i class={list.hidden ? "eye slash icon" : "eye icon"}></i>
                                            </button> */}
                                            <EditFormula index = {i}name={list.name} selectedFormulas = {selectedFormulas} formula={list.formula} type={editType} msg={editMsg} openMsg={editOpenMsg} setOpenMsg={setEditOpenMsg} editToListFormulas={editToListFormulas} setListItemsFormulasParsed={props.setListItemsFormulasParsed} setSelectedFormulas={setSelectedFormulas}/>
                                            {/* <button class="ui button right floated" style={{
                                                backgroundColor: "transparent",
                                            }}
                                                onClick={() => removeListFormulas(i)}>
                                                <i class="minus icon"></i>
                                            </button> */}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                }
            </div>
        </>
    );
};

export default BoardFormulas;
