/* eslint-disable */
import React from 'react';

export default function Spinner(props) {
    const height = props.height;
    return (
        <div className="ui segment" style={{ height: height, padding: "0px 0px 0px 0px", margin: "0px 0px 0px 0px" }}>
            <div className="ui active inverted dimmer">
                <div className="ui text loader">Loading</div>
            </div>
            <p></p>
        </div>
    )
}