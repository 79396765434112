/* eslint-disable */

import React from 'react';
export const options = {
    title: "",
};

const TextBoard = (props) => {
    return (
        <>{props.board && props.board.boardData && props.board.boardData[0] && props.board.boardData[0].text &&
            <div style={{ overflow: 'auto', width: '100%' }}>
                <p dangerouslySetInnerHTML={{ __html: props.board.boardData[0].text }} />
            </div>
        }
        </>
    );
};

export default TextBoard;
