const HealthcareBoardData = [
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Cost per Patient (in dollars)",
                  "data": [
                    2030,
                    2083,
                    1852,
                    2033,
                    1821,
                    1995,
                    2093,
                    1872,
                    1897,
                    1834,
                    2163,
                    1916
                  ],
                  "backgroundColor": [
                    "rgba(50, 50, 100, 1)",
                    "rgba(70, 70, 120, 1)",
                    "rgba(90, 90, 140, 1)",
                    "rgba(110, 110, 160, 1)",
                    "rgba(130, 130, 180, 1)",
                    "rgba(150, 150, 200, 1)",
                    "rgba(170, 170, 220, 1)",
                    "rgba(190, 190, 240, 1)",
                    "rgba(210, 210, 255, 1)",
                    "rgba(230, 230, 275, 1)",
                    "rgba(250, 250, 295, 1)",
                    "rgba(255, 255, 315, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate bar charts for Cost per Patient (in dollars)"
        }
      ],
      "boardFormulas": [],
      "_id": "67522802707e500fe2c1e4bb",
      "boardType": "graph",
      "title": "Cost per Patient (in dollars)",
      "boardView": "graph",
      "index": 1,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Hospital Readmission Rates (%)",
                  "data": [
                    5.5,
                    4.9,
                    4.7,
                    4,
                    4.5,
                    4.3,
                    5.1,
                    5.1,
                    4.5,
                    4.9,
                    5.7,
                    4.2
                  ],
                  "borderColor": "rgba(75, 0, 130, 1)",
                  "backgroundColor": "rgba(75, 0, 130, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate line charts for Hospital Readmission Rates (%)"
        }
      ],
      "boardFormulas": [],
      "_id": "675228c0707e500fe2c1e4bc",
      "boardType": "graph",
      "title": "Hospital Readmission Rates (%)",
      "boardView": "graph",
      "index": 2,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Accounts Receivable Days",
                  "data": [
                    28,
                    31,
                    28,
                    28,
                    25,
                    32,
                    25,
                    30,
                    26,
                    25,
                    30,
                    31
                  ],
                  "borderColor": "rgba(39, 55, 70, 1)",
                  "backgroundColor": "rgba(39, 55, 70, 0.5)",
                  "borderWidth": 1,
                  "fill": true
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "type": "linear",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate area charts for Accounts Receivable Days"
        }
      ],
      "boardFormulas": [],
      "_id": "67522925707e500fe2c1e4bd",
      "boardType": "graph",
      "title": "Accounts Receivable Days",
      "boardView": "graph",
      "index": 3,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>This template provides a clear snapshot of key financial and operational metrics crucial to healthcare success. Monitor Treatment Success Rate, Cost per Patient, Hospital Readmission Rate, Accounts Receivable Days, and Revenue to drive informed decisions.</p>\n<p>Empower your organization with actionable insights to enhance patient care and optimize financial performance!</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "67522b18707e500fe2c1e4bf",
      "index": 5,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Pulse of Healthcare Performance Template",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Monthly Revenue (in millions)",
                  "data": [
                    65,
                    68,
                    51,
                    59,
                    62,
                    53,
                    64,
                    64,
                    61,
                    55,
                    59,
                    58
                  ],
                  "backgroundColor": [
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(39, 55, 70, 1)",
                    "rgba(41, 128, 185, 1)",
                    "rgba(22, 160, 133, 1)",
                    "rgba(39, 174, 96, 1)",
                    "rgba(192, 57, 43, 1)",
                    "rgba(142, 68, 173, 1)",
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(39, 55, 70, 1)",
                    "rgba(41, 128, 185, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate bar charts for Revenue."
        }
      ],
      "boardFormulas": [],
      "_id": "67522bb1707e500fe2c1e4c0",
      "boardType": "graph",
      "title": "Monthly Revenue (in millions)",
      "boardView": "graph",
      "index": 6,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bubble",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Treatment Success Rates (%)",
                  "data": [
                    {
                      "x": "2023-01-01",
                      "y": 88,
                      "r": 5
                    },
                    {
                      "x": "2023-02-01",
                      "y": 87,
                      "r": 5
                    },
                    {
                      "x": "2023-03-01",
                      "y": 90,
                      "r": 5
                    },
                    {
                      "x": "2023-04-01",
                      "y": 92,
                      "r": 5
                    },
                    {
                      "x": "2023-05-01",
                      "y": 87,
                      "r": 5
                    },
                    {
                      "x": "2023-06-01",
                      "y": 93,
                      "r": 5
                    },
                    {
                      "x": "2023-07-01",
                      "y": 87,
                      "r": 5
                    },
                    {
                      "x": "2023-08-01",
                      "y": 90,
                      "r": 5
                    },
                    {
                      "x": "2023-09-01",
                      "y": 93,
                      "r": 5
                    },
                    {
                      "x": "2023-10-01",
                      "y": 93,
                      "r": 5
                    },
                    {
                      "x": "2023-11-01",
                      "y": 86,
                      "r": 5
                    },
                    {
                      "x": "2023-12-01",
                      "y": 88,
                      "r": 5
                    }
                  ],
                  "backgroundColor": [
                    "rgba(72, 61, 139, 1)",
                    "rgba(47, 79, 79, 1)",
                    "rgba(60, 60, 60, 1)",
                    "rgba(85, 107, 47, 1)",
                    "rgba(70, 130, 180, 1)",
                    "rgba(139, 69, 19, 1)",
                    "rgba(112, 128, 144, 1)",
                    "rgba(47, 79, 79, 1)",
                    "rgba(72, 61, 139, 1)",
                    "rgba(139, 0, 0, 1)",
                    "rgba(0, 100, 0, 1)",
                    "rgba(128, 0, 0, 1)"
                  ]
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "displayFormats": {
                      "month": "MMM YYYY",
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    },
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "min": 80,
                  "max": 100,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Bubble chart for  Treatment Success Rates (%)"
        }
      ],
      "boardFormulas": [],
      "_id": "6759f3e4ad4e003d3d386c92",
      "boardType": "graph",
      "title": "Treatment Success Rates (%)",
      "boardView": "graph",
      "index": 6,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Patient Satisfaction Scores (1-10)",
                  "data": [
                    8.1,
                    8.3,
                    8.9,
                    8.2,
                    8.3,
                    8.6,
                    8.3,
                    8.5,
                    8.6,
                    8.1,
                    8.5,
                    8.7
                  ],
                  "backgroundColor": [
                    "rgba(75, 0, 130, 0.8)",
                    "rgba(139, 0, 0, 0.8)",
                    "rgba(0, 100, 0, 0.8)",
                    "rgba(0, 0, 139, 0.8)",
                    "rgba(85, 107, 47, 0.8)",
                    "rgba(139, 69, 19, 0.8)",
                    "rgba(0, 128, 128, 0.8)",
                    "rgba(128, 0, 128, 0.8)",
                    "rgba(255, 140, 0, 0.8)",
                    "rgba(47, 79, 79, 0.8)",
                    "rgba(72, 61, 139, 0.8)",
                    "rgba(34, 139, 34, 0.8)"
                  ],
                  "borderColor": [
                    "rgba(75, 0, 130, 1)",
                    "rgba(139, 0, 0, 1)",
                    "rgba(0, 100, 0, 1)",
                    "rgba(0, 0, 139, 1)",
                    "rgba(85, 107, 47, 1)",
                    "rgba(139, 69, 19, 1)",
                    "rgba(0, 128, 128, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(255, 140, 0, 1)",
                    "rgba(47, 79, 79, 1)",
                    "rgba(72, 61, 139, 1)",
                    "rgba(34, 139, 34, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "type": "linear",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Bar chart for Patient Satisfaction Scores (1-10)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Patient Satisfaction Scores (1-10)",
      "boardView": "graph",
      "index": 7,
      "_id": "675a88a2ad4e003d3d386cb4",
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>Treatment Success Rates (%) measure the effectiveness of medical interventions by quantifying the percentage of patients who achieve desired health outcomes following a specific treatment. This metric is essential for evaluating the quality of care, identifying areas for improvement, and ensuring patient satisfaction and safety.</p>\n<p>In this section, we present an analysis of treatment success rates, highlighting trends over time and comparisons across different treatment types, conditions, and demographics. These insights help us refine our medical practices and enhance patient outcomes, aligning with our commitment to providing exceptional healthcare services.</p>"
        }
      ],
      "boardFormulas": [],
      "index": 8,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Treatment Success Rates (%)",
      "_id": "675a892bad4e003d3d386cb6",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Net Profit Margin (%)",
                  "data": [
                    19.3,
                    19.9,
                    18.7,
                    16.3,
                    15.4,
                    15.4,
                    15.9,
                    18.3,
                    19.7,
                    16.1,
                    15.5,
                    16.8
                  ],
                  "borderColor": "rgba(34, 87, 122, 1)",
                  "backgroundColor": "rgba(34, 87, 122, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate line chart for Net Profit Margin (%)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Net Profit Margin (%)",
      "boardView": "graph",
      "index": 8,
      "_id": "675a8a7fad4e003d3d386cb7",
      "invited": []
    }
  ]

const HealthcareLayout = [
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 3,
      "i": "0",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 10,
      "h": 3,
      "x": 0,
      "y": 12,
      "i": "1",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 6,
      "h": 3,
      "x": 4,
      "y": 9,
      "i": "2",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 0,
      "i": "3",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 3,
      "x": 0,
      "y": 9,
      "i": "4",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 6,
      "i": "5",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "6",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 6,
      "i": "7",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "8",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    }
  ]

export { HealthcareBoardData, HealthcareLayout };