/* eslint-disable */

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const VIEW_DATA = 'VIEW_DATA';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_SCENARIO = 'SET_SCENARIO';
export const SET_SCENARIOID = 'SET_SCENARIOID';
export const SET_PROJECT = 'SET_PROJECT';
export const VIEW_REPORTS = 'VIEW_REPORTS';
export const SET_BILLING = 'SET_BILLING';
export const SET_PURCHASING = 'SET_PURCHASING';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_REVENUE = 'SET_REVENUE';
export const Add_REVENUE = 'Add_REVENUE';
export const GET_REVENUE = 'GET_REVENUE';
export const SET_INPUTS = 'SET_INPUTS';
export const Add_SALES = 'Add_SALES';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SWITCH_USER = 'SWITCH_USER';
export const SET_QUICKBOOK_AUTH = 'SET_QUICKBOOK_AUTH';
export const SET_QUICKBOOK_USER = 'SET_QUICKBOOK_USER';
export const SET_INTEGRATIONINFO = 'SET_INTEGRATIONINFO';
export const SET_INTEGRATIONDATA = 'SET_INTEGRATIONDATA';
export const SET_SELECTEDINTEGRATION = 'SET_SELECTEDINTEGRATION';


export const SET_TEAMS = 'SET_TEAMS';
export const SET_FULLDATA_TABLE = 'SET_FULLDATA_TABLE';
export const SET_FULLDATA = 'SET_FULLDATA';
export const SET_DATE = 'SET_DATE';

export const SET_ALLBOARDS = 'SET_ALLBOARDS';
export const SET_SELECTEDTAB='SET_SELECTEDTAB';
export const SET_SELECTEDBOARD = 'SET_SELECTEDBOARD';
export const SET_SELECTED_CLIENT='SET_SELECTED_CLIENT';

export const SET_CLIENTS_PURCHASED='SET_CLIENTS_PURCHASED';
export const SET_GPT_DATA='SET_GPT_DATA';
export const SET_PRESET_COMPONENT='SET_PRESET_COMPONENT';
export const SET_ALL_CHARTS='SET_ALL_CHARTS';
export const SET_DATES='SET_DATES';
export const SET_CHART_IDS='SET_CHART_IDS';
export const SET_UPLOADED_FILES='SET_UPLOADED_FILES';
export const DELETE_FILE='DELETE_FILE';
export const SET_FILE_CONTENT='SET_FILE_CONTENT';
