/* eslint-disable */

// This code is for v4 of the openai package: npmjs.com/package/openai
import OpenAI from "openai";
import { retrieveStoredEmbeddings, retrieveStoredData  } from "./helpers";


const openai = new OpenAI({
  apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
  dangerouslyAllowBrowser: true,
});

/**
 * Cleans an HTML string by removing leading indentation, blank lines, and ellipsis.
 *
 * @param {string} htmlContent - The HTML content to process.
 * @returns {string} - The cleaned HTML content.
 */
const cleanHtmlContent = (htmlContent) => {
  if (typeof htmlContent !== 'string') {
    console.error('Input must be a string.');
    return '';
  }

  const removeLeadingWhitespace = htmlContent.replace(/^[ \t]+/gm, '');
  const removeBlankLines = removeLeadingWhitespace.replace(/^\s*$/gm, '');
  const cleanedElipsis = removeBlankLines.replace(
    /```html\s*([\s\S]*?)```/g,
    "$1"
  );

  return cleanedElipsis;
};

// export const sendMessageToGpt = async (conversationHistory, userInput) => {
//   // Construct the messages array based on conversation history and user input
//   const messages = conversationHistory.map((message) => ({
//     role: message.role,
//     content: message.content,
//   }));

//   // Add the user's latest message
//   if (userInput !== "") {
//     messages.push(userInput);
//   }

//   console.log(messages)
//   const response = await openai.chat.completions.create({
//     model: "gpt-4o",
//     messages: messages,
//     temperature: 0,
//     top_p: 1,
//     frequency_penalty: 0,
//     presence_penalty: 0,
//   });

//   // Extract the assistant's response from the API response
   
//   const assistantResponse = cleanHtmlContent(response.data[0].embedding);

//   return assistantResponse;
// };

// export const sendMessageToGpt = async (conversationHistory, userInput, dataObjects) => {
//   try {
//     // Validate inputs
//     if (!Array.isArray(conversationHistory)) {
//       throw new Error("Invalid conversation history provided.");
//     }

//     // if (!Array.isArray(dataObjects)) {
//     //   throw new Error("Invalid dataObjects provided.");
//     // }

//     // Construct the messages array based on conversation history
//     const messages = conversationHistory.map((message) => ({
//       role: message.role,
//       content: message.content,
//     }));

//     // Add the user's latest message
//     if (userInput && userInput.trim() !== "") {
//       messages.push({ role: "user", content: userInput });
//     }

//     console.log("Constructed messages:", messages);

//     // Include embedding data as context
//     const embeddingContext = {
//       role: "system",
//       content: `You have access to the following embedded data for analysis. Use it to answer the user's query accurately and contextually. Embedded data: ${JSON.stringify(dataObjects, null, 2)}.`,
//     };
//     messages.unshift(embeddingContext);

//     // (Optional) Token count validation to prevent exceeding limits
//     // const tokenCount = calculateTokens(messages);
//     // if (tokenCount > MAX_INPUT_TOKENS) {
//     //   throw new Error("Input exceeds maximum token limit. Please refine your query.");
//     // }

//     // Send the request to OpenAI's API
//     const response = await openai.chat.completions.create({
//       model: "gpt-4o",
//       messages: messages,
//       temperature: 0,
//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0,
//     });

//     // Extract and validate the assistant's response
//     if (!response.choices || response.choices.length === 0) {
//       throw new Error("No response received from OpenAI.");
//     }

//     const assistantResponse = response.choices[0].message.content;
//     return assistantResponse;
//   } catch (error) {
//     console.error("Error in sendMessageToGpt:", error.message || error);
//     return "An error occurred while processing your request. Please try again.";
//   }
// };

const MAX_INPUT_TOKENS = 100000; // Adjust this based on model's context length
const MAX_OUTPUT_TOKENS = 10000; // Ensure output stays manageable


// Helper function to truncate messages
// function truncateMessages(messages, maxTokens) {
//   let tokenCount = 0;
//   const truncated = [];
//   for (let i = messages.length - 1; i >= 0; i--) {
//     const messageTokens = messages[i].content.length / 4;
//     if (tokenCount + messageTokens > maxTokens) break;
//     truncated.unshift(messages[i]);
//     tokenCount += messageTokens;
//   }
//   return truncated;
// }

// export const sendMessageToGpt = async (conversationHistory, userInput, dataObjects) => {
//   try {
//     // Validate inputs
//     if (!Array.isArray(conversationHistory)) {
//       throw new Error("Invalid conversation history provided.");
//     }

//     // Construct the messages array based on conversation history
//     let messages = conversationHistory.map((message) => ({
//       role: message.role,
//       content: message.content,
//     }));

//     // Add the user's latest message
//     if (userInput && userInput.trim() !== "") {
//       messages.push({ role: "user", content: userInput });
//     }

//     // Include embedding data as context
//     const embeddingContext = {
//       role: "system",
//       content: `You have access to the following data objects for analysis. Use it to answer the user's query accurately and contextually. Data object: ${JSON.stringify(dataObjects, null, 2)}.`,
//     };

//     // Always ensure embedding context is at the beginning
//     messages.unshift(embeddingContext);

//     // console.log("Messages before tokenization and truncation:", JSON.stringify(messages, null, 2));

//     // Calculate tokens and truncate if necessary
//     const totalInputTokens = calculateTokens(messages);
//     if (totalInputTokens > MAX_INPUT_TOKENS) {
//       console.warn("Input exceeds limit. Truncating messages.");
//       messages = truncateMessages(messages, MAX_INPUT_TOKENS);
//       console.log(messages);

//     }


//     // Re-verify that the embedding context exists after truncation
//     // if (!messages.some((msg) => msg.role === "system")) {
//     //   console.warn("Embedding context missing after truncation. Re-adding it.");
//     //   // messages.unshift(embeddingContext);

//     //   // Re-truncate if adding the embedding context causes token overflow
//     //   const recheckedTokens = calculateTokens(messages);
//     //   if (recheckedTokens > MAX_INPUT_TOKENS) {
//     //     console.warn("Re-truncating after re-adding embedding context.");
//     //     messages = truncateMessages(messages, MAX_INPUT_TOKENS);
//     //   }
//     // }

//     // console.log("Final messages sent to OpenAI:", JSON.stringify(messages, null, 2));

//     // Ensure the messages array is not empty
//     if (messages.length === 0) {
//       throw new Error("The messages array is empty after processing.");
//     }

//     // Send the request to OpenAI's API
//     const response = await openai.chat.completions.create({
//       model: "gpt-4o",
//       messages: messages,
//       max_tokens: MAX_OUTPUT_TOKENS,
//       temperature: 0,
//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0,
//     });

//     // Extract and validate the assistant's response
//     if (!response.choices || response.choices.length === 0) {
//       throw new Error("No response received from OpenAI.");
//     }

//     const assistantResponse = response.choices[0].message.content;
//     return assistantResponse;
//   } catch (error) {
//     console.error("Error in sendMessageToGpt:", error.message || error);
//     return "An error occurred while processing your request. Please try again.";
//   }
// };

// Helper function to truncate large data objects
const truncateDataObjects = (dataObjects, maxLength = 5000) => {
  const jsonString = JSON.stringify(dataObjects, null, 2);
  return jsonString.length > maxLength
    ? jsonString.slice(0, maxLength) + "... (truncated)"
    : jsonString;
};

// Helper function to estimate tokens
// const calculateTokens = (messages) =>
//   messages.reduce((acc, message) => acc + message.content.length / 4, 0); // Approx. 4 chars/token
function calculateTokens(messages) {
  messages.forEach((message, index) => {
    if (!message.content || typeof message.content !== "string") {
      console.error(`Invalid message at index ${index}:`, message);
    }
  });
  return messages.reduce((acc, message) => acc + (message.content.length / 4), 0);
}
  

// Helper function to chunk messages
const chunkMessages = (messages, maxTokens) => {
  const chunks = [];
  let currentChunk = [];
  let currentTokens = 0;

  for (let message of messages) {
    const messageTokens = calculateTokens([message]);

    // If adding this message exceeds the limit, save the current chunk and start a new one
    if (currentTokens + messageTokens > maxTokens) {
      chunks.push(currentChunk);
      currentChunk = [];
      currentTokens = 0;
    }

    // Add the message to the current chunk
    currentChunk.push(message);
    currentTokens += messageTokens;

    // Warn if a single message exceeds the max token limit
    if (messageTokens > maxTokens) {
      console.warn(
        `A single message exceeds the max token limit (${messageTokens} > ${maxTokens}). Consider splitting it.`
      );
    }
  }

  // Add the last chunk if not empty
  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
};

// Main Function
export const sendMessageToGpt = async (updatedConversationHistory, userInput, dataObjects) => {
  try {
    if (!Array.isArray(updatedConversationHistory)) {
      throw new Error("Invalid conversation history provided.");
    }

    // Truncate the data objects to prevent exceeding token limits
    const truncatedDataObjects = truncateDataObjects(dataObjects, 5000);

    // Construct the embedding context as the first message
    const embeddingContext = {
      role: "system",
      content: `You have access to the following data objects for analysis. Use it to answer the user's query accurately and contextually. Data object: ${truncatedDataObjects}`,
    };

    // Construct messages
    let messages = updatedConversationHistory.map((message) => ({
      role: message.role,
      content: message.content,
    }));

    if (userInput?.trim()) {
      messages.push({ role: "user", content: userInput });
    }

    // Include the embedding context as the first message
    messages.unshift(embeddingContext);

    console.log("Messages before chunking:", JSON.stringify(messages, null, 2));

    // Chunk messages if they exceed the token limit
    const chunks = chunkMessages(messages, MAX_INPUT_TOKENS);

    console.log("Number of chunks created:", chunks.length);

    // Process each chunk
    const responses = [];
    for (const chunk of chunks) {
      const response = await openai.chat.completions.create({
        model: "gpt-4o",
        messages: chunk,
        max_tokens: MAX_OUTPUT_TOKENS,
        temperature: 0,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      });

      if (!response.choices || response.choices.length === 0) {
        throw new Error("No response received from OpenAI.");
      }

      responses.push(cleanHtmlContent(response.choices[0].message.content));
    }

    return responses.join(" ");
  } catch (error) {
    console.error("Error in sendMessageToGpt:", error.message || error);
    return "An error occurred while processing your request. Please try again.";
  }
};





