/* eslint-disable */
import BoltIcon from "@mui/icons-material/Bolt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/context";
import { addNewBoard } from "../../context/intuit-service";
import { extractId } from "../ClientPerformance/helpers";
import "./DtGpt.css";
import { sendMessageToGpt } from "./Gpt";
import { formatMessageContent } from "./AiFormatMessage";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { CircularProgress } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import File from "./File";
// csv parser
import Papa from "papaparse";
import OpenAI from "openai";
import { queryEmbeddings } from "./queryEmbeddings";
import { retrieveStoredEmbeddings, retrieveStoredData  } from "./helpers";


// excel parser
// import xlsx from 'node-xlsx';
import * as XLSX from "xlsx";
import axios from "../../context/axios";
import { ArrowBack, BackHandSharp } from "@mui/icons-material";

function DtGpt({ data, onClose }) {
  const {
    state: {
      user,
      switchUser,
      isAuthenticated,
      access_token,
      purchasing,
      projects,
      selectedBoard,
      allBoards,
      selectedTab,
      gptData,
      data_change,
      uploadedFiles,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [assistantData, setAssistantData] = useState(null);

  useEffect(() => {
    // console.log("useEffect", data, assistantData, gptData)
    if (assistantData) {
      dispatch({
        type: "SET_GPT_DATA",
        payload: assistantData,
      });

    return;
  }
    console.log(data);
    dispatch({
      type: "SET_GPT_DATA",
      payload: data,
    });

  }, [data && data.length > 0, assistantData]);

  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  // const [embeddingData, setEmbeddingData] = useState(null);

  const [storedEmbeddings, setStoredEmbeddings] = useState([]);
  const [storedData, setStoredData] = useState([]);
  const [queryResults, setQueryResults] = useState([]);
  const [embeddingsDatabase, setEmbeddingDatabase] = useState([])

  const [response, setResponse] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [scrollContainer, setScrollContainer] = useState(null); // State to hold the chat content container
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [panelOpen, setPanelOpen] = useState(true);
  const [ai_loaded, setAI_Loaded] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchFiles, setFetchFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadingUserFiles, setUploadingUserFiles] = useState(false);

  const inputRef = useRef(null);

  // State to store embeddings and data
  const [embeddings, setEmbeddings] = useState([]);
  const [dataObjects, setDataObjects] = useState([]);
  const [error, setError] = useState(null); // Error state

  // Fetch embeddings dynamically
  const fetchEmbeddings = async () => {
    return embeddings;
  };

  // Fetch data objects dynamically
  const fetchDataObjects = async () => {
    return dataObjects;
  };

  // Effect to load data on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      // setEmbeddings(embeddings);
      setDataObjects(dataObjects);
  };

    loadData();
  }, []); // Empty dependency array ensures this runs once on mount


  useEffect(() => {
    if (loading) {
       
    } else {
       
    }
  }, [loading]);

  useEffect(() => {
     
  }, [messages]);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setStep(1);
      setSelectedFiles([]);
      setUploadedFileName(null);
    }
  }, [uploadedFiles]);

  // check to see if there are already uploaded files in the user's account
  useEffect(() => {
    const fetchFiles = async () => {
      try {
         
        const response = await axios.get("/retrieveFiles");
        const fetchedFiles = response.data.files || [];

        dispatch({
          type: "SET_UPLOADED_FILES",
          payload: fetchedFiles,
        });

         
      } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "Unknown error occurred.";
        console.error("Error uploading files: ", errorMessage);
        setUploadingUserFiles(false);
      }
    };

    fetchFiles();
  }, [dispatch]); // Empty dependency array ensures this runs once

  useEffect(() => {
     
  }, [conversationHistory]);

  useEffect(() => {
     
  }, [storedData]);


  const handleOptionSelect = async (option) => {
    setLoading(true);
    setStep(2);
    if (option === "P&L") {
      await connectQuickbooks(
        data,
        "Income Statement, Balance Sheet, CashFlow"
      );
    } else if (option === "Balance") {
      await connectQuickbooks(
        data,
        "Income Statement, Balance Sheet, CashFlow"
      );
    } else if (option === "CashFlow") {
      await connectQuickbooks(
        data,
        "Income Statement, Balance Sheet, CashFlow"
      );
    }
    setSelectedOption(option);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const copyToClipboard = async () => {
    if (messages.length === 0) {
      return;
    }

    const lastMessage = messages[messages.length - 1].content;

    if (lastMessage) {
      try {
        await navigator.clipboard.writeText(lastMessage);
      } catch (error) {
        console.error("Failed to copy:", error);
      }
    } else {
    }
  };

  function extractFirstHeaderAndCleanMessage(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const header = doc.querySelector("h1, h2, h3, h4, h5, h6");

    let headerText = "";
    if (header) {
      headerText = header.textContent.trim();
      const nextSibling = header.nextSibling;
      header.remove();

      let currentNode = nextSibling;
      while (
        currentNode &&
        currentNode.nodeType === Node.TEXT_NODE &&
        /^\s*$/.test(currentNode.textContent)
      ) {
        const nodeToRemove = currentNode;
        currentNode = currentNode.nextSibling;
        nodeToRemove.remove();
      }
    }

    const modifiedContent = doc.body.innerHTML.trim();
    return { header: headerText, modifiedContent };
  }


  const addToBoard = async () => {
    // Get the last message from the messages array
    const lastMessage = messages[messages.length - 1].content;
    const lastUserPrompt = messages[messages.length - 2].content;

    if (typeof lastMessage !== "string" || lastMessage.trim() === "") {
      console.error("Last message is empty or not a valid string.");
      return;
    }

    const { header: boardTitle, modifiedContent: cleanedLastMessage } =
      extractFirstHeaderAndCleanMessage(lastMessage);

    let boardInfo = {
      clientId: allBoards.boards[selectedBoard]._id,
      boardId: allBoards._id,
      tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id, // Include tabId in the payload
      data: {
        index: allBoards.boards[selectedBoard].tabs[selectedTab].boardData
          ? allBoards.boards[selectedBoard].tabs[selectedTab].boardData.length
          : 0,
        boardView: "Text",
        boardType: "Text",
        title: boardTitle === "" ? lastUserPrompt : boardTitle,
        boardData: {
          text: cleanedLastMessage,
        },
      },
    };

    if (allBoards.boards[selectedBoard].access) {
      boardInfo.boardId = extractId(allBoards.boards[selectedBoard].access);
    }
    let addNewBoardResponse = await addNewBoard(boardInfo);

    dispatch({
      type: "SET_ALLBOARDS",
      payload: addNewBoardResponse.data.data,
    });
  };

  const addFile = async (files) => {
    try {
      setUploadingUserFiles(true);
      const uploadedFilesArray = [...uploadedFiles]; // Clone existing uploadedFiles

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Read file content
        const reader = new FileReader();
        const fileContent = await new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsText(file);
        });

        const formData = new FormData();
        formData.append("file", file);
        formData.append("email", user.email);

        const response = await axios.post("/addFile", formData);
        const uploadedFile = response.data.file;

        if (uploadedFile) {
          uploadedFile.content = fileContent;

          if (!uploadedFile._id) {
            console.error("Error: file _id not found:", uploadedFile);
          } else {
             
          }

          uploadedFilesArray.push(uploadedFile);
        } else {
           
        }
      }

      // 
      // Update the state with all uploaded files at once
      dispatch({
        type: "SET_UPLOADED_FILES",
        payload: uploadedFilesArray,
      });

      setUploadingUserFiles(false);
       
    } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "Unknown error occurred.";
        console.error("Error uploading files: ", errorMessage);
      setUploadingUserFiles(false);
    }
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      // Filter out files that are not .csv
      const csvFiles = Array.from(files).filter((file) =>
        file.name.endsWith(".csv")
      );
      if (csvFiles.length == 1) {
        addFile(csvFiles);
        setUploadedFileName(`${csvFiles[0].name}`);
      } else if (csvFiles.length > 1) {
        addFile(csvFiles);
        setUploadedFileName(`${csvFiles.length} file(s) uploaded`);
      } else {
        alert("Please upload .csv files only.");
      }
    }
  };

  const connectQuickbooks = async (data, dataType) => {
    // function delay(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // }
    // await delay(10000);
    let jsonData = JSON.stringify(data);
    // 
    // dispatch({
    //   type: "SET_GPT_DATA",
    //   payload: JSON.parse(jsonData),
    // });
    // If there is not data in this message after "->", respond with "please resync your integrations".
    const prompt =
      `Here is a stringified json dataset - read and remember that data. You are an assistant to help automate CPA's tasks. 
      Take a deep breath, and extract all the financial numbers to become an expert on my data. 
      Also, do not give me any explanations or formulas, simply give me the information I ask for without speaking.
      Return responses in html format. Use bullet points, sections, and paragraphs compared to tabular format. Do not apologize, do not preface, be concise. Simply give me the information exactly how I ask for. 
      If there is no data after "->" tell the user this. Please reference this data for every query.
      This data is about ${dataType} from intuit quickbooks ->` + jsonData;
     

    const loading2 = {
      content: "Setting up your AI Assistant...",
      role: "assistant",
    };
    setMessages([loading2]);

    const systemMessage = { content: prompt, role: "system", id: Date.now() };
    // make it so gpt api talks first
    // const talkFirst = { content: "Hello, I'm your AI Assistant! Ask me anything about your data!", role: "assistant" };
    // const loadingMessage = { content: 'Typing...', role: 'assistant', id: Date.now() };

    try {
      // Add user's message to the conversation history
      // const conversationHistory = [
      //   { role: "user", content: "What insights can I derive from the embedded data?" }
      // ];


      const updatedConversationHistory = [
        ...conversationHistory,
        systemMessage,
        {
          content:
            "Hello I am your AI Assistant! Ask me anything about your data!",
          role: "assistant",
        },
      ];


      
      // const result = await sendMessageToGpt(conversationHistory, userInput, dataObjects);
      // console.log("AI Response:", result);
      

      // Pass the entire conversation history as the first argument
      // first message needs to be a system prompt, because it's just the quickbooks data

      // wait for gpt to load the data into system before allowing user to prompt
      const aiResponse = await sendMessageToGpt(updatedConversationHistory,"");
      console.log("Updated conversation history:", updatedConversationHistory);



    //   const aiResponse = await sendMessageToGpt(
    //     updatedConversationHistory,
    //     // userMessage.content, // Pass only the content, not the entire user message object
    //     { 
    //         // embeddings,         // Use current state for embeddings
    //         dataObjects,        // Use current state for data objects
    //         queryResults        // Results from querying embeddings
    //     }
    // );

       
      setAI_Loaded(true);
      // console.log(aiResponse)

      setMessages([
        ...messages,
        {
          content:
            "Hello, I am your AI Assistant! Ask me anything about your data!",
          role: "assistant",
        },
      ]);

      //  

      // Update state with the new conversation history and messages
      setConversationHistory(updatedConversationHistory);
      // setMessages(updatedMessages);
      //  
    } catch (error) {
      console.error("Error handling AI response:", error);
    }
  };

  useEffect(() => {
    resyncIntegrations();
  }, [data_change]);

  const resyncIntegrations = () => {
    setModalOpen(false);
    setUserInput("");
    setSelectedOption(null);
    setStep(1);
    setMessages([]);
    setUploadedFileName(null);
    setConversationHistory([]);
  };

  const handleShowFiles = () => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setStep(1.5);
      setShowFiles(true);
    } else {
      console.error("No files available to show.");
    }
  };

  const skipToStepOnePointFive = () => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setStep(1.5);
      setShowFiles(true);
    } else {
      console.error("No files available to skip to.");
      // Optionally notify the user
    }
  };

  // const handleSendMessage = async () => {

  //   if (userInput.trim() === "") return;

  //   const userMessage = { content: userInput, role: "user", id: Date.now() };
  //   const loadingMessage = {
  //     content: "Typing...",
  //     role: "assistant",
  //     id: Date.now(),
  //   };

  //   // Add the loading message to the messages array
  //   setMessages([...messages, userMessage, loadingMessage]);

  //   try {
  //     // Pass the entire conversation history as the first argument
  //     setUserInput("");
       
  //     const aiResponse = await sendMessageToGpt(
  //       conversationHistory,
  //       userMessage,
  //       fetchEmbeddingData()
  //     );

  //     // Add user's message to the conversation history
  //     const updatedConversationHistory = [
  //       ...conversationHistory,
  //       userMessage,
  //       { content: aiResponse, role: "assistant" },
  //     ];

  //     // Add AI's response to the conversation history
  //     const updatedMessages = [
  //       ...messages,
  //       userMessage,
  //       { content: aiResponse, role: "assistant" },
  //     ];

  //     // Update state with the new conversation history and messages
  //     setConversationHistory(updatedConversationHistory);
  //     setMessages(updatedMessages);
       
  //   } catch (error) {
  //     console.error("Error handling AI response:", error);
  //     setUserInput("");
  //   }

  //   setUserInput("");
  // };

  const handleSendMessage = async () => {
    if (userInput.trim() === "") return;

    const userMessage = { content: userInput, role: "user", id: Date.now() };
    const loadingMessage = {
        content: "Typing...",
        role: "assistant",
        id: Date.now(),
    };

    // Add the loading message to the messages array
    setMessages([...messages, userMessage, loadingMessage]);

    try {
        setUserInput("");

        // Query the embeddings and data objects stored in state
        const queryResults = await handleQuery(userInput); // Use embeddings and dataObjects internally

        // Pass the entire conversation history, user input, and query results
        const aiResponse = await sendMessageToGpt(
            conversationHistory,
            userMessage.content, // Pass only the content, not the entire user message object
            { 
                // embeddings,         // Use current state for embeddings
                dataObjects,        // Use current state for data objects
                queryResults        // Results from querying embeddings
            }
        );

        // Update the conversation history with the new user message and AI response
        const updatedConversationHistory = [
            ...conversationHistory,
            userMessage,
            { content: aiResponse, role: "assistant" },
        ];

        // Add AI's response to the messages array, replacing the "Typing..." placeholder
        const updatedMessages = [
            ...messages.slice(0, -1), // Remove the "Typing..." placeholder
            userMessage,
            { content: aiResponse, role: "assistant" },
        ];

        // Update state
        setConversationHistory(updatedConversationHistory);
        setMessages(updatedMessages);

    } catch (error) {
        console.error("Error handling AI response:", error);
        setMessages([
            ...messages.slice(0, -1), // Remove the "Typing..." placeholder
            { content: "An error occurred. Please try again.", role: "assistant" },
        ]);
    } finally {
        setUserInput("");
    }
};
    

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setUserInput("");
    setSelectedOption(null);
    setStep(1);
    setMessages([]);
    setUploadedFileName(null);
    setConversationHistory([]);
  };

  const handleBackClick = () => {
    setStep(1);
    setShowFiles(false);
    setUploadedFileName(null);
    setSelectedFiles([]);
  };

  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const handleKeyDown = (e) => {
        if (e.key === "Enter" && userInput.trim() !== "") {
          handleSendMessage();
        }
      };

      inputElement.addEventListener("keydown", handleKeyDown);

      return () => {
        inputElement.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [userInput]);

  useEffect(() => {
    // Scroll to the bottom of the chat content when new messages are added
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [messages]);

  const openPanel = () => {
    // Add logic to open the panel
    setPanelOpen(true);
    setStep(2);
  };

  const closePanel = () => {
    // Add logic to close the panel
    if (onClose){
      onClose()
    }
    // setSelectedFiles([]);
    // setUploadedFileName(null);
    setPanelOpen(false);
    setStep(1)
  };

  const setStepBackToStepOnePointFive = () => {
    setStep(1.5);
    setShowFiles(true);
    setPanelOpen(true);
  }

  const setStepBackToStepOne = () => {
    setPanelOpen(true);
    setStep(1);
  }

  useEffect(() => {
    if (ai_loaded && inputRef.current) {
      const input = inputRef.current;
      input.focus();
    }
  }, [ai_loaded]);

  function parseCSVAsync(rawCSV, config = {}) {
    return new Promise((resolve, reject) => {
      Papa.parse(rawCSV, {
        ...config,
        complete: (results) => {
          // Check if rows are objects (header: true) or arrays (header: false)
          const isHeader = config.header || false;
          let filteredData;

          if (isHeader) {
            // For object rows, filter out rows where all values are empty
            filteredData = results.data.filter((row) =>
              Object.values(row).some((cell) => cell !== "")
            );
          } else {
            // For array rows, filter out rows where all cells are empty
            filteredData = results.data.filter((row) =>
              row.some((cell) => cell !== "")
            );
          }

          resolve({ ...results, data: filteredData });
        },
        error: (error) => reject(error),
      });
    });
  }

  const fetchEmbeddingData = async () => {
    try {
      // Retrieve stored embeddings and associated data (e.g., from your previous logic)
      const embeddings = await retrieveStoredEmbeddings(); // Implement this function
      const data = await retrieveStoredData(); // Implement this function

      setStoredEmbeddings(embeddings);
      setStoredData(data);
    } catch (error) {
      console.error("Error fetching embedding data:", error);
    }
  };

  const handleQuery = async (userInput) => {
    try {
        if (!embeddings || !dataObjects) {
            console.error("Embeddings or data objects are not loaded.");
            return;
        }

        const results = await queryEmbeddings(userInput, embeddings, dataObjects);
        setQueryResults(results);
    } catch (error) {
        console.error("Error handling query:", error);
    }
};
  const MAX_TOKENS_PER_CHUNK = 8000; // Slightly below the max context length to leave room for overhead

  function splitIntoChunks(text, maxTokens) {
    const chunks = [];
    let currentChunk = "";

    const lines = text.split("\n");
    for (const line of lines) {
      const estimatedTokens = line.length / 4; // Approximate 4 characters per token
      if (currentChunk.length + estimatedTokens > maxTokens) {
        chunks.push(currentChunk);
        currentChunk = ""; // Reset current chunk
      }
      currentChunk += line + "\n";
    }

    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    return chunks;
  }

  // process the selected files and send them to the AI assistant
  // const selectClicked = async () => {
  //   setLoading(true);

  //   const openai = new OpenAI({
  //     apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
  //     dangerouslyAllowBrowser: true,
  //   });
    
  
  //   if (uploadedFiles && selectedFiles.length > 0) {
  //     const filesToProcess = uploadedFiles.filter((file) =>
  //       selectedFiles.includes(file._id)
  //     );
  
  //     let combinedData = [];
  
  //     try {
  //       for (const file of filesToProcess) {
  //         if (!file || !file.fileName || !file.content) {
  //           console.error("Incomplete file data:", file);
  //           continue; // Skip incomplete files
  //         }
  
  //         if (file.fileName.toLowerCase().endsWith(".csv")) {
  //           const rawCSV = file.content;
  
  //           if (typeof rawCSV !== "string") {
  //             throw new TypeError("CSV content is not a string.");
  //           }
  
  //           const parsedCSV = await parseCSVAsync(rawCSV, {
  //             header: true,
  //             skipEmptyLines: true,
  //           });
  
  //           combinedData.push(...parsedCSV.data);
  //         } else {
  //           alert("Unsupported file type. Please upload a .csv file.");
  //           setLoading(false);
  //           return;
  //         }
  //       }
  
  //       if (combinedData.length === 0) {
  //         alert("No valid data was found in the selected files.");
  //         setLoading(false);
  //         return;
  //       }
  
  //       // Convert combinedData to CSV format as a string
  //       const csvContent = Papa.unparse(combinedData);
  
  //       console.log("CSV content generated successfully.");
  
  //       // Split CSV content into manageable chunks
  //       const csvChunks = splitIntoChunks(csvContent, MAX_TOKENS_PER_CHUNK);
  
  //       console.log(`Split CSV content into ${csvChunks.length} chunks.`);

  //       const embeddings = [];
  
  //       // Process each chunk and create embeddings
  //       for (const chunk of csvChunks) {
  //         const embedding = await openai.embeddings.create({
  //           model: "text-embedding-3-small",
  //           input: chunk,
  //           encoding_format: "float",
  //         });
  //         console.log("Embedding created for a chunk:", embedding);
  //         embeddings.push(embedding);
  //         setEmbeddingData(embedding);
  //         await connectQuickbooks(embedding);
  //       }

  
  //       setStep(2);
  //     } catch (error) {
  //       console.error("Error processing files:", error);
  //       alert("There was an error processing the files. Please try again.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     console.error("No files selected.");
  //     alert("Please select at least one file to proceed.");
  //     setLoading(false);
  //   }
  // };  
  

// let embeddingsDatabase = [];  // Store embeddings for querying

const selectClicked = async () => {
  setLoading(true);

  const openai = new OpenAI({
    apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
    dangerouslyAllowBrowser: true,
  });

  if (uploadedFiles && selectedFiles.length > 0) {
      const filesToProcess = uploadedFiles.filter((file) =>
          selectedFiles.includes(file._id)
      );

      let combinedData = [];
      const localEmbeddings = [];
      const localDataObjects = [];

      try {
          // Process each selected file
          for (const file of filesToProcess) {
              if (!file || !file.fileName || !file.content) {
                  console.error("Incomplete file data:", file);
                  continue; // Skip incomplete files
              }

              if (file.fileName.toLowerCase().endsWith(".csv")) {
                  const rawCSV = file.content;

                  if (typeof rawCSV !== "string") {
                      throw new TypeError("CSV content is not a string.");
                  }

                  const parsedCSV = await parseCSVAsync(rawCSV, {
                      header: true,
                      skipEmptyLines: true,
                  });

                  combinedData.push(...parsedCSV.data);
              } else {
                  alert("Unsupported file type. Please upload a .csv file.");
                  setLoading(false);
                  return;
              }
          }

          if (combinedData.length === 0) {
              alert("No valid data was found in the selected files.");
              setLoading(false);
              return;
          }

          // Convert combinedData to CSV format as a string
          const csvContent = Papa.unparse(combinedData);

          console.log("CSV content generated successfully.");

          // Split CSV content into manageable chunks
          const csvChunks = splitIntoChunks(csvContent, MAX_TOKENS_PER_CHUNK);

          console.log(`Split CSV content into ${csvChunks.length} chunks.`);

          // Process each chunk and create embeddings and data objects
          for (const chunk of csvChunks) {
              const embedding = await openai.embeddings.create({
                  model: "text-embedding-3-small",
                  input: chunk,
                  encoding_format: "float",
              });

              console.log("Embedding created for a chunk:", embedding);

              // Store embedding
              localEmbeddings.push(embedding);

              // Create corresponding data object
              const dataObject = {
                  title: `Chunk ${embeddings.length}`,
                  content: chunk,
                  embedding,
              };

              localDataObjects.push(dataObject);
              setAssistantData(dataObject);
              await connectQuickbooks(dataObject);

          }

          // Update state with generated embeddings and data objects
          setEmbeddings(localEmbeddings);
          setDataObjects(localDataObjects);

          console.log("Embeddings and data objects created successfully.");

          setStep(2); // Proceed to the next step
      } catch (error) {
          console.error("Error processing files:", error);
          alert("There was an error processing the files. Please try again.");
      } finally {
          setLoading(false);
      }
  } else {
      console.error("No files selected.");
      alert("Please select at least one file to proceed.");
      setLoading(false);
  }
};

  
  const handleClickedFile = (id, isDelete = false) => {
    if (isDelete) {
      // Remove the file from selectedFiles if it's being deleted
       
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((fileId) => fileId !== id)
      );
      dispatch({
        type: "SET_UPLOADED_FILES",
        payload: uploadedFiles.filter((file) => file._id !== id),
      });
    } else {
       
      if (selectedFiles.includes(id)) {
        setSelectedFiles(selectedFiles.filter((fileId) => fileId !== id));
      } else {
        setSelectedFiles([...selectedFiles, id]);
      }
    }
  };

  return (
    <>
      <div className="App">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            right: 0, // Position the panel to the right
            top: 0,
            bottom: 0,
            zIndex: 9999,
            width: "40%", // Adjust the width as needed
            height: "100%",
            maxHeight: "100%",
            overflow: "hidden",
            overflowY: "auto",
            backgroundColor: "#ffffff", // Add your desired background color
            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow if needed
            transition: "transform 0.3s ease-in-out", // Add transition for smooth open/close
            transform: panelOpen ? "translateX(0)" : "translateX(100%)", // Slide in from the right
          }}
          role="sidebar"
        >
          {step === 1 && (
            <>
              <div
                className="row mb-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  width: "100%",
                  maxHeight: "50px",
                }}
              >
                <div>
                  <DialogTitle style={{ textAlign: "left" }}>
                    Select or Upload a data set
                  </DialogTitle>
                </div>
                <div className="mt-3">
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closePanel}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </div>
              </div>
              <DialogContent
                className="chat-container"
                style={{ height: "85%" }}
              >
                <div className="ui buttons w-100">
                  <button
                    className={`ui button secondary m-2 ${
                      selectedOption === "P&L" ? "active" : ""
                    }`}
                    onClick={() => handleOptionSelect("P&L")}
                  >
                    Use Data from Quickbooks
                  </button>
                  {/* <button
                    className={`ui button secondary m-2 ${selectedOption === 'Balance' ? 'active' : ''}`}
                    onClick={() => handleOptionSelect('Balance')}
                  >
                    Balance
                  </button>
                  <button
                    className={`ui button secondary m-2 ${selectedOption === 'Cash Flow' ? 'active' : ''}`}
                    onClick={() => handleOptionSelect('Cash Flow')}
                  >
                    Cash Flow
                  </button> */}
                </div>
                {/* Drag and Drop Upload Container */}
                <div
                  className="ui raised segment upload-container"
                  style={{
                    height: "600px",
                    border: "2px dashed black", // Black dashed line border
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const files = e.dataTransfer.files;
                    handleFileUpload({ target: { files: files } });
                  }}
                >
                  <p>Drag and drop a .csv files here or click to upload</p>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept=".csv"
                    multiple
                  />
                  {uploadedFileName && (
                    <p>Uploaded Files: {uploadedFileName}</p>
                  )}
                  <button
                    className="ui button m-1"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    {uploadedFileName ? "Re-upload" : "Upload"}
                  </button>
                  {!uploadedFileName &&
                    uploadedFiles &&
                    uploadedFiles.length > 0 && (
                      <button
                        className="ui button m-1"
                        onClick={skipToStepOnePointFive}
                      >
                        Use existing files
                      </button>
                    )}
                  {uploadedFileName && (
                    <button
                      className="mt-2 ui button m-1"
                      onClick={handleShowFiles}
                      disabled={uploadingUserFiles}
                      style={{
                        cursor: uploadingUserFiles ? "not-allowed" : "pointer",
                        opacity: uploadingUserFiles ? 0.6 : 1, // Optional for better UX
                      }}
                    >
                      {uploadingUserFiles ? "Uploading..." : "Next"}
                    </button>
                  )}
                </div>
              </DialogContent>
            </>
          )}
          {step === 1.5 && showFiles && (
            <>
              <div
                style={{
                  height: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="row mb-3"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    width: "100%",
                    maxHeight: "50px",
                  }}
                >
                  <div>
                    <DialogTitle
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      Select file(s)
                    </DialogTitle>
                  </div>
                  <div className="mt-3 ml-3">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={setStepBackToStepOne}
                    >
                      <ArrowBack fontSize="medium" />
                    </IconButton>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={closePanel}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </div>
                </div>
                <DialogContent style={{ height: "89%" }}>
                  <div>
                    <Grid item xs={12} md={12}>
                      <div>
                        <List dense={true}>
                          {uploadedFiles &&
                            uploadedFiles.map((file) => {
                              return (
                                <File
                                  key={file._id}
                                  id={file._id}
                                  file={file}
                                  handleClickedFile={handleClickedFile}
                                  selectedFiles={selectedFiles}
                                />
                              );
                            })}
                        </List>
                      </div>
                    </Grid>
                  </div>
                </DialogContent>
                <div
                  className="ui buttons w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`ui button secondary m-2`}
                    style={{
                      display: "block",
                      width: "90%",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      if (selectedFiles !== null) {
                        selectClicked();
                      } else {
                        alert("Please select a file to proceed.");
                      }
                    }}
                  >
                    Select ({selectedFiles.length} file
                    {selectedFiles.length !== 1 ? "s" : ""})
                  </button>
                  <button
                    onClick={handleBackClick}
                    className="ui button m-2"
                    style={{ width: "90%", borderRadius: "5px" }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div
                className="row mb-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  width: "100%",
                  maxHeight: "50px",
                }}
              >
                <div>
                  <DialogTitle
                    style={{
                      textAlign: "left",
                      color: "#00396d",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <BoltIcon fontSize="large" />
                    {"    "} Warren
                  </DialogTitle>
                </div>
                <div className="mt-3" style={{}}>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={setStepBackToStepOnePointFive}
                  >
                    <ArrowBack fontSize="medium" />
                  </IconButton>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closePanel}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </div>
              </div>
              {loading ? (
                <>
                  <DialogContent
                    className="chat-container pb-2"
                    style={{
                      height: "85%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={32} color="inherit" />
                  </DialogContent>
                </>
              ) : (
                <>
                  <DialogContent
                    className="chat pb-2"
                    style={{ height: "85%" }}
                  >
                    <div
                      className="chat-content"
                      style={{ width: "100%", marginBottom: "50px" }}
                      ref={(element) => setScrollContainer(element)} // Set the ref to the chat content container
                    >
                      <div className="chat-container">
                        {messages.map((message, index) => (
                          <div
                            key={index}
                            className={`message ${
                              message.role === "user" ? "user-message" : "ai-message"
                            }`}
                          >
                            <div 
                              className={`message-bubble ${
                                message.role === "user" ? "user-bubble" : "ai-bubble"
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: formatMessageContent(message.content),
                              }} 
                            ></div>
                          </div>
                        ))}
                      </div>
                      {messages.length >= 2 && (
                        <div
                          className="ui menu pb-3"
                          style={{
                            position: "absolute",
                            bottom: "55px", // Adjust the distance from the bottom
                            left: "50%", // Center horizontally
                            transform: "translateX(-50%)", // Center using transform
                            display: "flex", // Enable flex behavior
                            justifyContent: "center", // Center horizontally within the container
                            alignItems: "center", // Center vertically within the container
                            width: "100%", // Take up the full width of the container
                          }}
                        >
                          <button
                            className="ui button"
                            style={{ width: "40%" }}
                            onClick={copyToClipboard}
                          >
                            Copy to Clipboard
                          </button>
                          <button
                            className="ui button"
                            style={{ width: "40%" }}
                            onClick={addToBoard}
                          >
                            Add to Client's Board
                          </button>
                        </div>
                      )}
                    </div>
                  </DialogContent>
                  <div className="chat-input" style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Ask AI a question about your financials..."
                      disabled={ai_loaded ? false : true}
                      value={userInput}
                      onChange={handleInputChange}
                      ref={inputRef} // Add the ref to the input field
                      style={{
                        flex: "1", // Allow the input to grow and take up available space
                        padding: "10px", // Adjust padding as needed
                        border: "none",
                        borderBottom: "1px solid #ddd",
                        borderRadius: "0",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    />
                    <button
                      className="ui button"
                      onClick={handleSendMessage}
                      style={{
                        padding: "0.5em", // Adjust the padding as needed
                        background: "none",
                        border: "none",
                        color: "#2185d0", // Match the chat box color
                        width: "40px",
                      }}
                    >
                      <i className="paper plane outline icon"></i>{" "}
                      {/* Semantic UI send arrow icon */}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </Box>
      </div>
    </>
  );
}

export default DtGpt;
