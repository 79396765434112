/* eslint-disable */
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-plugin-zoom';
import numeral from 'numeral';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { AuthContext } from '../../../context/context';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = (props) => {
  const {
    state: {
      user,
    },
    dispatch,
  } = React.useContext(AuthContext);

  return (
    <>
      <div className="container-fluid" style={{ padding: "0px 0px 0px 0px" }}>
        <div className="col-xs-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">{props.name}</h4>
              <br />
              <h3>{props.amount}</h3>
            </div>
            <div className="card-body">
              {props.chartLoader ? (
                <div className="loader-wrapper">
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Line
                  data={props.data}
                  width={1000}
                  height={400}
                  options={{
                    zoom: {
                      enabled: true,
                      mode: 'x',
                    },
                    pan: {
                      enabled: true,
                      mode: 'x',
                    },
                    tooltips: {
                      callbacks: {
                        title(tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                        label(tooltipItem, data) {
                          let value;
                          data.datasets.forEach((d) => {
                            if (d.data[tooltipItem.index] === Number(tooltipItem.value)) {
                              value = `${user.currency || '$'} ${parseInt(d.data[tooltipItem.index].toFixed(2)).toLocaleString(2)}`;
                            }
                          });
                          return value;
                        },
                        afterLabel(tooltipItem, data) { },
                      },
                      backgroundColor: '#FFF',
                      borderWidth: 2,
                      xPadding: 15,
                      yPadding: 15,
                      borderColor: '#ddd',
                      titleFontSize: 16,
                      titleFontColor: '#0066ff',
                      bodyFontColor: '#000',
                      bodyFontSize: 14,
                      displayColors: false,
                    },
                    cornerRadius: 20,
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            callback(value) {
                              return `${user.currency || '$'} ${numeral(value).format('0.0a')}`;
                            },
                            stepSize: 200,
                            beginAtZero: true,
                          },
                          gridLines: {
                            borderDash: [2],
                            zeroLineWidth: 3,
                            tickMarkLength: 10,
                            lineWidth: 3,

                          },
                        },
                      ],
                      xAxes: [
                        {
                          // barThickness: 12,
                          barPercentage: 0.3,
                          borderWidth: 88,
                          gridLines: {
                            lineWidth: 0,
                            zeroLineColor: 'transparent',
                          },
                        },
                      ],
                    },
                    plugins: {
                      legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                          usePointStyle: true,
                          boxWidth: 10,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarChart;
