/* eslint-disable */
import * as React from 'react';
import { AuthContext } from '../../../context/context';
import { convertDate } from './InputUtils';
import RevenueEntry from './RevenueEntry';

export default function RevnueInputs(props) {
  const {
    state: {
      revenues,
    },
  } = React.useContext(AuthContext);


  return (
    <div>
      {revenues
        && (
          <>
            {revenues.revenuInputs.map((value) => (
              <div>
                {(value.model === props.model || props.model === undefined) ?

                  <div style={{
                    borderRadius: '10px',
                    backgroundColor: 'rgba(196,196,196,0.1)',
                    padding: '10px 10px 10px 10px',
                    marginTop: '10px',
                  }}
                  >
                    <div className="row">
                      <div className="col-sm-9 pt-1" style={{ color: '#55616E', fontSize: '12px' }}>
                        {convertDate(value.startDate)}

                      </div>
                      <div className="col-sm-3" style={{ textAlign: 'right' }}>
                        <RevenueEntry type="Edit" value={value} id={revenues._id} />
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-sm-9" style={{ fontSize: '14px' }}>
                        {value.name}
                      </div>
                      <div className="col-sm-3" style={{ textAlign: 'center' }}>
                        $
                        {value.price}
                      </div>
                    </div>
                  </div>
                  :

                  <></>

                }
              </div>
            ))}
          </>
        )}
    </div>
  );
}
