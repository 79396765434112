/* eslint-disable */
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import { getInputs, getRevenue } from '../../context/fetch-service';
import ProjectsButton from '../Projects/ProjectsButton';
import Hiring from './Hiring';
import Revenue from './Revenue';

const PREFIX = 'WorkstationPage';

const classes = {
  link: `${PREFIX}-link`,
  btn: `${PREFIX}-btn`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({

  [`& .${classes.link}`]: {
    color: 'black',
  },

  [`& .${classes.btn}`]: {
    width: '15%',
    backgroundColor: '#5abcdc',
    borderRadius: '1rem',
    fontWeight: 'bold',
    fontFamily: 'cerebri sans',
    fontSize: '1rem',
    padding: '.8rem 1rem',
  },
});

const Styles = makeStyles()({

  [`& .${classes.link}`]: {
    color: 'black',
  },

  [`& .${classes.btn}`]: {
    width: '15%',
    backgroundColor: '#5abcdc',
    borderRadius: '1rem',
    fontWeight: 'bold',
    fontFamily: 'cerebri sans',
    fontSize: '1rem',
    padding: '.8rem 1rem',
  },
});
const WorkstationPage = () => {
  const {
    state: {
      user, switchUser, purchasing, isAuthenticated, currentScenario, projects
    }, dispatch
  } = React.useContext(AuthContext);
  const history = useNavigate();
  const userSub = purchasing;

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const revenues = await getRevenue(switchUser.userName, currentScenario);
      dispatch({
        type: 'SET_REVENUE',
        payload: revenues,
      });
    }
    fetchRevenue();
    async function fetchInputs() {
      const inputs = await getInputs(switchUser.userName, currentScenario);

      dispatch({
        type: 'SET_INPUTS',
        payload: inputs,
      });
    }
    fetchInputs();
  }, [isAuthenticated, history, dispatch]);


  let message = '';
  let expired = false;
  let date = new Date(userSub[0].trialStartDate);
  date.setDate(date.getDate() + 7);
  let expiryDate = Math.round((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
  if (userSub[0].trialStatus) {
    if (expiryDate > 0) {
      message = `Your Trial Ends in ${expiryDate} Days!`;
    } else {
      message = "We think you'll like it here! Choose a free trial to get started";
      expired = true;
    }
  }

  function TabPanel(props) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    (<Root>
      {projects.length <= 0 ?
        <div classNamer="row" style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: "300px"
        }}>
          <ProjectsButton />

        </div>
        :
        <div>
          {user.email !== switchUser.userName && switchUser.userName
            ? (
              <div>
                <Alert severity="success">{'Currently on: ' + switchUser.userName}</Alert>
              </div>
            )
            : <></>}
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="row">
              <div className="col-sm-3">
                <h1 style={{ paddingRight: '20px', color: '#55616E' }}> WorkStation&nbsp;&nbsp;&nbsp;
                  <a className="ui blue label ml-3">Alpha</a>
                  {/* <a className="ui label ml-3" style={{backgroundColor: "#220FFE", color: "white"}}>Alpha</a> */}

                </h1>

              </div>
              {/* <div className="col-sm-3">

            <ProjectsButton />
            </div> */}
            </div>
            <Box className="pt-3" sx={{ width: '100%', minWidth: "1300px" }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  textColor="black"
                  indicatorColor="primary"
                  value={value}
                  onChange={handleChange}
                  aria-label="Workstation Tabs"
                >
                  <Tab style={{ textTransform: 'none', fontWeight: 'bold', fontSize: '20px' }} label="Financial Plan" {...a11yProps(0)} />
                  <Tab style={{ textTransform: 'none', fontWeight: 'bold', fontSize: '20px' }} label="Hiring Plan" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Revenue />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Hiring />
              </TabPanel>
            </Box>
          </div>
        </div>
      }
    </Root>)
  );
};

export default WorkstationPage;
