/* eslint-disable */
import React, { useEffect } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import { convertToKMB } from "../Helpers/DataHelper";

export default function ReportsTableCompare(props) {
    const columns = props.headers;
    const rows = props.data
    const [colDeltaYearly, setColDeltaYearly] = React.useState(true);
    const [colDeltaCategory, setColDeltaCategory] = React.useState(true);

    useEffect(() => {
         
         
         
        console.log(colDeltaCategory)
    }, [columns, rows, colDeltaYearly, colDeltaCategory]);

    return (
        <div>
            {/* <div class="ui buttons pb-3">
                <div class="ui button" style={{ color: (!colDeltaYearly && colDeltaCategory) ? "white" : "black", backgroundColor: (!colDeltaYearly && colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("Category")}>Category &#916;</div>
                <div class="ui button" style={{ color: (colDeltaYearly && !colDeltaCategory) ? "white" : "black", backgroundColor: (colDeltaYearly && !colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("Yearly")}>Yearly &#916;</div>
                <div class="ui button" style={{ color: (colDeltaYearly && colDeltaCategory) ? "white" : "black", backgroundColor: (colDeltaYearly && colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("Both")}>Both &#916;</div>
                <div class="ui button" style={{ color: (!colDeltaYearly && !colDeltaCategory) ? "white" : "black", backgroundColor: (!colDeltaYearly && !colDeltaCategory) ? "#220FFE" : "" }} onClick={() => setToggle("None")}>None &#916;</div>
            </div> */}
            <div class="scrolling content">
                {columns && rows &&

                    <table className="ui selectable celled table">
                        <thead>
                            <tr>
                                {columns.map((column, i) => (
                                    <>
                                        <th key={i} >{column}</th>
                                    </>

                                ))}
                                {columns.length === 1 &&
                                    <th key={new Date().getFullYear()} >{new Date().getFullYear()}</th>
                                }
                                {colDeltaCategory &&
                                    <th key={"header"} >Absolute Change</th>
                                }
                                {colDeltaYearly && columns.length > 2 &&
                                    <th key={"yearly"} >Percentage Change</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, i) => {
                                return (
                                    <>
                                        <Row rows={rows} row={row} colDeltaCategory={colDeltaCategory} colDeltaYearly={colDeltaYearly} i={i} />
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

function Row(props) {
    const { rows, row, colDeltaCategory, colDeltaYearly, i } = props;
    const [open, setOpen] = React.useState(false);
    function percentChange(oldValue, newValue) {
        if (oldValue === 0 || newValue === 0) {
            if (oldValue > newValue) {
                return "-100%"
            } else if (newValue > oldValue) {
                return "+100%"
            } else {
                return "0%";
            }
        }
        if (oldValue === newValue) {
            return "0%";
        }
        var change = (newValue - oldValue) / oldValue * 100;
        if (change < 0) {
            return change.toFixed(2) + "%";
        } else {
            return "+" + change.toFixed(2) + "%";
        }
    }

    return (
        <>
            <>
                <tr key={i} className={row.header ? "" : ""} style={{
                    fontWeight: row.header ? "bold" : ""
                }}>
                    <td className={row.header ? "" : "pl-5"}>
                        {row.items && row.items.length >= 1 ?
                            <>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </>
                            :
                            <>
                                {row.items && row.items.length === 0 && row.header &&
                                    <>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                            disabled={true}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </>
                                }
                            </>
                        }
                        {row.name}</td>
                    {row.amount.map((value, i) => {
                        return (
                            <>
                                <td style={{ backgroundColor: (i === 1) ? "rgba(1, 233, 143, 0.05)" : "" }}>${value}</td>
                                {i !== 0 &&
                                    <>
                                        <td>{convertToKMB(value - row.amount[i - 1])}</td>
                                        <td>{percentChange(row.amount[i - 1], value)}</td>
                                    </>
                                }
                            </>

                        )
                    })}

                </tr>
                {row.items &&
                    <>
                        {row.items.map((row, i) => {
                            return (
                                <tr hidden={open} key={i} className={row.header ? "" : ""} style={{ width: "100%", fontWeight: row.header ? "bold" : "" }}>
                                    <td className={row.header ? "" : "pl-6"}>{row.name}</td>
                                    {row.amount.map((value, i) => {
                                        return (
                                            <>
                                                <td style={{ backgroundColor: (i === 1) ? "rgba(1, 233, 143, 0.05)" : "" }}>${value}</td>
                                                {i !== 0 &&
                                                    <>
                                                        <td>{convertToKMB(value - row.amount[i - 1])}</td>
                                                        <td>{percentChange(row.amount[i - 1], value)}</td>
                                                    </>
                                                }
                                            </>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </>
                }
            </>
        </>
    );
}