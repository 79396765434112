import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { AuthContext } from '../../context/context';
import { addScenario } from '../../context/project-service';

const PREFIX = 'NewScenario';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '400px' }
}));

export default function NewScenario(props) {

  const {
    state: {
      user, switchUser, isAuthenticated, access_token, purchasing, projects, selectedProject,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [name, setName] = React.useState('Scenario Name');

  const createNewProject = async () => {
    const scenario = await addScenario({ sharedId: switchUser.userName, projectId: projects[selectedProject]._id, scenarioName: name });
    props.handleCloseBackDrop();
  };

  return (
    (<Root>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.backDropOpen}
        onClose={props.handleCloseBackDrop}
      >
        <Dialog classes={{ paper: classes.paper }} onClose={props.handleCloseBackDrop} open={props.backDropOpen}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: '50px 50px 50px 50px' }}>
              <h1 style={{ paddingBottom: '15px', width: '100%' }}>  Project Name </h1>
              <TextField
                style={{ paddingBottom: '15px', width: '100%' }}
                onChange={() => setName(event.target.value)}
                required
                label="Required"
                value={name}
                variant="standard"
              />
              <Button style={{ paddingTop: '15px', width: '100%' }} onClick={() => createNewProject()} variant="contained">Create</Button>
            </CardContent>
          </Card>
        </Dialog>
      </Backdrop>
    </Root>)
  );
}
