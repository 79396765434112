const RealEstateBoardData = [

    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Average Property Price (in thousands)",
                  "data": [
                    319,
                    460,
                    205,
                    252,
                    473,
                    315,
                    264,
                    482,
                    359,
                    354,
                    299,
                    365
                  ],
                  "borderColor": "rgba(75, 75, 192, 1)",
                  "backgroundColor": "rgba(75, 75, 192, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "displayFormats": {
                      "month": "MMM YYYY",
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    },
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond"
                  },
                  "title": {
                    "display": true,
                    "text": "Month",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "title": {
                    "display": true,
                    "text": "Price (in thousands)",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Give me line charts to describe the Average Property Price (in thousands)"
        }
      ],
      "boardFormulas": [],
      "_id": "6750cd4acc4c0ed989b98600",
      "boardType": "graph",
      "title": "Average Property Price (in thousands)",
      "boardView": "graph",
      "index": 0,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "2023-01",
                "2023-02",
                "2023-03",
                "2023-04",
                "2023-05",
                "2023-06",
                "2023-07",
                "2023-08",
                "2023-09",
                "2023-10",
                "2023-11",
                "2023-12"
              ],
              "datasets": [
                {
                  "label": "Rental Income (in thousands)",
                  "data": [
                    63,
                    62,
                    58,
                    57,
                    71,
                    54,
                    79,
                    99,
                    81,
                    92,
                    67,
                    81
                  ],
                  "backgroundColor": [
                    "rgba(45, 49, 66, 1)",
                    "rgba(46, 64, 83, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(44, 62, 80, 1)",
                    "rgba(36, 37, 42, 1)",
                    "rgba(20, 22, 37, 1)",
                    "rgba(33, 47, 61, 1)",
                    "rgba(34, 49, 63, 1)",
                    "rgba(41, 50, 65, 1)",
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(46, 64, 83, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "ticks": {
                    "autoSkip": false,
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate bar charts for rental income"
        }
      ],
      "boardFormulas": [],
      "_id": "6750ce9dcc4c0ed989b98603",
      "boardType": "graph",
      "title": "Rental Income (in thousands)",
      "boardView": "graph",
      "index": 2,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Vacancy Rate (%)",
                  "data": [
                    14.6,
                    15.6,
                    13.2,
                    23.2,
                    19.6,
                    15.9,
                    22.6,
                    23.3,
                    17.3,
                    23.6,
                    25.3,
                    17.9
                  ],
                  "borderColor": "rgba(75, 0, 130, 1)",
                  "backgroundColor": "rgba(75, 0, 130, 0.5)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate line charts for Vacancy Rate (%)"
        }
      ],
      "boardFormulas": [],
      "_id": "6750d4eecc4c0ed989b98609",
      "boardType": "graph",
      "title": "Vacancy Rate (%)",
      "boardView": "graph",
      "index": 4,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>Our new <strong>Real Estate Template</strong> is here to simplify property management and financial analysis. Gain quick insights into key metrics like:</p>\n<ul>\n<li><strong>Average Property Price</strong> &ndash; Track market trends and portfolio values.</li>\n<li><strong>Rental Income</strong> &ndash; Monitor revenue with ease.</li>\n<li><strong>Average Time to Lease</strong> &ndash; Optimize leasing processes.</li>\n<li><strong>Occupancy &amp; Vacancy Rates</strong> &ndash; Maximize portfolio performance.</li>\n</ul>\n<p>With a user-friendly design and automated calculations, this template helps you make data-driven decisions quickly.</p>\n<p><strong>Get started now</strong> and take your real estate management to the next level!</p>"
        }
      ],
      "boardFormulas": [],
      "index": 8,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Introducing the Real Estate Template in Datatrixs",
      "_id": "6750d664cc4c0ed989b9860b",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Occupancy Rate",
                  "data": [
                    89.3,
                    84.1,
                    88.5,
                    89.7,
                    73.6,
                    74.1,
                    76.4,
                    74,
                    73.2,
                    88.8,
                    88.2,
                    86
                  ],
                  "backgroundColor": [
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(41, 128, 185, 1)",
                    "rgba(22, 160, 133, 1)",
                    "rgba(39, 174, 96, 1)",
                    "rgba(142, 68, 173, 1)",
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(41, 128, 185, 1)",
                    "rgba(22, 160, 133, 1)",
                    "rgba(39, 174, 96, 1)",
                    "rgba(142, 68, 173, 1)"
                  ],
                  "borderColor": [
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(41, 128, 185, 1)",
                    "rgba(22, 160, 133, 1)",
                    "rgba(39, 174, 96, 1)",
                    "rgba(142, 68, 173, 1)",
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(41, 128, 185, 1)",
                    "rgba(22, 160, 133, 1)",
                    "rgba(39, 174, 96, 1)",
                    "rgba(142, 68, 173, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "type": "linear",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate Bar Chart Border Radius for Occupancy rate."
        }
      ],
      "boardFormulas": [],
      "_id": "6758b0bead4e003d3d386c81",
      "boardType": "graph",
      "title": "Occupancy Rate",
      "boardView": "graph",
      "index": 6,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Monthly Revenue (in millions)",
                  "data": [
                    7,
                    8,
                    5,
                    7,
                    8,
                    6,
                    7,
                    7,
                    6,
                    8,
                    6,
                    6
                  ],
                  "borderColor": "rgba(75, 75, 192, 1)",
                  "backgroundColor": "rgba(75, 75, 192, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Line chart for Monthly Revenue (in millions)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Monthly Revenue (in millions)",
      "boardView": "graph",
      "index": 6,
      "_id": "675a7de3ad4e003d3d386ca8",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01",
                "2023-05-01",
                "2023-06-01",
                "2023-07-01",
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01"
              ],
              "datasets": [
                {
                  "label": "Operating Margin (%) Over Time",
                  "data": [
                    21.4,
                    29.7,
                    23.4,
                    28,
                    26.5,
                    28.3,
                    21.8,
                    23.9,
                    22.4,
                    22.3,
                    28.9,
                    27.4
                  ],
                  "borderColor": "rgba(44, 62, 80, 1)",
                  "backgroundColor": "rgba(44, 62, 80, 0.2)",
                  "borderWidth": 1,
                  "fill": true
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "time",
                  "time": {
                    "unit": "month",
                    "parser": false,
                    "round": false,
                    "isoWeekday": false,
                    "minUnit": "millisecond",
                    "displayFormats": {
                      "datetime": "MMM D, YYYY, h:mm:ss a",
                      "millisecond": "h:mm:ss.SSS a",
                      "second": "h:mm:ss a",
                      "minute": "h:mm a",
                      "hour": "hA",
                      "day": "MMM D",
                      "week": "ll",
                      "month": "MMM YYYY",
                      "quarter": "[Q]Q - YYYY",
                      "year": "YYYY"
                    }
                  },
                  "bounds": "data",
                  "ticks": {
                    "source": "auto",
                    "callback": false,
                    "major": {
                      "enabled": false
                    },
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate Area chart for  Operating Margin (%)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Operating Margin (%) Over Time",
      "boardView": "graph",
      "index": 6,
      "_id": "675a82dcad4e003d3d386ca9",
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>Monthly Revenue is a vital indicator of our business's financial health and growth trajectory. It reflects the total income generated from operations each month, providing insight into the effectiveness of our sales strategies, market demand, and customer retention.</p>\n<p>In this section, we present a detailed analysis of our monthly revenue trends, highlighting key patterns, seasonal variations, and their implications for our financial strategy. This data is crucial for evaluating performance, identifying growth opportunities, and making informed decisions to drive long-term success.</p>"
        }
      ],
      "boardFormulas": [],
      "index": 7,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Monthly Revenue (in Millions)",
      "_id": "675a832aad4e003d3d386caa",
      "invited": []
    }
  ]

const RealEstateLayout = [
  {
    "w": 5,
    "h": 3,
    "x": 5,
    "y": 3,
    "i": "0",
    "minW": 2,
    "minH": 3,
    "moved": false,
    "static": false
  },
  {
    "w": 5,
    "h": 3,
    "x": 5,
    "y": 9,
    "i": "1",
    "minW": 2,
    "minH": 3,
    "moved": false,
    "static": false
  },
  {
    "w": 5,
    "h": 3,
    "x": 5,
    "y": 6,
    "i": "2",
    "minW": 2,
    "minH": 3,
    "moved": false,
    "static": false
  },
  {
    "w": 6,
    "h": 3,
    "x": 4,
    "y": 0,
    "i": "3",
    "minW": 2,
    "minH": 2,
    "moved": false,
    "static": false
  },
  {
    "w": 4,
    "h": 3,
    "x": 0,
    "y": 0,
    "i": "4",
    "minW": 2,
    "minH": 3,
    "moved": false,
    "static": false
  },
  {
    "w": 5,
    "h": 3,
    "x": 0,
    "y": 6,
    "i": "5",
    "minW": 2,
    "minH": 3,
    "moved": false,
    "static": false
  },
  {
    "w": 5,
    "h": 3,
    "x": 0,
    "y": 9,
    "i": "6",
    "minW": 2,
    "minH": 3,
    "moved": false,
    "static": false
  },
  {
    "w": 5,
    "h": 3,
    "x": 0,
    "y": 3,
    "i": "7",
    "minW": 2,
    "minH": 2,
    "moved": false,
    "static": false
  }
]

export { RealEstateBoardData, RealEstateLayout }