import axios from './axios';

export const getTeams = async () => new Promise((resolve, reject) => {
  axios
    .get('/getTeams')
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});


