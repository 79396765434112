/* eslint-disable */

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Updated import
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import { useStyles } from './styles.js';
import { Box, Card, Typography, Button, useMediaQuery, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundImage from '../../assets/grid-3.svg';
import warren from '../../assets/product-warren.svg';

function ResetPassword() {
  const classes = useStyles();
  const navigate = useNavigate(); // Updated hook

  const [alertClass, setAlertClass] = useState('');
  const [msg, setMsg] = useState('');
  const [err, setErr] = useState('');
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [resetForm, setResetForm] = useState({
    username: '',
    code: '',
    newPassword: ''
  });
  const [validPassword, setValidPassword] = useState(false);
  const [showPasswordReqsPanel, setShowPasswordReqsPanel] = useState(false);
  const [passwordReqs, setPasswordReqs] = useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    lengthOf8: false,
  });

  const handleReset = (e) => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      passwordValidator(value);
    }
    setResetForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (step === 1) {
      try {
        const response = await axios.post('/send-code', { username: resetForm.username });
        setMsg('Verification code sent to your email');
        setErr('');
        setStep(2);
      } catch (e) {
        setAlertClass('show');
        setMsg('');
        if (e.response && e.response.data && e.response.data.message) {
          setErr(e.response.data.message);
        } else {
          setErr('User not found. Please sign up.');
        }
      } finally {
        setLoader(false);
      }
    } else {
      try {
        const response = await axios.post('/reset-password', {
          username: resetForm.username,
          code: resetForm.code,
          newPassword: resetForm.newPassword,
        });
        setMsg('Password reset successful');
        setErr('');
        setStep(3);
      } catch (e) {
        setAlertClass('show');
        setMsg('');
        if (e.response && e.response.data && e.response.data.message) {
          setErr(e.response.data.message);
        } else {
          setErr(e.message);
        }
      } finally {
        setLoader(false);
      }
    }
  };

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  const passwordValidator = (password) => {
    let lowerCase = false;
    let upperCase = false;
    let number = false;
    let special = false;
    let lengthOf8 = false;
    if (password.match(/[a-z]/)) {
      lowerCase = true;
    }
    if (password.match(/[A-Z]/)) {
      upperCase = true;
    }
    if (password.match(/[0-9]/)) {
      number = true;
    }
    if (password.match(/(?=.*[!@#$%^&*])/)) {
      special = true;
    }
    if (password.length >= 8) {
      lengthOf8 = true;
    }

    setPasswordReqs({
      lowerCase, upperCase, number, special, lengthOf8,
    });
    setValidPassword(lowerCase && upperCase && number && special && lengthOf8);
  };

  const PasswordReqsPanel = (props) => (
    <div style={{
      borderStyle: 'solid', borderColor: '#CAD4D7', borderRadius: '5px', borderWidth: '1px', width: '250px', fontSize: '15px', position: 'fixed', backgroundColor: 'white', zIndex: '10',
    }}
    >
      <div className="row align-items-center justify-content-center">
        <p className="mt-3 ml-5">
          {props.passwordReqs.lowerCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One lowercase Character
        </p>
        <p className="ml-5">
          {props.passwordReqs.upperCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One uppercase character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.number ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One number
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.special ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One special character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.lengthOf8 ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          8 characters minimum
          {' '}
        </p>
      </div>
    </div>
  );

  if (step === 3) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%',
            background: '#00396D',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        >
          <Card
            sx={{
              width: { xs: '100%', md: '70%' },
              padding: { xs: '30px', md: '60px' },
              backgroundColor: '#F8FAFC',
              boxShadow: 0,
            }}
          >
            <Box
              sx={{
                textAlign: 'left',
                padding: '50px 10px',
                width: { xs: '80%', md: '50%' },
                margin: '0 auto',
              }}
            >
              <Link to="/">
                <img src={LOGO} alt="logo-black" style={{ width: '130px', height: '20px', marginBottom: '30px' }} />
              </Link>
              
              <Typography
                variant="h4"
                align="left"
                sx={{ color: '#55616E', mb: 3 }}
              >
                Password Reset Successful
              </Typography>
              <Box textAlign="center" mt={3}>
                <Button onClick={() => navigate('/login')} sx={{ backgroundColor: 'black', color: 'white', width: '100%', mt: 3 }}>
                  Back to Login
                </Button>
              </Box>
            </Box>
          </Card>

          {/* Warren Image */}
          <Card
              sx={{
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                width: '30%',
                padding: 3,
                boxShadow: 0,
                background: `url(${backgroundImage}) no-repeat`,
              }}
            >
              <img src={warren} alt="warren" style={{ maxWidth: '80%', height: 'auto' }} />
          </Card>

        </Box>
      </Box>
    );
  }
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
          background: '#00396D',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      >
        <Card
          sx={{
            width: { xs: '100%', md: '70%' },
            padding: { xs: '30px', md: '60px' },
            backgroundColor: '#F8FAFC',
            boxShadow: 0,
          }}
        >
          <Box
            sx={{
              textAlign: 'left',
              padding: '50px 10px',
              width: { xs: '80%', md: '50%' },
              margin: '0 auto',
            }}
          >
            <Box sx={{ 
              textAlign: 'left',
              marginBottom: '100px',
              }}>

              <Link to="/">
                <img src={LOGO} alt="logo-black" style={{ width: '130px', height: '20px', marginBottom: '30px' }} />
              </Link>
            </Box>
            
            <Typography
              variant="h2"
              align="left"
              sx={{
                fontWeight: 900,
                fontSize: { xs: '36px', md: '46px' },
                color: '#55616E',
                mb: 3,
              }}
            >
              Reset Password
            </Typography>
            <Typography sx={{ color: '#55616E', marginTop: 2, textAlign: 'left' }}>
              Remembered your password? <Link to="/login">Sign in</Link>
            </Typography>
  
            {msg && (
              <Box className={`alert alert-success alert-dismissible fade show`} role="alert">
                <strong>{msg}</strong>
                <Button onClick={handleCloseAlert} type="button" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}
            {err && (
              <Box className={`alert alert-danger alert-dismissible fade show`} role="alert">
                <strong>{err}</strong>
                <Button onClick={handleCloseAlert} type="button" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}
  
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              {step === 1 ? (
                <TextField
                  label="Email"
                  type="email"
                  name="username"
                  value={resetForm.username}
                  onChange={handleReset}
                  required
                  placeholder="Email"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              ) : (
                <>
                  <TextField
                    label="Verification Code"
                    name="code"
                    value={resetForm.code}
                    onChange={handleReset}
                    required
                    placeholder="Verification Code"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  />
                  <TextField
                    label="New Password"
                    type="password"
                    name="newPassword"
                    value={resetForm.newPassword}
                    onChange={handleReset}
                    onFocus={() => setShowPasswordReqsPanel(true)}
                    onBlur={() => setShowPasswordReqsPanel(false)}
                    required
                    placeholder="New Password"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  />
                  {showPasswordReqsPanel && !validPassword && <PasswordReqsPanel passwordReqs={passwordReqs} />}
                </>
              )}
  
              <Button
                type="submit"
                disabled={loader || (step === 2 && !validPassword)}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  width: '100%',
                  mt: 3,
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                }}
              >
                {loader ? 'Loading...' : step === 1 ? 'Send Code' : 'Reset Password'}
              </Button>
            </Box>
          </Box>
        </Card>

        {/* Warren Image */}
        <Card
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              width: '30%',
              padding: 3,
              boxShadow: 0,
              background: `url(${backgroundImage}) no-repeat`,
            }}
          >
            <img src={warren} alt="warren" style={{ maxWidth: '80%', height: 'auto' }} />
        </Card>

      </Box>
    </Box>
  );
}

export default ResetPassword;
