// setup the AI Assistant
/*eslint-disable*/

// have a render array, where each function will append to the render array with the properties 
/*
Use chart.js to generate the charts instead of function calling, might be better. And then in this case we'd use code interpreter

Essentially do this, but create each of the functions below for that
Then within the actual Assistant component, we just do renderArray.length > 0 && ...
*/



// Probably move this code to the backend
import { fileSearchAssistant, fetchGraphAssistant, uploadFiles } from "../../context/fetch-service";
// import OpenAI from "openai";
// import fs from 'fs'

// const openai = new OpenAI({
//   apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
//   dangerouslyAllowBrowser: true,
// });



const setUpAssistant = async (ragData, userInput, date_change, refresh, id, setError, setTitle) => {
  // Upload a file converted to json format with an "assistants" purpose
  // console.log(data);
  console.log(userInput);
  console.log(date_change);
  try {
    const files = await uploadFiles(JSON.stringify(ragData));
  }
  catch (error) {
    console.error(error.message);
    if (error.status == 401) {
      setError("Please log back in or resync your integrations.");
    }
    else {
      setError("Unable to upload files");
    }
    return null;
  }
  try {
    const ragResponse = await fileSearchAssistant(userInput, date_change);
    console.log(ragResponse.data.response);
    // const data = {
    //   chartType: "bar",
    //   labels: ["Total Assets"],
    //   data: [150]
    // }
    let graphResponse;
    try {
      const data = JSON.parse(ragResponse.data.response);
      // set the title originally just in case the graph assistant hallucinates
      setTitle(ragResponse.data.response.title);
      console.log(data);
      graphResponse = await fetchGraphAssistant(data);
    }
    catch(error) {
      console.error(error.message);
      setError("From Mira: " + ragResponse.data.response);
      return null;
    }

    if (refresh) {
      return {id: id, config: graphResponse.data}
    }

    return graphResponse;
  }
  catch (error) {
    console.error(error.message);
    setError('Unable to conduct file search.');
    return null;
  }

//   const graphingAssistantResponse = await fetchGraphAssistant(ragResponse);
// //   chain RAG Assistant with graphing assistant

//   const graphingAssistant = await client.beta.assistants.create({
//     name: "Graphing Assistant",
//     model: "gpt-4o",
//     // zero-shot prompting
//     instructions: `You are a chart and graph generator based on financial user data from intuit quickbooks that is in json format.
//             Please generate a script in JavaScript with Chart.js to create a graph or chart of what the user is asking for and provide the link to the script. 
//             Take a deep breath, and focus on writing clean, performant, and concise code to answer the user query. 
//             If the user does not provide a query that is not relevant to the data, then say the question given is not relevant to the data.
//             `,
//     tools: [],
//   });
    return null;
}

export default setUpAssistant;