/* eslint-disable */
function getTotalValue(revenues, model) {
  if (revenues && revenues.revenuInputs) {
    let total = 0;
    for (let revenueValues of revenues.revenuInputs) {

      if (revenueValues.model === model) {
        total += parseInt(revenueValues.price);
      }
    }
    return 'Total $' + total;
  }
  return '$0';
}

function getTotalValueHiring(inputs, model) {

  if (inputs) {


    let total = 0;
    for (let input of inputs) {
      if (input.title === model) {
        for (let inputValues of input.inputs) {
          total += parseInt(inputValues.salary);
        }
      }
      //total += parseInt(inputValues.salary);
    }
    return 'Total $' + total;
  }
  return '$0';
}

function convertDate(date) {
  if (date) {
    date = date.substring(0, 10).split('-').reverse();
    date = date[1] + '/' + date[0] + '/' + date[2];
    return date;
  }
  return '';
}

export { convertDate, getTotalValue, getTotalValueHiring };
