/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PieController,
  DoughnutController,
  BarController,
  LineController,
  RadarController,
  PolarAreaController,
  LogarithmicScale
} from "chart.js";
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { AuthContext } from "../../context/context";

const ChartComponent = ({ config, chartId, setRef }) => {
  const chartRef = useRef(null);
  // Register the necessary components for a pie chart
  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    PieController,
    DoughnutController,
    BarController,
    LineController,
    RadarController,
    PolarAreaController,
    LogarithmicScale
  );

  const {state: {
    allCharts, chartIds
  }, dispatch} = React.useContext(AuthContext);

const callbacks = {
  formatCurrency: (value) => `$${value.toLocaleString()}`,
  formatPercentage: (value) => `${value.toLocaleString()}%`,
};

useEffect(() => {
  if (
    config.options?.scales?.y?.ticks &&
    typeof config.options.scales.y.ticks.callback === 'string'
  ) {
    const callbackName = config.options.scales.y.ticks.callback;
    if (callbacks[callbackName]) {
      config.options.scales.y.ticks.callback = callbacks[callbackName];
    }
  }

  if (chartRef.current) {
    const chart = new Chart(chartRef.current, config);

    // Save the chart instance if setRef is provided
    if (setRef) {
      setRef(chartId, chart);
    }

    return () => {
      chart.destroy(); // Cleanup chart instance on unmount
    };
  }
}, [config, setRef, chartId]);

  return (
    <div style={{ position: 'relative', width: "100%", height: "300px", overflow: 'hidden'}}>
      <canvas
        ref={chartRef}
        id={chartId}
        style={{ width: "100%", height: "300px" }}
      ></canvas>
      {/* {currCharts &&
        currCharts.map((chart) => {
          if (chart.id === newChart.id) {
            return (
              <canvas
                key={key}
                id={chart.id}
                style={{ width: "200px", height: "300px" }}
              ></canvas>
            );
          }
        })} */}
    </div>
  );
};

export default ChartComponent;
