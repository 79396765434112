/* eslint-disable */
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { createStyles } from "@mui/material/styles";
import * as React from 'react';
import Freshbooks from '../../assets/freshbooks.png';
import Quickbooks from '../../assets/quickbooks.png';
import Xero from '../../assets/xero.png';
import { intuitLogin } from '../../context/intuit-service';
import IntegrationCards from '../../views/Settings/IntegrationCards';
import { CardContentNoPadding } from './CustomStyles';
import StepperStyles from './Styles';


const useStyles = makeStyles()(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    }
}))
const goToIntuit = async () => {
    const sub = await intuitLogin();
    if (sub.status === 200 || sub.status === 201) {
        window.location.href = sub.data.url;
    }
}

const integrations = [
    <Button style={{
        textTransform: 'none',
        padding: "0px"
    }}><div style={StepperStyles.integrations}
        onClick={() => goToIntuit()}
    > <p style={{
        marginBottom: "0px"
    }}>  <img src={Quickbooks}></img>&nbsp; Sync with QuickBooks</p></div></Button>,
    <Button style={{
        textTransform: 'none',
        padding: "0px"
    }}> <div style={StepperStyles.integrations}> <p style={{
        marginBottom: "0px"
    }}> <img src={Xero}></img>&nbsp; Sync with Xero </p></div></Button>,
    <Button style={{
        textTransform: 'none',
        padding: "0px"

    }}> <div style={StepperStyles.integrations}> <p style={{
        marginBottom: "0px"
    }}> <img src={Freshbooks}></img>&nbsp; Sync with Freshbooks </p></div></Button>,
];






export default function Integrations(props) {
    const [search, setSearchValue] = React.useState("");
    const [files, setFiles] = React.useState("");

    function setSearch(e) {
        setSearchValue(e.value);
    }

    function handleChange(files) {

        setFiles(files);
    }

    const classes = useStyles();


    return (
        (<div>
            <Card
                style={{ margin: "0 auto" }}
                sx={{ width: "1000px" }}>
                <CardContentNoPadding>
                    <Typography
                        style={StepperStyles.header}
                        gutterBottom
                        sx={{
                            color: "text.secondary",
                            fontSize: 30
                        }}>
                        2. Connect Your Books to Datatrixs
                    </Typography>
                    <div className="row"
                        style={{
                            paddingLeft: "5%", paddingRight: "5%"
                        }}>
                        <IntegrationCards />
                        {/* <DropzoneArea
                            maxFileSize={5000000}
                            showFileNames={true}
                            showPreviewsInDropzone={false}
                            showPreviews={true}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewChipProps={{ classes: { root: classes.previewChip } }}
                            previewText="Files to upload"
                            dropzoneText='drop csv. files to upload, or click here'
                            onChange={(files) => handleChange(files)}
                            disabled={true}
                        /> */}
                        <br />
                        <div style={{ maringLeft: "10%", maringRight: "10%", paddingBottom: "20px" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} >
                                <Button
                                    color="inherit"
                                    disabled={false}
                                    onClick={() => props.handleBack()}
                                    sx={{ mr: 1 }}
                                    style={{
                                        textTransform: "none"

                                    }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <button className="ui right floated primary button mr-3" style={{ backgroundColor: "black" }} onClick={() => props.handleNext()} sx={{ mr: 1 }}>
                                    Sync & Continue
                                </button>
                            </Box>
                        </div>
                    </div>
                </CardContentNoPadding>
            </Card>
        </div>)
    );
}

