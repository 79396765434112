/* eslint-disable */

const StepperStyles = {
    header: {
        background: "white",
        opacity: "0.7",
        marginBottom: "0px",
        marginBottom: "10px",
        paddingTop: "10px",
        paddingLeft: "20px",
        paddingBottom: "10px",
        color: "black"
    },

    buttonBlue: {
        background: "#220FFE",
        color: "white",
        borderRadius: 20, border: '1px solid',
        textTransform: "none"
    },
    integrations: {
        borderRadius: 10, border: '1px solid #0075DD',
        padding: "5px 5px 5px 5px",
        marginBottom: "5px"
    }
}

export default StepperStyles;