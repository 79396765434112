/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import FreshBooks from '../../assets/freshbooks.png';
import QuickBooks from '../../assets/qb-logo.svg';
import Xero from '../../assets/xero.png';
import Spinner from '../../components/Workstation/Helpers/Spinner';
import { AuthContext } from '../../context/context';
import { getIntegrationInfo, deleteIntegrationInfo, validateToken } from '../../context/intuit-service';
import { useStyles } from './styles.js';

// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import { Box, Typography, Button, Grid, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ConnectedIntegrations() {
  const { state, dispatch } = useContext(AuthContext);
  const [msg, setMsg] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [type, setType] = useState("success");
  const [openMsg, setOpenMsg] = useState(false);
  const [prog, setProg] = useState(true);
  const [connectedBooks, setConnectedBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isValidatingToken, setIsValidatingToken] = useState(false);
  const [deletedOpen, setDeletedOpen] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);

  const getIcon = {
    QuickBooks,
    Xero,
    Freshbooks: FreshBooks,
  };

  // Open and close modal logic
  const openModal = (id) => {
    if (openModalId !== id) {
      setOpenModalId(id); // Only update if necessary
    }
  };

  const handleClose = () => setOpenModalId(null);
  const handleDeletedOpen = () => setDeletedOpen(true);
  const handleDeletedClose = () => setDeletedOpen(false);

  // Fetch integration info
  const fetchIntegrationInfo = useCallback(async () => {
    if (loading) {
      return;
    }
    console.log("Fetching integration info...");
    setLoading(true);
    try {
      const books = await getIntegrationInfo();
      if (books && books.data) {
        if (books.data.integrations !== state.integrationInfo) {
          dispatch({
            type: "SET_INTEGRATIONINFO",
            payload: books.data.integrations,
          });
        }
        setConnectedBooks(books.data.integrations?.integrations || []);
      } else {
        setConnectedBooks([]);
      }
    } catch (error) {
      console.error("Error fetching integration info:", error);
      setConnectedBooks([]);
    } finally {
      setLoading(false);
      console.log("Integration info fetched successfully.");
    }
  }, [dispatch, state.integrationInfo]);

  // Validate token for QuickBooks
  const validateIntegrationToken = useCallback(async () => {
    if (!connectedBooks.length || isValidatingToken) return;

    console.log("Validating QuickBooks token...");
    setIsValidatingToken(true);

    const quickbooksIntegration = connectedBooks.find(
      (book) => book.integrationType === "QuickBooks"
    );
    if (!quickbooksIntegration) return;

    const data = {
      realmId: quickbooksIntegration.realmId,
      refresh_token: quickbooksIntegration.refresh_token,
      access_token:
        state.integrations?.quickbooks?.access?.token?.access_token || "",
    };

    try {
      const validatedToken = await validateToken(data);
      setIsTokenValid(validatedToken.status === 201);
      if (validatedToken.status === 201) {
        dispatch({
          type: "SET_QUICKBOOK_AUTH",
          payload: {
            code: "",
            realmId: quickbooksIntegration.realmId,
            state: "",
            token: validatedToken.data.token,
          },
        });
        setAlertMessage("");
      }
    } catch (error) {
      console.error("Error validating token:", error);
      setAlertMessage("No connected integrations found.");
      setIsTokenValid(false);
    } finally {
      setIsValidatingToken(false);
    }
  }, [connectedBooks, dispatch, state.integrations]);

  //Fetch integration info on mount
  useEffect(() => {
    fetchIntegrationInfo();
  }, [fetchIntegrationInfo]);

  //Validate token whenever connectedBooks change
  useEffect(() => {
    validateIntegrationToken();
  }, [connectedBooks, validateIntegrationToken]);

  // Delete integration logic
  const deleteIntegration = useCallback(
    async (integration) => {
      setProg(false);
      try {
        const { protocol, hostname, port } = window.location;
        const URL = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
        const data = {
          integrationId: integration.integrationId,
          URL,
        };
        const response = await deleteIntegrationInfo(data);
        if (response && response.data?.result?.integrations) {
          setConnectedBooks(response.data.result.integrations);
          dispatch({
            type: "SET_INTEGRATIONINFO",
            payload: response.data.result,
          });
        }
        dispatch({
          type: "SET_SELECTEDINTEGRATION",
          payload: 0,
        });
        handleClose();
        handleDeletedOpen();
      } catch (error) {
        console.error("Error deleting integration:", error);
      } finally {
        setProg(true);
      }
    },
    [dispatch]
  );

  return (
    <>
      {loading ? (
        <Spinner height={"100px"} />
      ) : (
        <>
          {/* Check if alert message and token are invalid */}
          {alertMessage && !isTokenValid ? (
            <Typography variant="body1" color="error" align="left">
              {alertMessage}
            </Typography>
          ) : // Check if no integrations are found
          connectedBooks.length === 0 ? (
            <Typography variant="body1" color="error" align="left">
              No connected integrations found.
            </Typography>
          ) : (
            // If there are connected integrations, map them
            connectedBooks.map((book) => (
              <React.Fragment key={book.integrationId}>
                {/* Modal for disconnecting integration */}
                <Modal
                  open={openModalId === book.integrationId}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Are you sure you would like to disconnect this
                      integration?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div
                        className="ui right red button"
                        onClick={() => deleteIntegration(book)}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      >
                        Yes
                      </div>
                      <div
                        className="ui right button"
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      >
                        No
                      </div>
                    </Typography>
                  </Box>
                </Modal>

                {/* Integration card display */}
                <Box
                  sx={{
                    mb: 3,
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <Box sx={{ p: 2, textAlign: "left", bgcolor: "white.100" }}>
                    <img
                      src={getIcon[book.integrationType]}
                      alt={`${book.integrationType} Logo`}
                      style={{ maxWidth: "200px" }}
                    />
                  </Box>
                  <Box sx={{ p: 2, bgcolor: "#F7F8FC" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Account Info
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography>{book.email}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography>
                          {new Date(book.date).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      mb: 3,
                      mt: 3,
                      pl: 2,
                      textAlign: "left",
                      bgcolor: "white.100",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => openModal(book.integrationId)}
                      disabled={openModalId === book.integrationId}
                    >
                      Disconnect
                    </Button>
                  </Box>
                </Box>
              </React.Fragment>
            ))
          )}

          {/* Modal to confirm disconnection */}
          <Modal
            open={deletedOpen}
            onClose={handleDeletedClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                You're successfully disconnected.
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
// export default ConnectedIntegrations;
