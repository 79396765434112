/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/context';
import {
  getBalanceSheet,
  getCashFlow,
  getIncomeStatement,
  validateToken
} from '../../context/intuit-service';
import { getDateRange } from "../ClientPerformance/helpers";
import { parseDate, createDateRangeStrings, combineArrays, processArray, calculatePercentage } from './Helpers/QuickbooksParser';
import { options } from './Helpers/Sankey';
import Alerts from '../../views/Misc/Alerts';
import IntegrationsButton from '../Projects/IntegrationsButton';
import { CardContentNoPadding } from '../Stepper/CustomStyles';
import LoadingTable from './LoadingTable';
import TopFiveExpenses from './TopFiveExpenses';
import { Chart } from "react-google-charts";
import { DatesRangeInput } from "semantic-ui-calendar-react-17";
import { Card, Typography, Button, FormControl, MenuItem, Select, ListSubheader } from '@mui/material';

const Reports = () => {
  const {
    state: { integrations, integrationInfo, selectedIntegration },
    dispatch
  } = useContext(AuthContext);

  // State Variables
  const [balanceSheet, setBalanceSheet] = useState([null]);
  const [cashFlow, setCashFlow] = useState(null);
  const [incomeStatement, setIncomeStatement] = useState(null);

  const [balanceSheet2, setBalanceSheet2] = useState([]);
  const [cashFlow2, setCashFlow2] = useState([]);
  const [incomeStatement2, setIncomeStatement2] = useState([]);

  const [data, setData] = useState([]);
  const [sankeyData, setSankeyData] = useState([]);
  const [data2, setData2] = useState([]);
  const [sankeyData2, setSankeyData2] = useState([]);

  const [balanceSheetTable, setBalanceSheetTable] = useState(null);
  const [cashFlowTable, setCashFlowTable] = useState(null);
  const [incomeStatementTable, setIncomeStatementTable] = useState(null);
  const [balanceSheetTable2, setBalanceSheetTable2] = useState(null);
  const [cashFlowTable2, setCashFlowTable2] = useState(null);
  const [incomeStatementTable2, setIncomeStatementTable2] = useState(null);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableHeaders2, setTableHeaders2] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [dataTableCompare, setDataTableCompare] = useState([]);

  const [date, setDate] = useState(getDateRange());
  const [date2, setDate2] = useState(getDateRange());

  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleValue, setToggleValue] = useState("$");
  const [currentView, setCurrentView] = useState("Income Statement");
  const [loadingIncome, setLoadingIncome] = useState(true);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [loadingCashflow, setLoadingCashflow] = useState(true);

  const [msg, setMsg] = useState("");
  const [type, setType] = useState('success');
  const [openMsg, setOpenMsg] = useState(false);

  // Handlers
  const handleCheckboxChange = (event) => setIsChecked(event.target.checked);

  const handleDateChange = (event, { name, value }) => {
    setDate(value);
  };

  const handleDateChange2 = (event, { name, value }) => {
    setDate2(value);
  };

  const toggleSankeyView = useCallback((value) => {
    if (value === "%") {
      const sankeyDataValue = calculatePercentage(data);
      const sankeyDataValue2 = calculatePercentage(data2);
      setToggleValue("%");
      setSankeyData(sankeyDataValue);
      setSankeyData2(sankeyDataValue2);
    } else {
      setToggleValue("$");
      setSankeyData(data);
      setSankeyData2(data2);
    }
  }, [data, data2]);

  const setCurrentViewPage = useCallback((value) => {
    setCurrentView(value);
    setToggleValue("$");
    if (value === "Income Statement") {
      setData(incomeStatement);
      setSankeyData(incomeStatement);
      setData2(incomeStatement2);
      setSankeyData2(incomeStatement2);
      setDataTable(incomeStatementTable);
      setDataTableCompare(incomeStatementTable2);
    } else if (value === "Balance Sheet") {
      setData(balanceSheet);
      setSankeyData(balanceSheet);
      setData2(balanceSheet2);
      setSankeyData2(balanceSheet2);
      setDataTable(balanceSheetTable);
      setDataTableCompare(balanceSheetTable2);
    } else if (value === "Cash Flow") {
      setData(cashFlow);
      setSankeyData(cashFlow);
      setData2(cashFlow2);
      setSankeyData2(cashFlow2);
      setDataTable(cashFlowTable);
      setDataTableCompare(cashFlowTable2);
    }
  }, [
    incomeStatement,
    incomeStatement2,
    incomeStatementTable,
    incomeStatementTable2,
    balanceSheet,
    balanceSheet2,
    balanceSheetTable,
    balanceSheetTable2,
    cashFlow,
    cashFlow2,
    cashFlowTable,
    cashFlowTable2
  ]);

  // Async Functions
  const getLiveData = useCallback(async () => {
    try {
      setToggleValue("$");
      const query = parseDate(date);
      const dataPayload = {
        realmId: integrations.quickbooks.access.realmId,
        access_token: integrations.quickbooks.access.token.access_token,
        refresh_token: integrations.quickbooks.access.token.refresh_token,
        singleQuery: query,
        query: [query],
        compare: true
      };

      let ranges = [parseDate(date), parseDate(date2)];
      if (!isChecked) {
        const [startDateString, endDateString] = date.split(" - ");
        ranges = createDateRangeStrings(startDateString, endDateString);
      }

      const months = ranges.map(dateRangeStr => {
        const match = dateRangeStr.match(/start_date=(.*)&end_date=(.*)/);
        const startDateStr = match ? match[1] : '';
        const endDateStr = match ? match[2] : '';
        const endDate = new Date(endDateStr);
        return endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
      });
      months.unshift("Title");
      setTableHeaders(months);

      if (isChecked) {
        dataPayload.compare = true;
        setLoadingIncome(true);
        setLoadingBalance(true);
        setLoadingCashflow(true);
        dataPayload.query = [ranges[0]];

        // Fetch Income Statement
        const incomeStatementResponse = await getIncomeStatement(dataPayload);
        dataPayload.query = [ranges[1]];
        const incomeStatementResponse2 = await getIncomeStatement(dataPayload);
        setIncomeStatement(incomeStatementResponse.data.data);
        setIncomeStatement2(incomeStatementResponse2.data.data);
        const combinedIncome = processArray(combineArrays(incomeStatementResponse.data.table, incomeStatementResponse2.data.table, 1));
        setIncomeStatementTable2(combinedIncome);
        if (currentView === "Income Statement") {
          setDataTableCompare(combinedIncome);
          setLoadingIncome(false);
        }

        // Fetch Balance Sheet
        dataPayload.query = [ranges[0]];
        const balanceSheetResponse = await getBalanceSheet(dataPayload);
        dataPayload.query = [ranges[1]];
        const balanceSheetResponse2 = await getBalanceSheet(dataPayload);
        setBalanceSheet(balanceSheetResponse.data.data);
        setBalanceSheet2(balanceSheetResponse2.data.data);
        const combinedBalance = processArray(combineArrays(balanceSheetResponse.data.table, balanceSheetResponse2.data.table, 1));
        setBalanceSheetTable2(combinedBalance);
        if (currentView === "Balance Sheet") {
          setDataTableCompare(combinedBalance);
          setLoadingBalance(false);
        }

        // Fetch Cash Flow
        dataPayload.query = [ranges[0]];
        const cashFlowResponse = await getCashFlow(dataPayload);
        dataPayload.query = [ranges[1]];
        const cashFlowResponse2 = await getCashFlow(dataPayload);
        setCashFlow(cashFlowResponse.data.data);
        setCashFlow2(cashFlowResponse2.data.data);
        const combinedCashflow = processArray(combineArrays(cashFlowResponse.data.table, cashFlowResponse2.data.table, 1));
        setCashFlowTable2(combinedCashflow);
        if (currentView === "Cash Flow") {
          setDataTableCompare(combinedCashflow);
          setLoadingCashflow(false);
        }

        setTableHeaders2(["Title", date, date2]);
      } else {
        dataPayload.query = ranges;
        setLoading(true);
        setLoadingIncome(true);
        setLoadingBalance(true);
        setLoadingCashflow(true);
        try {
          const [incomeStatementResp, balanceSheetResp, cashFlowResp] = await Promise.all([
            getIncomeStatement(dataPayload),
            getBalanceSheet(dataPayload),
            getCashFlow(dataPayload)
          ]);

          const IncomeStatement = incomeStatementResp.data;
          const BalanceSheet = balanceSheetResp.data;
          const CashFlow = cashFlowResp.data;

          setIncomeStatement(IncomeStatement.data);
          setIncomeStatementTable(IncomeStatement.table);

          setBalanceSheet(BalanceSheet.data);
          setBalanceSheetTable(BalanceSheet.table);

          setCashFlow(CashFlow.data);
          setCashFlowTable(CashFlow.table);

          if (currentView === "Income Statement") {
            setData(IncomeStatement.data);
            setSankeyData(IncomeStatement.data);
            setDataTable(IncomeStatement.table);
            setLoadingIncome(false);
          } else if (currentView === "Balance Sheet") {
            setData(BalanceSheet.data);
            setSankeyData(BalanceSheet.data);
            setDataTable(BalanceSheet.table);
            setLoadingBalance(false);
          } else if (currentView === "Cash Flow") {
            setData(CashFlow.data);
            setSankeyData(CashFlow.data);
            setDataTable(CashFlow.table);
            setLoadingCashflow(false);
          }

          setLoading(false);
        } catch (error) {
          console.error("Error while fetching data:", error);
          setLoading(false);
          setLoadingIncome(false);
          setLoadingBalance(false);
          setLoadingCashflow(false);
        }
      }
    } catch(error){
       
    }
  },[
    integrations,
    integrations.quickbooks.access.realmId,
    integrations.quickbooks.access.token.access_token,
    integrations.quickbooks.access.token.refresh_token,
    isChecked,
    date,
    date2,
    currentView
  ]);

  useEffect(() => {
    let isMounted = true; 
    const fetchLiveData = async () => {
      if (
        integrations &&
        integrations.quickbooks &&
        integrations.quickbooks.access &&
        integrations.quickbooks.access.token &&
        integrations.quickbooks.access.token.access_token
      ) {
        try {
          await getLiveData();
        } catch (error) {
          console.error('Error fetching live data:', error);
        }
      }
    };

    if (isMounted) {
      fetchLiveData();
    }

    return () => {
      isMounted = false; 
    };
  }, [integrations, getLiveData]);

  // Refactored useEffect for Validating Token
  useEffect(() => {
    let isMounted = true; 

    const validateAndSetToken = async () => {
      if (integrationInfo) {
        setLoading(true);
        const data = {
          realmId: integrationInfo.integrations[selectedIntegration].realmId,
          refresh_token: integrationInfo.integrations[selectedIntegration].refresh_token,
          access_token: integrations.quickbooks.access?.token?.access_token || ""
        };

        try {
          const validatedToken = await validateToken(data);
          if (validatedToken.status === 201 && isMounted) {
            const token = validatedToken.data.token;
            const access = {
              code: "",
              realmId: integrationInfo.integrations[selectedIntegration].realmId,
              state: "",
              token: token,
            };
            dispatch({
              type: 'SET_QUICKBOOK_AUTH',
              payload: access,
            });
            setMsg("");
            setType("");
            setOpenMsg(false);
          }
        } catch (error) {
          if (isMounted) {
            setMsg("Please Re-Sync your integrations");
            setType("error");
            setOpenMsg(true);
          }
          console.error('Error validating token:', error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    validateAndSetToken();

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };
  }, [
    selectedIntegration,
    integrationInfo,
    integrations.quickbooks.access?.token?.access_token,
    dispatch
  ]);

  return (
    <>
      {Object.keys(integrationInfo).length === 0 || integrationInfo.integrations.length < 1 ? (
        <Navigate replace to='/company' />
      ) : (
        <div className="pl-5 pr-5 pt-3 pb-3">
          <div className="row">
            <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} button={true} />
            <div className="col-sm-2" style={{ minWidth: "235px" }}>
              <div>
                {integrationInfo && integrationInfo.integrations[selectedIntegration]?.companyName && (
                  <Card
                    style={{
                      borderRadius: 15,
                      margin: "0 auto",
                      padding: "10px",
                      boxShadow: "none"
                    }}
                  >
                    <CardContentNoPadding>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "text.primary",
                          fontSize: 15
                        }}
                      >
                        {integrationInfo.integrations[selectedIntegration].companyName}
                      </Typography>
                      <hr />
                      <Typography
                        gutterBottom
                        sx={{
                          color: "text.primary",
                          fontSize: 15
                        }}
                      >
                        {integrationInfo.integrations[selectedIntegration].integrationType}
                      </Typography>
                      <IntegrationsButton />
                    </CardContentNoPadding>
                  </Card>
                )}
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Card
                  style={{
                    borderRadius: 15,
                    margin: "0 auto",
                    padding: "10px",
                    boxShadow: "none"
                  }}
                >
                  <CardContentNoPadding>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "text.primary",
                        fontSize: 15
                      }}
                    >
                      Top 5 Metrics
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "text.primary",
                        fontSize: 10
                      }}
                    >
                      by Category
                    </Typography>
                    {dataTable && <TopFiveExpenses data={dataTable} loading={loading} />}
                    <div style={{ paddingLeft: "5%", paddingRight: "5%" }}></div>
                  </CardContentNoPadding>
                </Card>
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row">
                <div className="ui secondary pointing menu" style={{ width: "370px" }}>
                  <a
                    className={currentView === "Income Statement" ? "active item" : "item"}
                    onClick={() => setCurrentViewPage("Income Statement")}
                  >
                    Income Statement
                  </a>
                  <a
                    className={currentView === "Balance Sheet" ? "active item" : "item"}
                    onClick={() => setCurrentViewPage("Balance Sheet")}
                  >
                    Balance Sheet
                  </a>
                  <a
                    className={currentView === "Cash Flow" ? "active item" : "item"}
                    onClick={() => setCurrentViewPage("Cash Flow")}
                  >
                    Cash Flow
                  </a>
                </div>
              </div>
              <h2 style={{ color: 'rgba(0, 0, 0)', paddingLeft: "10px" }}>Condensed Consolidated Statements of Operations</h2>
              <div className="row mb-4" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr' }}>
                <div className="ui toggle checkbox mt-3">
                  <input
                    type="checkbox"
                    name="public"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label>Compare With</label>
                </div>
                <DatesRangeInput
                  name="datesRange"
                  placeholder="From - To"
                  iconPosition="left"
                  value={date}
                  dateFormat="YYYY-MM-DD"
                  onChange={handleDateChange}
                  style={{ width: "250px" }}
                />
                {isChecked && (
                  <DatesRangeInput
                    name="datesRange"
                    placeholder="From - To"
                    iconPosition="left"
                    value={date2}
                    dateFormat="YYYY-MM-DD"
                    onChange={handleDateChange2}
                    style={{ width: "250px" }}
                  />
                )}
                <button
                  className="ui primary button mr-3"
                  style={{ backgroundColor: "Black", width: "100px", height: "35px" }}
                  onClick={getLiveData}
                >
                  Apply
                </button>
              </div>
              <div>
                {loading ? (
                  <>Loading...</>
                ) : (
                  <>
                    {data && data.length < 2 && data2 && data2.length < 2 ? (
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          background: 'white',
                          border: '1px solid #d3d3d3',
                          borderRadius: '15px',
                          padding: '30px 50px 0px 50px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <p style={{ textAlign: "center" }}>Select a larger date range</p>
                      </div>
                    ) : (
                      <>
                        {!isChecked ? (
                          <div
                            style={{
                              background: 'white',
                              width: '100%',
                              height: '85%',
                              border: '1px transparent #000000',
                              borderRadius: '15px',
                              padding: '30px 50px 30px 50px',
                            }}
                          >
                            <div className="ui buttons pb-3">
                              <div
                                className="ui button"
                                style={{
                                  color: toggleValue === "$" ? "white" : "black",
                                  backgroundColor: toggleValue === "$" ? "#220FFE" : ""
                                }}
                                onClick={() => toggleSankeyView("$")}
                              >
                                $
                              </div>
                              <div
                                className="ui button"
                                style={{
                                  color: toggleValue === "%" ? "white" : "black",
                                  backgroundColor: toggleValue === "%" ? "#220FFE" : ""
                                }}
                                onClick={() => toggleSankeyView("%")}
                              >
                                %
                              </div>
                            </div>
                            <div>
                              <div className='row pt-3'>
                                <h1>
                                  {sankeyData && sankeyData.length > 2 && (
                                    <Chart
                                      chartType="Sankey"
                                      width="100%"
                                      height="600px"
                                      data={sankeyData}
                                      options={options}
                                    />
                                  )}
                                </h1>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              background: 'white',
                              width: '100%',
                              height: '85%',
                              border: '1px transparent #000000',
                              borderRadius: '15px',
                              padding: '30px 50px 30px 50px',
                            }}
                          >
                            <div className="ui buttons pb-3">
                              <div
                                className="ui button"
                                style={{
                                  color: toggleValue === "$" ? "white" : "black",
                                  backgroundColor: toggleValue === "$" ? "#220FFE" : ""
                                }}
                                onClick={() => toggleSankeyView("$")}
                              >
                                $
                              </div>
                              <div
                                className="ui button"
                                style={{
                                  color: toggleValue === "%" ? "white" : "black",
                                  backgroundColor: toggleValue === "%" ? "#220FFE" : ""
                                }}
                                onClick={() => toggleSankeyView("%")}
                              >
                                %
                              </div>
                            </div>
                            <div>
                              <div className='row pt-3'>
                                <div className='col-sm-6 pt-3'>
                                  {sankeyData && sankeyData.length > 2 && (
                                    <Chart
                                      chartType="Sankey"
                                      width="100%"
                                      height="600px"
                                      data={sankeyData}
                                      options={options}
                                    />
                                  )}
                                </div>
                                <div className='col-sm-6 pt-3'>
                                  {sankeyData2 && sankeyData2.length > 2 && (
                                    <Chart
                                      chartType="Sankey"
                                      width="100%"
                                      height="600px"
                                      data={sankeyData2}
                                      options={options}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="col-sm-12">
              <div
                className="pt-3 pl-3 pb-3 pr-3"
                style={{
                  background: 'white',
                  height: '100%',
                  width: '100%',
                  border: '1px transparent #000000',
                  borderRadius: '15px',
                  marginTop: '30px',
                  overflowX: "scroll"
                }}
              >
                {!loadingIncome && dataTable && dataTable.length < 2 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "200px",
                      background: 'white',
                      border: '1px solid #d3d3d3',
                      borderRadius: '15px',
                      padding: '30px 50px 0px 50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <p style={{ textAlign: "center" }}>Select a larger date range</p>
                  </div>
                ) : (
                  <>
                    <div
                      className="pt-3 pl-3 pb-3 pr-3"
                      style={{
                        background: 'white',
                        height: '100%',
                        width: '100%',
                        border: '1px transparent #000000',
                        borderRadius: '15px',
                        marginTop: '30px',
                        overflowX: "scroll"
                      }}
                    >
                      {currentView === "Income Statement" && (
                        <LoadingTable
                          loading={loadingIncome}
                          currentView={currentView}
                          data={dataTable}
                          headers={tableHeaders}
                          isChecked={isChecked}
                          compareData={dataTableCompare}
                          compareHeaders={tableHeaders2}
                        />
                      )}

                      {currentView === "Balance Sheet" && (
                        <LoadingTable
                          loading={loadingBalance}
                          currentView={currentView}
                          data={dataTable}
                          headers={tableHeaders}
                          isChecked={isChecked}
                          compareData={dataTableCompare}
                          compareHeaders={tableHeaders2}
                        />
                      )}

                      {currentView === "Cash Flow" && (
                        <LoadingTable
                          loading={loadingCashflow}
                          currentView={currentView}
                          data={dataTable}
                          headers={tableHeaders}
                          isChecked={isChecked}
                          compareData={dataTableCompare}
                          compareHeaders={tableHeaders2}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
