import { InputBase } from "@mui/material";
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from 'tss-react/mui';
import React from 'react';
import { useNavigate } from 'react-router-dom';


import { AuthContext } from '../../context/context';
import { getInputs } from '../../context/fetch-service';

import ExpenseInputs from './ExpenseInputs';
import GandAInputs from './GandAInputs';


const PREFIX = 'GandA';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  root2: `${PREFIX}-root2`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root2}`]: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const BootstrapInput = InputBase;

function GandA() {
  const history = useNavigate();

  const {
    state: {
      user, purchasing, inputs, data, isAuthenticated, switchUser, currentScenario,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const ganda = inputs.filter((i) => i.title === 'ganda')[0];
  const userSub = purchasing && purchasing.length > 0 ? purchasing.filter((sub) => sub.status === 'active') : [];

  const [chartValue, setChartValue] = React.useState('year');
  const handleChange = (event) => {
    setChartValue(event.target.value);
  };

  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [alertClass, setAlertClass] = React.useState(userSub && userSub.length > 0 && Date.parse(new Date()) < Date.parse(new Date(userSub[0].purchaseDate)) + (userSub[0].planType === 'purchased' ? 30 : 7) * 24 * 60 * 60 * 1000 ? '' : 'show');
  const [csvData, setCsvData] = React.useState('');

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const inputs = await getInputs(switchUser.userName, currentScenario);
      dispatch({
        type: 'SET_INPUTS',
        payload: inputs,
      });
    }
    fetchRevenue();
  }, [isAuthenticated, history, dispatch]);

  return (
    (<Root className="row">
      <div className="col-12 col-xl-12">

        <div className="col-8 col-xl-7">
          <h4>G & A Variables</h4>
          <GandAInputs ganda={ganda} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />
        </div>
        <div className="col-4 col-xl-5">
          <h4>Major Expense Variables</h4>
          {ganda && ganda._id && <ExpenseInputs gandaId={ganda._id} expenseInputs={ganda.majorExpenseInput} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />}
        </div>
      </div>
    </Root>)
  );
}

export default GandA;
