/* eslint-disable */

import React, { useEffect, useState } from 'react';
import 'semantic-ui-css/components/search.min.js'; // import Semantic UI Search JS
import 'semantic-ui-css/semantic.min.css'; // import Semantic UI CSS
import { Search } from 'semantic-ui-react';
import './scroll.css'

function SearchData({indices, content, addToList}) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState("");
  
  let titles = ["P&L", "Balance Sheet", "CashFlow"];
  const incomeStatement = content.slice(indices[0][0], indices[0][1]); 
  const balanceSheet = content.slice(indices[1][0], indices[1][1]); 
  const cashFlow = content.slice(indices[2][0], indices[2][1]);


  const combinedArray = [incomeStatement, balanceSheet, cashFlow];

  const source = [...Array(3).keys()].reduce((memo, index) => {
    //console.log(content)
    const name = titles[index]; 
    memo[name] = {
      name,
      results: combinedArray[index],
    };
    return memo;
  }, {});
  
  const handleSearchChange = (e, data) => {
    setValue(data.value)
    let filteredData = filterArrayByTitle(data.value, source)
    setResults(filteredData);
    setLoading(false);
  }

  function selectItem(data) {
    addToList(data.result);
    // 
    setValue("");
    setResults(filterArrayByTitle('', source));
  }

  function filterArrayByTitle(searchText, array) {
    const isMatch = (result) => result.title.toLowerCase().includes(searchText.toLowerCase());
    //console.log(array)
    const filteredArray = Object.entries(source).reduce((memo, [name, data]) => {
      const results = data.results.filter(isMatch);
      if (results.length) {
        memo[name] = { name, results };
      }
      return memo;
    }, {});
    //const filteredArray = array.filter(obj => obj.name.toLowerCase().includes(searchText.toLowerCase()));
    //console.log(filteredArray)

    return filteredArray;
  }

  // function filterArrayByTitle(searchText, array) {
  //   let flattenedArray = [];

  //   array.forEach(obj => {
  //     flattenedArray.push(obj);

  //     if (obj.items && obj.items.length > 0) {
  //       flattenedArray = flattenedArray.concat(obj.items);
  //     }
  //   });

  //   const filteredArray = flattenedArray.filter(obj =>
  //     obj.name.toLowerCase().includes(searchText.toLowerCase())
  //   );

  //   return filteredArray;
  // }

  // function filterArrayByTitle(searchText, array) {
  //   const filteredArray = array.filter(obj => {
  //     if (obj.name.toLowerCase().includes(searchText.toLowerCase())) {
  //       return true;
  //     }
  //     if (obj.items && obj.items.length > 0) {
  //       const filteredItems = obj.items.filter(item =>
  //         item.name.toLowerCase().includes(searchText.toLowerCase())
  //       );
  //       if (filteredItems.length > 0) {
  //         obj.items = filteredItems;
  //         return true;
  //       }
  //     }
  //     return false;
  //   });

  //   return filteredArray;
  // }

  useEffect(() => {
    let filteredData = filterArrayByTitle(value, content)
    setResults(filteredData);
  }, [content])

  return (
    (<div style={{ width: '95%', height: "100%" }}>
      <Search
        category
        input={{ fluid: true }} loading={loading}
        placeholder='Search...'
        onResultSelect={(e, data) =>
          selectItem(data)
        }
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
        style={{
          width: "100%",
          zIndex: "999",
          height: "100%",
        }}
        minCharacters={0}
        defaultOpen={true}
      > 
        {results.length > 5 ? (
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Search.Results
              results={results.slice(0, 5)} // display only the first 5 results
              onResultSelect={(e, data) => {
                selectItem(data)
                document.querySelector('.ui.search input').blur() // to close the dropdown after selecting a result
              }}
            />
          </div>
        ) : (
        (<Search.Results />) // display all results if there are 5 or fewer
        )}
      </Search>
    </div>)
  );
}

export default SearchData;
