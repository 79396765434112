import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  topBar: {
    position: 'absolute',
    left: '0%',
    right: '0%',
    top: '0%',
    bottom: '0%',
    paddingTop: '20px',
    background: '#F1F4F7',
  },

  card: {
    background: '#FFFFFF',
    borderRadius: '8px',
  },

  container: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    height: '100%',
    width: '90%',
    color: '#55616E',
  },

  navBar: {
    paddingTop: '40px',
    paddingLeft: '20px',
  },

  mainCard: {
    paddingTop: '30px',
    paddingBottom: '30px',

  },

  lineVertical: {
    marginTop: '10%',
    marginBottom: '5%',
    opacity: '0.1',
    borderLeft: '1px solid #000000',
    height: '90%',
  },

  buttonStyle: {
    backgroundColor: 'transparent', border: 'none', whiteSpace: 'normal', overflow: 'visible', textAlign: 'left', color: '#79A1DD', textTransform: 'none',
  },

  creditCard: {
    background: '#FFFFFF',
    height: '100%',
    width: '100%',
    border: ' 1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    paddingTop: '30px',
    paddingBottom: '30px',
  },

  teamPlanCard: {
    background: 'rgba(244, 244, 244, 0.3)',
    height: '100%',
    width: '100%',
    border: ' 1px transparent #000000',
    borderRadius: '10px',
    padding: '30px 50px 30px 50px',
  },

  teamPlan: {
    height: '100%',
    width: '100%',
    border: ' 1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: '20px',
  },

  table: {
    height: '100%',
    width: '100%',
    paddingLeft: '10px',
    paddingTop: '10px',
  },

}));

export { useStyles };
