import { Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import InputBase from '@mui/material/InputBase';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { makeStyles, withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import axios from '../../context/axios';
import Alerts from '../Misc/Alerts';
import LoadingBtn from '../Misc/LoadingBtn';

import { getTeams } from '../../context/team-service';

const PREFIX = 'InviteProject';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  closeButton: `${PREFIX}-closeButton`,
  buttonStyle: `${PREFIX}-buttonStyle`,
  btn: `${PREFIX}-btn`,
  formdiv: `${PREFIX}-formdiv`,
  nestdiv: `${PREFIX}-nestdiv`,
  invite_btn: `${PREFIX}-invite_btn`,
  datadiv: `${PREFIX}-datadiv`,
  buttonStyle2: `${PREFIX}-buttonStyle2`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.btn}`]: {
    color: '#aaa',
    margin: '0 0 0 0',

    '& svg': {
      fontSize: '2rem',
    },
  },
  [`& .${classes.formdiv}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.nestdiv}`]: {
    border: '2px solid #5abcdc',
    borderRadius: '10px',
    padding: '.5rem .5rem',
    '& input': {
      margin: '0 5rem 0 1rem',
    },
  },
  [`& .${classes.invite_btn}`]: {
    width: '15%',
    backgroundColor: '#220FFE',
    fontWeight: 'bold',
    fontFamily: 'cerebri sans',
    padding: '.5rem 3.5rem',
    borderRadius: '1rem',
    fontSize: '1rem',
    margin: '0 1rem',
  },

  [`& .${classes.datadiv}`]: {
    margin: '2.5rem 1rem',
  },
  [`& .${classes.buttonStyle2}`]: {
    backgroundColor: 'transparent', border: 'none', whiteSpace: 'normal', overflow: 'visible', textAlign: 'left', color: '#220FFE',
  },
});

const DialogTitle = ((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = MuiDialogContent;

const ShareStyles = makeStyles()({
  sharedata_container: {
    padding: '0 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    '& input': {
      margin: '0 3rem 0 1rem',
    },
  },
});

const ShareDiv = (props) => {
  const classes = ShareStyles();
  return (
    (<Root>
      <div style={{ margin: '1rem 0' }}>
        <div className={classes.sharedata_container}>
          <InputBase fullWidth value={props.data.email} />

          <Select
            value={props.data.access}
            name={props.data.email}
            onChange={props.modifyUser}
            disableUnderline
          >
            <MenuItem value="CAN_MODIFY">Can Edit</MenuItem>
            <ListSubheader>Can edit but not share with others.</ListSubheader>
            <MenuItem value="CAN_VIEW">Can View</MenuItem>
            <ListSubheader>Cannot edit or share with others.</ListSubheader>
          </Select>
          <div>
            <Button onClick={() => props.deleteUser(props.data._id)}>
              <DeleteForeverOutlinedIcon />
            </Button>
          </div>
        </div>
        <Divider />
      </div>
    </Root>)
  );
};

const InviteProject = (props) => {
  const classes = MStyles();
  const [open, setOpen] = React.useState(false);

  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);

  const [reload, setReload] = useState(true);
  const [enteredData, setEnteredData] = useState({
    email: '',
    access: 'CAN_VIEW',
  });

  let Profile = '';

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const url = '/getInvites';
        axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
        
        const res = await axios.post(url);
  
        // setInvitesData([...res.data.data[0].invited]);
      } catch (err) {
        console.error(err); // Handle error as needed
      }
    };
  
    fetchInvites();
  }, [reload]);
  
  const handleClickOpen = () => {
    setType('success');
    setMsg('');
    setOpenMsg(false);
    setOpen(true);
  };
  const handleClose = () => {
    setType('success');
    setMsg('');
    setOpenMsg(false);
    setOpen(false);
  };

  const enteringData = (data) => {
    const { name, value } = data.target;
    setEnteredData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const inviteUser = async () => {
    setProg(false);
    const url = '/inviteUser';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
    await axios
      .post(url, { projectId: props.projectId, email: enteredData.email, access: enteredData.access })
      .then((res) => {
        setType('success');
        setMsg(res.data.message);
      })
      .catch((err) => {
        setType('error');
        if (err.response && err.response.data) {
          setMsg(err.response.data.message);
        } else {
          setMsg(err.message);
        }
      });
    setOpenMsg(true);
    setProg(true);

    setEnteredData({
      email: '',
      access: 'CAN_VIEW',
    });
    props.fetchdata();
  };

  const [teamMembers, setTeamMembers] = React.useState([]);
  const fetchData = async () => {
    const teamMembersResponse = await getTeams();
    setTeamMembers(teamMembersResponse.data.data[0].teamMembers);

  };

  React.useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const RenderInvites = (props) => (
    <div>
      {props.map((members, i) => (
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          <div className="col-1">
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                marginRight: '2.5rem',
              }}
              src={members && members.profile ? `${members.profile}` : Profile}
            />
          </div>
          <div className="col-8" style={{ marginLeft: '30px' }}>
            <div
              className="text-lowercase"
            >
              <div className="row">
                {members.firstName + ' ' + members.lastName}
              </div>
              <div className="row">
                {members.email}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (<>
    <div className="share_container">
      <div>
        <button className={classes.buttonStyle} onClick={handleClickOpen}>
          + Invite
        </button>
        <Dialog variant="standard" onClose={handleClose} open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Share Project
          </DialogTitle>
          <Alerts msg={msg} type={type} openMsg={openMsg} setOpenMsg={setOpenMsg} />
          <DialogContent
            dividers
            classes={{
              root: classes.root
            }}>
            <TextField variant="standard" placeholder="Enter Email " label="Email" value={enteredData.email} name="email" onChange={enteringData} />
            <Select style={{ marginTop: '20px', marginLeft: '20px' }} variant="standard" value={enteredData.access} name="Access Level" label="Access" onChange={enteringData} disableUnderline>
              <MenuItem value="CAN_MODIFY">Can Edit</MenuItem>
              <ListSubheader>Can edit but not share with others.</ListSubheader>
              <MenuItem value="CAN_VIEW">Can View</MenuItem>
              <ListSubheader>Cannot edit or share with others.</ListSubheader>
            </Select>
            <LoadingBtn prog={prog} styleprop={classes.invite_btn} onclick={inviteUser} name="Invite" />

          </DialogContent>
          <DialogContent
            dividers
            classes={{
              root: classes.root
            }}>
            <div>
              <p> Select a Member</p>
              {teamMembers.length > 0
                ? (
                  <>
                    {RenderInvites(teamMembers)}
                  </>
                )
                : <p>Select Add New Members to invite members to your team!</p>}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  </>);
};

export default InviteProject;
