import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../../context/context";
import { SaaSBoardData, SaaSLayout } from "../../templates/SaaS";
import { eCommerceBoardData, eCommerceLayout } from "../../templates/eCommerce";
import { RealEstateBoardData, RealEstateLayout } from "../../templates/RealEstate";
import { GeneralBoardData, GeneralLayout } from "../../templates/General";
import { HealthcareBoardData, HealthcareLayout  } from "../../templates/Healthcare";
import realEstateImage from '../../assets/real-estate-graphic.svg';
import healthCareImage from '../../assets/healthcare-graphic.svg';
import saasImage from '../../assets/saas-graphic.svg';
import eCommerceImage from '../../assets/ecommerce-graphic.svg';
import generalImage from '../../assets/company-kpi.svg';
import addTemplate from '../../assets/add-template.svg';
import { Box, DialogContentText } from "@mui/material";
//import React from "react";
//import Button from "@mui/material/Button";
//import { AuthContext } from "../../context/context";
//import { Saas2Data, Saas2Layout } from "../../templates/Saas2";

/**
 * AddTemplate component for adding a pre-defined template to the board.
 *
 * @param {Function} updateBoardsToTemplate - Function to update the boards with template data
 * @returns {JSX.Element} A button to add a template or null if layout is not provided
 */
const AddTemplate = ({updateBoardsToTemplate}) => {
  // Access the isFullScreen state from the AuthContext
  const {
    state: { isFullScreen },
  } = React.useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const templates = [
    { name: "Company KPIs", data: GeneralBoardData, layout: GeneralLayout, image: generalImage},
    { name: "eCommerce", data: eCommerceBoardData, layout: eCommerceLayout, image: eCommerceImage  },
    { name: "SaaS Company KPIs", data: SaaSBoardData, layout: SaaSLayout, image: saasImage },
    { name: "Real Estate", data: RealEstateBoardData, layout: RealEstateLayout, image: realEstateImage },
    { name: "Healthcare", data: HealthcareBoardData, layout: HealthcareLayout, image: healthCareImage  },
    //{ name: "SaaS 2", data: Saas2Data, layout: Saas2Layout },
  ];

  const handleTemplateSelect = (template) => {
    updateBoardsToTemplate(template.data, template.layout);
    handleClose();
  };
  
  

  /**
   * Sets the board to a predefined SaaS template.
   * This function is called when the "Templates" button is clicked.
   */
  /*const setBoardToTemplate = () => {
    const newBoardData = SaaSBoardData;
    const newLayout = SaaSLayout;
    updateBoardsToTemplate(newBoardData, newLayout);
  };*/

  return (
    <>
      {updateBoardsToTemplate ? (
        <>
        <Box sx={{ marginBottom: "40px" }} className="d-flex flex-column justify-content-center text-center">

          <a
            className={`${
              isFullScreen ? "hide-in-fullscreen" : ""
            } m-3 p-3 third-step d-flex flex-column anchor-style`}
            onClick={handleOpen}
          >
            <img
              src={addTemplate}
              className="w-100 img-fluid"
              alt="Add Content"
            />
            <h3 className='m-1'>Use a Template</h3>
            <p style={{ width: "300px", textDecoration: "none", color: "#000" }}>
              Choose from five Datatrixs templates
            </p>
          </a>
          </Box>
          <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="md"
            PaperProps={{
              style: {
                width: '60%',
                height: '75%',
                maxWidth: 'none',
              },
            }}
          >
            <DialogTitle><h2>Use a Template</h2></DialogTitle>
            <DialogContentText>
              <p className="pb-4 px-4 mx-2">
              Datatrixs has a number of out-of-the-box, example reports that make it easy for anyone to get started.
              </p> 
            </DialogContentText>
            <DialogContent>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {templates.map((template, index) => (
                  <Card key={index} 
                  style={{ margin: '10px', cursor: 'pointer', width: '30%', minWidth: '300px', borderRadius: '10px'  }} 
                  onClick={() => handleTemplateSelect(template)}
                  className="col-md-4 m-2"
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={template.image}
                      alt={template.name}
                      className="img-fluid"
                    />
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {template.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Click to select this template
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>Nothing</>
      )}
    </>
  );
};

export default AddTemplate;
