/* eslint-disable */
import axios from './axios';

export const subscription = async (startTrial, seats, boards, subscriptionType, customerId, URL) => new Promise((resolve, reject) => {
  axios
    .post('/subscriptionNew', {
      startTrial, seats, boards, subscriptionType, customerId, URL
    })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});
export const userInvoices = async (customerId) => new Promise((resolve, reject) => {
  axios
    .get('/invoices', { params: { customerId } })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getPaymentInfo = async (customerId) => new Promise((resolve, reject) => {
  axios
    .get('/paymentInfo', { params: { customerId } })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const manageSubscription = async (customerId) => new Promise((resolve, reject) => {
  axios
    .get('/manageSubscription', { params: { customerId } })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const cancelSubscription = async () => new Promise((resolve, reject) => {
  axios
    .post('/cancel')
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});
