/* eslint-disable */

import React from "react";
import { Popup } from "semantic-ui-react";
import Alerts from "../../views/Misc/Alerts";
const AddFormulas = (props) => {


    const [formulaName, setFormulaName] = React.useState("");
    const [formula, setFormula] = React.useState("");

    function handleFormulaNameChange(e) {
        setFormulaName(e.target.value);
    }

    function handleFormulaChange(e) {
        setFormula(e.target.value);
    }

    function addToFormulas() {
        let data = {
            name: formulaName,
            formula: formula
        }
        props.addToListFormulas(data);
    }
    //     <button class="circular icon button ui"
    //     onClick={() => toggleSearch()} style={{ width: "40px", marginLeft: "100%" }}>
    //     <i class="icon plus"></i>
    // </button>


    return (
        <Popup
            trigger={
                <button class="circular icon button ui"
                    style={{ width: "40px", marginLeft: "100%" }}
                ><i class="icon plus"></i></button>}
            flowing on="click"
            position="bottom left">
            <div className="ui container" style={{ marginLeft: "300px", width: "300px", height: "300px" }}>
                <div className=" ui item">
                    <Alerts msg={props.msg} type={props.type} openMsg={props.openMsg} setOpenMsg={props.setOpenMsg} />

                    <p style={{ fontWeight: "bold" }}> Formula Name</p>
                    <div class="ui input" style={{ width: "100%" }}>
                        <input type="text" placeholder="Search..." value={formulaName} onChange={(e) => handleFormulaNameChange(e)} />
                    </div>
                    <p class="mt-5 " style={{ fontWeight: "bold" }}> Formula </p>
                    <div class="ui input" style={{ width: "100%" }}>
                        <input type="text" placeholder="(A/B)/100..." style={{ height: "100px", width: "100%" }} onChange={(e) => handleFormulaChange(e)} />
                    </div>
                </div>
                <div className="ui item mt-3">
                    <button className="ui primary right floated button mr-3" style={{ backgroundColor: "Black", width: "150px", height: "35px" }} onClick={addToFormulas}>
                        Add Formula
                    </button>
                </div>
            </div>
        </Popup>
    )
};

export default AddFormulas;