import React from 'react';
import { useNavigate } from 'react-router-dom';
import { set } from 'numeral';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { subscription, cancelSubscription } from '../../context/subscription-service';
import { AuthContext } from '../../context/context';
import { getUserPaymentMethods, getUserPurchasing } from '../../context/fetch-service';
import { addPayment } from '../../context/payment-service';
import './Pricing.scss';

const planlist = ['SaaS business Model', 'Input Variables', 'Charts', 'Reports', '7-day free trial'];

function Pricing() {
  const {
    meta, wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps,
  } = usePaymentInputs();
  const history = useNavigate();
  const {
    state: {
      user, billingDetails, purchasing, isAuthenticated,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const userSub = purchasing && purchasing.length > 0 ? purchasing : [];

  const [alertErrOpen, setAlertErrOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [loaderFor, setLoaderFor] = React.useState('');
  const [alertClass, setAlertClass] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [msg2, setMsg2] = React.useState('');
  const [err2, setErr2] = React.useState('');

  const monthlyPrice = 77;
  const yearlyPrice = 767;
  const [monthly, setMonthly] = React.useState(true);
  const [yearly, setYearly] = React.useState(false);

  const [paymentForm, setPaymentForm] = React.useState({
    city: '',
    state: '',
    address: '',
    cardNumber: '',
    expiryDate: '',
    cvc: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    setPaymentForm({
      address: '',
      city: '',
      state: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
    });
    setErr('');
    setMsg('');
    setErr2('');
    setMsg2('');
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const billings = await getUserPaymentMethods();
      dispatch({
        type: 'SET_BILLING',
        payload: billings,
      });
      const purchasing = await getUserPurchasing();
      dispatch({
        type: 'SET_PURCHASING',
        payload: purchasing,
      });
    }
    fetchRevenue();
  }, [isAuthenticated, history, dispatch]);

  
  const CancelSubscription = async (e) => {
    e.preventDefault();
    const subscriptionType = monthly ? 'INTRO_MONTHLY' : 'INTRO_YEARLY';
    try {
      const sub = await subscription(false, null, subscriptionType, purchasing.planId);
      if (sub.status === 200 || sub.status === 201) {
        window.location.href = sub.data.url;
      }
    } catch (err) {
      setAlertClass('show');
    }
  };

  const PurchaseSubscription = async (e) => {
    e.preventDefault();
    const subscriptionType = monthly ? 'INTRO_MONTHLY' : 'INTRO_YEARLY';
    try {
      const sub = await subscription(false, null, subscriptionType, purchasing[0].planId);
      if (sub.status === 200 || sub.status === 201) {
        window.location.href = sub.data.url;
      }
    } catch (err) {
      setAlertClass('show');
    }
  };

  const ToggleMonthly = () => {
    setMonthly(true);
    setYearly(false);
  };
  const ToggleYearly = () => {
    setMonthly(false);
    setYearly(true);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="page-headings text-center">Plan & Pricing</h1>
          <div className="mt-2 mb-2" />
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <div className="btn-group" style={{ align: 'center', marginBottom: '10px' }}>
                  <button onClick={ToggleMonthly} className={monthly ? 'MonthlyOn' : 'MonthlyOff'} type="button">
                    <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0px' }}>Pay Monthly</p>
                    <p className="mt-0 mb-0">Commit monthly</p>
                  </button>
                  <button onClick={ToggleYearly} type="button" className={yearly ? 'YearlyOn' : 'YearlyOff'}>
                    <span style={{ color: 'black', fontWeight: 'bold', marginBottom: '0px' }}>
                      Pay Upfront
                      <font style={{ color: 'green' }}>
                        &nbsp;SAVE
                        {Math.floor(((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100)}
                        %
                      </font>
                    </span>
                    <p className="mt-0 mb-0">Commit annualy</p>
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="plan-card-heading">
                  <h4>INTRO PLAN</h4>
                  <h1 className="plan-price">
                    {monthly && (
                      <span>
                        <span>
                          $
                          {monthlyPrice}
                        </span>
                        <span>/mo</span>
                      </span>
                    )}
                    {yearly && (
                      <span>
                        <span>
                          $
                          {yearlyPrice}
                        </span>
                        <span>/yr</span>
                        {' '}

                      </span>
                    )}
                  </h1>
                  {monthly && (
                    <p>
                      {' '}
                      billed
                      <font style={{ fontWeight: 'bold' }}>monthly</font>
                    </p>
                  )}
                  {yearly && (
                    <p>
                      {' '}
                      billed at
                      <font style={{ fontWeight: 'bold' }}>
                        {' '}
                        <font style={{ textDecoration: 'line-through' }}>
                          $
                          {monthlyPrice * 12}
                        </font>
                        {' '}
                        $
                        {yearlyPrice}
                        {' '}
                        yearly
                      </font>
                    </p>
                  )}

                </div>

                <div className="items-check-list">
                  <ul>
                    {planlist.map((list, id) => (
                      <li key={id}>
                        <div className="row">
                          <div className="col">
                            <h4>{list}</h4>
                          </div>
                          <div className="col-auto">
                            <span className="list-checks">
                              <i className="fe fe-check-circle" />
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mt-2" />
                <div className="mb-4 text-center">
                  
                  {userSub && userSub.length > 0 && userSub[0].status === 'active' && Date.parse(new Date()) < Date.parse(new Date(userSub[0].purchaseDate)) + 30 * 24 * 60 * 60 * 1000 ? (
                    <>
                      {userSub[0].planType === 'trial' && (
                        <button onClick={PurchaseSubscription} className="btn btn-custom btn-padd">
                          {loaderFor === 'purchase' && (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          Purchase
                        </button>
                      )}
                      <button onClick={CancelSubscription} className="btn btn-custom btn-padd">
                        {loaderFor === 'cancel' && (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        )}
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={PurchaseSubscription} className="btn btn-custom btn-padd">
                        {loaderFor === 'purchase' && (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        )}
                        Purchase
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pricing;
