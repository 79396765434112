/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import ConfirmRegistration from './Confirm';
import { useStyles } from './styles.js';
import { Box, Card, Typography, Button, useMediaQuery, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundImage from '../../assets/grid-3.svg';
import warren from '../../assets/product-warren.svg';

function SignUp() {
  const classes = useStyles();

  const [alertClass, setAlertClass] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [confirm, setConfrim] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState('password');
  const [signupForm, setSignupForm] = React.useState({
    email: '',
    cpassword: '',
  });

  const [passwordReqs, setPasswordReqs] = React.useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    lengthOf8: false,
  });

  const [showPasswordReqsPanel, setShowPasswordReqsPanel] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(false);

  const handleSignUp = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'password') {
      passwordValidator(value);
    } else {
      emailValidator(value);
    }
    setSignupForm((prevState) =>
    ({
      ...prevState,
      [name]: value,
    }));



  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const signup = await axios.post('/signup', signupForm);
      if (signup.status === 200 || signup.status === 201) {
        setErr('');
        setAlertClass('show');
        setSignupForm({
          email: signupForm.email,
          password: '',
        });
        setConfrim(true);
        setMsg('Successfully Registered');
        setLoader(false);
      }
    } catch (e) {
      setAlertClass('show');
      setMsg('');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else if (e.response.data.errors && e.response.data.errors.length > 0) {

          setErr(e.response.data.errors[0].msg);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };
  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  const handlePwdType = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  const showPasswordReqs = (e) => {
    e.preventDefault();
    setShowPasswordReqsPanel(true);
  };
  const hidePasswordReqs = (e) => {
    e.preventDefault();
    setShowPasswordReqsPanel(false);
  };

  const emailValidator = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(true);
    }
  };

  const passwordValidator = (password) => {
    let lowerCase = false;
    let upperCase = false;
    let number = false;
    let special = false;
    let lengthOf8 = false;
    if (password.match(/[a-z]/)) {
      lowerCase = true;
    }
    if (password.match(/[A-Z]/)) {
      upperCase = true;
    }
    if (password.match(/[0-9]/)) {
      number = true;
    }
    if (password.match(/(?=.*[!@#$%^&*])/)) {
      special = true;
    }
    if (password.length >= 8) {
      lengthOf8 = true;
    }

    setPasswordReqs({
      lowerCase, upperCase, number, special, lengthOf8,
    });
    setValidPassword(lowerCase && upperCase && number && special && lengthOf8);
  };

  const PasswordReqsPanel = (props) => (
    <div style={{
      borderStyle: 'solid', borderColor: '#CAD4D7', borderRadius: '5px', borderWidth: '1px', width: '250px', fontSize: '15px', position: 'fixed', backgroundColor: 'white', zIndex: '10',
    }}
    >
      <div className="row align-items-center justify-content-center">
        <p className="mt-3 ml-5">
          {props.passwordReqs.lowerCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One lowercase Character
        </p>
        <p className="ml-5">
          {props.passwordReqs.upperCase ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One uppercase character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.number ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One number
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.special ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          One special character
          {' '}
        </p>
        <p className="ml-5">
          {props.passwordReqs.lengthOf8 ? <i className="bi-check2-circle text-success mr-2" role="img" />
            : <i className="bi-x-circle text-danger mr-2" role="img" />}
          8 characters minimum
          {' '}
        </p>
      </div>
    </div>
  );

  return confirm ? (
    <ConfirmRegistration email={signupForm.email} alertClass={alertClass} setAlertClass={setAlertClass} setMsg={setMsg} msg={msg} err={err} setErr={setErr} loader={loader} setLoader={setLoader} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
          background: '#00396D',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      >
        {/* Signup Card */}
        <Card
          sx={{
            width: { xs: '100%', md: '70%' },
            padding: { xs: '30px', md: '60px' },
            backgroundColor: '#F8FAFC',
            boxShadow: 0,
          }}
        >
          <Box sx={{ 
            textAlign: 'left', 
            padding: '50px 10px',
            width: { xs: '80%', md: '50%' },
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>

            <Box sx={{ 
              textAlign: 'left',
              marginBottom: '100px',
            }}>
              <Link to="/">
                <img
                  src={LOGO}
                  alt="logo-black"
                  style={{ width: '130px', height: '20px', marginBottom: '30px' }}
                />
              </Link>
            </Box>

            <Typography
              variant="h2"
              sx={{
                fontWeight: 900,
                fontSize: { xs: '36px', md: '46px' },
                color: '#55616E',
              }}
            >
              Welcome to Datatrixs
            </Typography>
            <Typography sx={{ color: '#55616E', marginTop: 2 }}>
              Already have an account? <Link to="/login">Sign in</Link>.
            </Typography>

            <Typography sx={{ color: '#55616E', marginTop: 2, textAlign: 'center' }}>
              or
            </Typography>

            {msg && (
              <Box className={`alert alert-success alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{msg}</strong>
                <Button onClick={handleCloseAlert} type="button" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}
            {err && (
              <Box className={`alert alert-danger alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{err}</strong>
                <Button onClick={handleCloseAlert} type="button" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              {/* Email Input */}
              <TextField
                type="email"
                name="email"
                value={signupForm.email}
                onChange={handleSignUp}
                required
                placeholder="Email"
                fullWidth
                variant="outlined"
                margin="normal"
              />

              {/* Password Input */}
              <TextField
                type={passwordType}
                name="password"
                value={signupForm.password}
                onFocus={showPasswordReqs}
                onBlur={hidePasswordReqs}
                onChange={handleSignUp}
                required
                placeholder="Password"
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePwdType} edge="end">
                        {passwordType === 'password' ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {showPasswordReqsPanel && !validPassword && <PasswordReqsPanel passwordReqs={passwordReqs} />}
              
              <Button
                type="submit"
                disabled={loader || (!validPassword || !validEmail)}
                sx={{ backgroundColor: 'black', color: 'white', width: '100%', mt: 3 }}
              >
                {loader ? 'Loading...' : 'Sign up'}
              </Button>
              
              <Typography
                sx={{ color: '#6c757d', textAlign: 'center', mt: 2, fontSize: '0.875rem' }}
              >
                By clicking "Sign Up," you agree to Datatrixs's 
                <Link to="https://www.datatrixs.com/terms-of-service" target="_blank" rel="noreferrer"> Terms of Use </Link>
                and
                <Link to="https://www.datatrixs.com/privacy-policy" target="_blank" rel="noreferrer"> Privacy Policy</Link>.
              </Typography>
            </Box>
          </Box>
        </Card>

        {/* Warren Image */}
        <Card
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            width: '30%',
            padding: 3,
            boxShadow: 0,
            background: `url(${backgroundImage}) no-repeat`,
          }}
        >
          <img src={warren} alt="warren" style={{ maxWidth: '80%', height: 'auto' }} />
        </Card>
      </Box>
    </Box>
  );
}

export default SignUp;
