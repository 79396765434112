/* eslint-disable */

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/context";
import {
  accessToken,
  getIntegrationInfo,
  syncAllData,
} from "../../context/intuit-service";
import Spinner from "../Workstation/Helpers/Spinner";
import CompanyCard from "./CompanyCard";
import { CardContentNoPadding } from "./CustomStyles";
import Finish from "./Finish";
import Integrations from "./Integrations";
const steps = [
  { label: "company" },
  { label: "integrations" },
  { label: "finsih" },
];
export default function StepperView() {
  const { state, dispatch } = React.useContext(AuthContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = React.useState(parseInt(searchParams.get("tab") ?? 0));
  const [activeStep, setActiveStep] = React.useState(
    parseInt(
      searchParams.get("tab") ? parseInt(searchParams.get("tab")) - 1 : 0
    )
  );
  const [completed, setCompleted] = React.useState({});
  const [code, setCode] = React.useState(0);
  const [state_value, setStateValue] = React.useState(0);
  const [realmId, setRealmIdValue] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const history = useNavigate();

  const pushHistory = (value) => {
    history(`/company?tab=${value + 1}`);
  };

  useEffect(() => {
    const asyncFunc = async () => {
      if (searchParams.get("code")) {
        setLoading(true);
        setCode(searchParams.get("code"));
        setRealmIdValue(searchParams.get("realmid"));
        setStateValue(searchParams.get("state"));
        let url = `/getAuthToken?code=${searchParams.get(
          "code"
        )}&realmId=${searchParams.get("realmId")}&state=${searchParams.get(
          "state"
        )}`;
        const authToken = await accessToken(url);

        if (authToken.data.status === "200" || "201") {
          let token = authToken.data.token;
          let access = {
            code: searchParams.get("code"),
            realmId: searchParams.get("realmId"),
            state: searchParams.get("state"),
            token: token,
          };
          let data = {
            realmId: searchParams.get("realmId"),
            access_token: token.access_token,
            refresh_token: token.refresh_token,
          };
          let allData = await syncAllData(data);
          dispatch({
            type: "SET_QUICKBOOK_AUTH",
            payload: access,
          });
          let integrationInfoResponse = await getIntegrationInfo();
          dispatch({
            type: "SET_INTEGRATIONINFO",
            payload: integrationInfoResponse.data.integrations,
          });
        }
        setLoading(false);
      }
    };

    asyncFunc();
  }, [tab]);

  const handleStep = (step) => () => {
    setActiveStep(step);
    pushHistory(step);
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    pushHistory(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    pushHistory(activeStep - 1);
  };

  return (
    <Box className="mt-5" sx={{ width: "100%" }}>
      <h2
        style={{
          marginLeft: "42%",
          paddingBottom: "10px",
        }}
      >
        Let’s get your company set up
      </h2>
      <Stepper
        nonLinear
        activeStep={activeStep}
        style={{
          margin: "auto",
          width: "30%",
        }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
            ></StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          {loading ? (
            <Card
              className="mt-3"
              style={{
                borderRadius: 10,
                margin: "0 auto",
              }}
              sx={{ width: "1000px" }}
            >
              <CardContentNoPadding>
                <Spinner height={"300px"} />
              </CardContentNoPadding>
            </Card>
          ) : (
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              {activeStep === 0 && (
                <CompanyCard handleNext={handleNext} handleBack={handleBack} />
              )}
              {activeStep === 1 && (
                <Integrations handleNext={handleNext} handleBack={handleBack} />
              )}
              {activeStep === 2 && (
                <Finish
                  handleNext={handleNext}
                  handleBack={handleBack}
                  companyName={code != 0 ? "Quickbooks" : ""}
                />
              )}
            </Typography>
          )}
        </React.Fragment>
      </div>
    </Box>
  );
}
