/* eslint-disable */

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import { AuthContext } from '../../context/context';
import HiringEditButton from '../Scenarios/HiringEditButton';
import ScenarioButton from '../Scenarios/ScenarioButton';
import HiringEntry from './InputPanels/HiringEntry';
import HiringTable from './Table/HiringTable';

const Hiring = () => {
  const {
    state: {
      purchasing, inputs, revenues
    },
  } = React.useContext(AuthContext);

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <div className="row">
              <div className="col-sm-6" />
              <div className="col-sm-6">
                <div style={{ float: 'right', paddingTop: '15px', paddingBottom: '0px' }}>
                  <HiringEntry />
                  <ScenarioButton />
                  {revenues === null ? <div /> : <HiringEditButton />}
                </div>
              </div>
            </div>

          </Box>
          <CardContent style={{ padding: '0px' }}>
            <HiringTable inputs={inputs} />
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default Hiring;
