import React, { useEffect, useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "../../context/axios";
import Profile from "../../assets/profile.png";
import { AuthContext } from "../../context/context";
import LoadingBtn from "../Misc/LoadingBtn";
import SubscriptionPopup from "./SubscriptionPopup.js";

const InviteItem = React.memo(
  ({ invite, handleAccessChange, deleteUserInvite, prog }) => {
    return <div className="invite-item">{/* ... */}</div>;
  }
);

InviteItem.propTypes = {
  invite: PropTypes.shape({
    recipientId: PropTypes.string.isRequired,
    profile: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    access: PropTypes.string.isRequired,
  }).isRequired,
  handleAccessChange: PropTypes.func.isRequired,
  deleteUserInvite: PropTypes.func.isRequired,
  prog: PropTypes.bool.isRequired,
};

const InviteView = ({
  boardId,
  inviterefresh,
  setinviterefresh,
  setTotalAvailable,
}) => {
  const {
    state: { allBoards, selectedBoard },
  } = useContext(AuthContext);

  const [inviteData, setInviteData] = useState([]);
  const [totalEdits, setTotalEdits] = useState(3);
  const [currentEdits, setCurrentEdits] = useState(0);
  const [prog, setProg] = useState(true);
  const [showSubscription, setShowSubscription] = useState(false);

  const getBoards = useCallback(async () => {
    try {
      const response = await axios.get("/getInvitesBoards");
      const data = response.data.data;
      if (data && data.length > 0) {
        const filteredData = data[0].invited.filter(
          (invite) => invite.boardId === boardId
        );
        setInviteData(filteredData);

        const modifyAccessEntries = data.flatMap((entry) =>
          entry.invited.filter((item) => item.access === "CAN_MODIFY")
        );
        const distinctRecipientIds = [
          ...new Set(modifyAccessEntries.map((item) => item.recipientId)),
        ];
        setCurrentEdits(distinctRecipientIds.length);
      }
    } catch (error) {
      console.error("Error fetching invite data:", error);
    }
  }, [boardId]);

  useEffect(() => {
    getBoards();
  }, [getBoards]);

  useEffect(() => {
    if (inviterefresh) {
      setinviterefresh(false);
      getBoards();
    }
  }, [inviterefresh, setinviterefresh, getBoards]);

  useEffect(() => {
    setTotalAvailable(totalEdits - currentEdits);
    setShowSubscription(currentEdits > totalEdits);
  }, [totalEdits, currentEdits, setTotalAvailable]);

  const handleAccessChange = useCallback(
    async (event, recipientId) => {
      const newAccess = event.target.value;
      let previousAccess = "";

      setInviteData((prevData) => {
        const invite = prevData.find((inv) => inv.recipientId === recipientId);
        previousAccess = invite ? invite.access : "";
        return prevData;
      });

      if (
        newAccess === "CAN_MODIFY" &&
        previousAccess !== "CAN_MODIFY" &&
        currentEdits >= totalEdits
      ) {
        setShowSubscription(true);
        return;
      } else {
        setShowSubscription(false);
      }

      if (newAccess === "CAN_MODIFY" && previousAccess !== "CAN_MODIFY") {
        setCurrentEdits((prev) => prev + 1);
      } else if (newAccess === "CAN_VIEW" && previousAccess === "CAN_MODIFY") {
        setCurrentEdits((prev) => prev - 1);
      }

      try {
        await axios.post("/modifyUserInviteBoards", {
          recipientId,
          boardId: boardId,
          boardUserId: allBoards.boards[selectedBoard]._id,
          access: newAccess,
        });

        setInviteData((prevData) =>
          prevData.map((invite) =>
            invite.recipientId === recipientId
              ? { ...invite, access: newAccess }
              : invite
          )
        );
      } catch (error) {
        console.error("Error updating invite access:", error);
      }
    },
    [currentEdits, totalEdits, boardId, allBoards.boards, selectedBoard]
  );

  const deleteUserInvite = useCallback(
    async (recipientId) => {
      setProg(false);
      try {
        await axios.post("/deleteUserInviteBoards", {
          recipientId,
          boardId: boardId,
        });
        setProg(true);
        await getBoards();
      } catch (error) {
        console.error("Error deleting user invite:", error);
        setProg(true);
      }
    },
    [boardId, getBoards]
  );

  return (
    <div className="invite-view">
      <h3>Invites</h3>
      {inviteData.map((invite) => (
        <InviteItem
          key={invite.recipientId}
          invite={invite}
          handleAccessChange={handleAccessChange}
          deleteUserInvite={deleteUserInvite}
          prog={prog}
        />
      ))}
      <div className="invite-availability">
        <p>{totalEdits - currentEdits} Editors Available</p>
      </div>
      {showSubscription && (
        <div className="invite-subscription">
          <SubscriptionPopup />
        </div>
      )}
    </div>
  );
};

InviteView.propTypes = {
  boardId: PropTypes.string.isRequired,
  inviterefresh: PropTypes.bool.isRequired,
  setinviterefresh: PropTypes.func.isRequired,
  setTotalAvailable: PropTypes.func.isRequired,
};

export default InviteView;
