import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from 'tss-react/mui';
import { FormControl, InputBase, NativeSelect } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import { ButtonGroup, Button } from 'react-bootstrap';

import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import { getInputs } from '../../context/fetch-service';
import { AuthContext } from '../../context/context';
import { getYear, getQuarter, getMonthDetails } from '../../utils/utils';

import MarketingInputs from './MarketingInputs';
import ExpenseInputs from './ExpenseInputs';

const PREFIX = 'Marketing';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  root2: `${PREFIX}-root2`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root2}`]: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const BootstrapInput = InputBase;

function Marketing() {
  const history = useNavigate();

  const {
    state: {
      user, purchasing, inputs, data, isAuthenticated, switchUser, currentScenario,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const marketing = inputs.filter((i) => i.title === 'marketing')[0];

  const userSub = purchasing && purchasing.length > 0 ? purchasing.filter((sub) => sub.status === 'active') : [];

  const [chartValue, setChartValue] = React.useState('year');
  const handleChange = (event) => {
    setChartValue(event.target.value);
  };

  const [msg, setMsg] = React.useState('');
  const [err, setErr] = React.useState('');
  const [alertClass, setAlertClass] = React.useState(userSub && userSub.length > 0 && Date.parse(new Date()) < Date.parse(new Date(userSub[0].purchaseDate)) + (userSub[0].planType === 'purchased' ? 30 : 7) * 24 * 60 * 60 * 1000 ? '' : 'show');

  const [csvData, setCsvData] = React.useState('');

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
    async function fetchRevenue() {
      const inputs = await getInputs(switchUser.userName, currentScenario);
      dispatch({
        type: 'SET_INPUTS',
        payload: inputs,
      });
    }
    fetchRevenue();
  }, [isAuthenticated, history, dispatch]);

  return (
    (<Root className="row">
      <div className="col-12 col-xl-12">

        <div className="col-8 col-xl-7">
          <h4>Marketing Variables</h4>
          <MarketingInputs marketing={marketing} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />
        </div>
        <div className="col-4 col-xl-5">
          <h4>Major Expense Variables</h4>
          {marketing && marketing._id && <ExpenseInputs marketingId={marketing._id} expenseInputs={marketing.majorExpenseInput} setMsg={setMsg} setErr={setErr} setAlertClass={setAlertClass} />}
        </div>
      </div>
    </Root>)
  );
}

export default Marketing;
