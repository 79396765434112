/* eslint-disable */

import React from 'react';
import ReportsTable from './ReportsComponents/ReportsTable';
import ReportsTableCompare from './ReportsComponents/ReportsTableCompare'

function LoadingTable({ loading, currentView, data, headers, isChecked, compareData, compareHeaders }) {
    const isLoading = loading;
    return (
        <>
            {isLoading ? (
                <div className="table-responsive">
                    <table className="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(5)].map((_, index) => (
                                <tr key={index}>
                                    {headers.map((_, colIndex) => (
                                        <td key={colIndex}>
                                            <div className="loading-pulse" style={{ animationDelay: `${index * 0.2}s` }} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    {currentView && data !== null && (
                        <>
                            {!isChecked ? (
                                <ReportsTable toggleValue = {currentView} data={data} headers={headers} />
                            ) : (
                                <ReportsTableCompare data={compareData} headers={compareHeaders} />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default LoadingTable;
