export const formatMessageContent = (content) => {
  // Replace header tags with divs styled as headers
  content = content.replace(/<h2>(.*?)<\/h2>/g, '<div style="font-size: 1.5rem; font-weight: bold; margin-top: 1rem;">$1</div>');
  content = content.replace(/<h3>(.*?)<\/h3>/g, '<div style="font-size: 1.25rem; font-weight: bold; margin-top: 0.75rem;">$1</div>');

  // Replace unordered list items with styled divs
  content = content.replace(
    /<ul>(.*?)<\/ul>/gs, // Match everything within <ul>...</ul>, including line breaks
    (match, listItems) => {
      const formattedItems = listItems.replace(
        /<li>(.*?)<\/li>/g,
        '<div style="margin-left: 1.5rem; margin-top: 0.25rem;">• $1</div>'
      );
      return `<div style="margin: 0.5rem 0;">${formattedItems}</div>`;
    }
  );

  // Format numbers with commas and avoid formatting years
  content = content.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    (match, digit, offset) => (offset % 4 === 0 ? match : `${digit},`) // Skip formatting for years (assume 4-digit years)
  );

  return content;
};
