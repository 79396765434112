import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import * as React from 'react';
import GAandChurnEdit from './GAandChurnEdit';

export default function GAandChurnButton() {
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  const handleCloseBackDrop = () => {
    setBackDropOpen(false);
  };
  const handleToggleBackDrop = () => {
    setBackDropOpen(!backDropOpen);
  };

  return (
    <>
      <Button
        variant="text"
        style={{
          whiteSace: 'nowrap', minWidth: 'max-content', fontSize: '1rem', color: 'black', paddingLeft: '0px', paddingBottom: '0px',
        }}
        onClick={handleToggleBackDrop}
      >
        <p style={{ textTransform: 'none', marginBottom: '0px' }}><TuneIcon style={{ transform: 'rotate(90deg)', paddingBottom: '5px', marginBottom: '5px' }} /></p>
      </Button>
      {backDropOpen
        && <GAandChurnEdit backDropOpen={backDropOpen} handleCloseBackDrop={handleCloseBackDrop} />}
    </>
  );
}
