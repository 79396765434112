import OpenAI from "openai";

export function cosineSimilarity(vecA, vecB) {
  const dotProduct = vecA.reduce((sum, a, idx) => sum + a * vecB[idx], 0);
  const magnitudeA = Math.sqrt(vecA.reduce((sum, a) => sum + a * a, 0));
  const magnitudeB = Math.sqrt(vecB.reduce((sum, b) => sum + b * b, 0));
  return dotProduct / (magnitudeA * magnitudeB);
}


export const queryEmbeddings = async (userInput, embeddings, dataObjects) => {
    try {

      const openai = new OpenAI({
        apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
        dangerouslyAllowBrowser: true,
      });
  
      // Generate embedding for the query
      const queryEmbeddingResponse = await openai.embeddings.create({
        model: "text-embedding-3-small",
        input: userInput,
      });
  
      const queryEmbedding = queryEmbeddingResponse.data[0].embedding;
  
      // Compare with stored embeddings
      const similarities = embeddings.map((embedding, idx) => {
        return {
          similarity: cosineSimilarity(queryEmbedding, embedding),
          data: dataObjects[idx], // Match the data with its embedding
        };
      });
  
      // Sort results by similarity in descending order
      similarities.sort((a, b) => b.similarity - a.similarity);
  
      // Return top N results
      const topResults = similarities.slice(0, 5); // Adjust N as needed
      console.log("Top Results:", topResults);
  
      return topResults;
    } catch (error) {
      console.error("Error querying embeddings:", error);
      throw error;
    }
  };
  