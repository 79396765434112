/* eslint-disable */
import React, { useEffect, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@mui/material";
import { AuthContext } from "../../context/context";
import axios from "../../context/axios";

const File = ({ id, file, handleClickedFile, selectedFiles }) => {
  const {
    state: { uploadedFiles },
    dispatch,
  } = React.useContext(AuthContext);

  const [fileHover, setFileHover] = useState(false);

  // useEffect(() => {
  //    
  //    
  //    
  // }, []);

  const deleteFile = async (fileId) => {
    const data = {
      fileName: file.fileName,
    };
    try {
      const res = await axios.post("/deleteFile", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const newFiles = uploadedFiles.filter((f) => f.id !== fileId);
        dispatch({
          type: "SET_UPLOADED_FILES",
          payload: newFiles,
        });

        // Also remove from selectedFiles if it's selected
        handleClickedFile(fileId, true); // Pass a flag to indicate deletion
      }else if(res.status === 500){
        console.error("Unable to delete file: " + data.fileName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isSelected = selectedFiles.includes(id);
  // if(isSelected){
  //    
  // }

  return (
    <ListItem
      id={id}
      style={{
        margin: "10px 0px",
        backgroundColor: isSelected ? "#e0f7fa" : "#fff",
        borderRadius: "10px",
        cursor: "pointer",
      }}
      onClick={() => handleClickedFile(id)}
      onMouseEnter={() => setFileHover(true)}
      onMouseLeave={() => setFileHover(false)}
    >
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={file?.fileName ? file?.fileName : "Untitled"}
        secondary={null}
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the file selection
            deleteFile(id);
          }}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default File;
