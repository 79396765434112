/* eslint-disable */
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ForecastsPage from './components/ForecastsPage';
import MenuBar from './components/MenuBar';
import Overlay from './components/Overlay';
import TablePage from './components/TablePage';
import WorkStation from './components/Workstation/WorkStation';
import { AuthContext } from './context/context';
// import Reports from './views/Reports/Reports';
import AddBoard from './components/ClientPerformance/AddBoard';
import ClientPerformance from './components/ClientPerformance/ClientPerformance';
import EditBoard from './components/ClientPerformance/EditBoard';
import StepperView from './components/Stepper/StepperView';
import Reports from './components/Workstation/Reports';
import ConfirmRegistration from './views/Auth/Confirm';
import Login from './views/Auth/Login';
import SignUp from './views/Auth/SignUp';
import Invites from './views/Invite/Invites';
import Pricing from './views/Pricing/Pricing';
import Settings from './views/Settings/Settings';
import ResetPass from './views/Auth/ResetPass';
import { Navigate } from 'react-router-dom'

function FinproRoutes() {
  const {
    state: { 
      isAuthenticated
    },
    dispatch
  } = React.useContext(AuthContext);
  return isAuthenticated ? (
    <MenuBar>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to="/clientperformance" replace />}
        />
        <Route path="/workstation" element={<WorkStation />} />
        <Route path="/clientperformance" element={<ClientPerformance />} />
        <Route path="/AddBoard" element={<AddBoard />} />
        <Route path="/EditBoard/:board_id" element={<EditBoard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/company" element={<StepperView />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/" element={<ForecastsPage />} />
        <Route path="/table" element={<TablePage />} />
        <Route path="/invites" element={<Invites />} />
        <Route path="/overlay" element={<Overlay />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </MenuBar>
  ) : (
    <Routes>
      <Route exact path="/dashboard" element={<ForecastsPage />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/verify" element={<ConfirmRegistration />} />
      <Route exact path="/forgotpassword" element={<ResetPass />} />
      <Route path="*" element={<Navigate to='/login' />} />
    </Routes>
  );
}

export default FinproRoutes;
