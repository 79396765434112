import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  cssVariables: true,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                       "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", 
                       monospace, sans-serif`,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          backgroundColor: '#F8FAFC',
        },
        code: {
          fontFamily: `source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace`,
        },
        th: {
          firstChild: {
            position: 'sticky',
            left: '-15px',
            backgroundColor: '#f0f0f0',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#f4f4f4',
          color: '#000',
          '&:hover': {
            backgroundColor: '#1B1C1D',
            color: '#FFF'
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '0.3rem',
          },
          '& tbody': {
            backgroundColor: '#f4f4f4',
          },
          '& thead th': {
            color: '#000',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          },
          '& tbody tr th': {
            fontSize: '14px',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#00396D',
    },
    secondary: {
      main: '#79a1dd',
    },
    tertiary: {
      main: 'rgba(0, 0, 0, .6)',
    },
    background: {
      default: '#f4f4f4',
    },
    text: {
      primary: '#000',
      secondary: '#55616e',
    },
  },
  typography: {
    h1: {
      color: '#000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
