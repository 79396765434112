/*eslint-disable*/

import React, { useEffect } from 'react';
import ChartComponent from '../ChartComponent';

const callbacks = {
  formatCurrency: (value) => `$${value.toLocaleString()}`,
  formatPercentage: (value) => `${value.toLocaleString()}%`,
};

const GraphAssistantBoard = (props) => {
  useEffect(() => {
    // Ensure the chart config is updated with appropriate callbacks
    const updateConfigWithCallbacks = (config) => {
      if (
        config?.options?.scales?.y?.ticks &&
        typeof config.options.scales.y.ticks.callback === 'string'
      ) {
        const callbackName = config.options.scales.y.ticks.callback;
        if (callbacks[callbackName]) {
          config.options.scales.y.ticks.callback = callbacks[callbackName];
        }
      }
    };

    // Update the config for the first boardData entry
    if (props.board?.boardData?.[0]?.config) {
      updateConfigWithCallbacks(props.board.boardData[0].config);
    }
  }, [props]);

  return (
    <>
      {props.board?.boardData?.[0]?.config && (
        <ChartComponent
          config={props.board.boardData[0].config}
          chartId={props.id}
          setRef={props.setRef}
        />
      )}
    </>
  );
};

export default GraphAssistantBoard;

