/* eslint-disable */
function createHiringData(Inputs) {
  let fullData = [];
  let months = 12;
  for (let input of Inputs) {
    let inputValues = input.inputs;
    for (let values of inputValues) {
      let i = 1;
      let monthsCount = months;
      let amounts = [];
      amounts.push(parseInt(values.salary / 12));
      while (monthsCount > 1) {
        amounts.push(amounts[i - 1] + parseInt(values.salary) / 12);
        i += 1;
        monthsCount -= 1;
      }
      values.amounts = amounts;
    }
    let fullDataRow = {
      model: input.title,
      categoriesValue: inputValues,
    }
    fullData.push(fullDataRow);
  }
  for (let data of fullData) {
    let compoundValues = [];
    for (let i = 0; i < months; i += 1) {
      let compoundVal = 0;
      for (let category of data.categoriesValue) {
        compoundVal += category.amounts[i];
      }
      compoundValues.push(compoundVal);
    }
    data.compoundValues = compoundValues;

  }

  fullData.colSpan = months + 1;
  return fullData;
}

function createRevenueData(revenue) {
  let revenues = revenue.revenuInputs;
  let Months = 12;
  let data = [];
  let categories = [];
  for (let revenue of revenues) {
    if (!categories.includes(revenue.model)) {
      categories.push(revenue.model);
    }
  }

  for (let category of categories) {
    let categoryArray = revenues.filter((item) => item.model === category);
    let newRow = {
      model: category,
      categoriesValue: categoryArray,
    };
    data.push(newRow);
  }

  data = getMontlyValues(data, revenue.grateMonthly);
  return data;
}

function getMontlyValues(rows, ga) {
  let months = 12;
  for (let row of rows) {
    for (let category of row.categoriesValue) {
      let values = [];
      let difference = getMonthDifference(new Date(category.startDate), new Date(category.endDate));
      let monthsCount = months - 1;
      let i = 1;
      let customers = parseInt(category.customers);
      if (isNaN(customers)) {
        customers = 1;
      }

      values.push(parseInt(category.price) * customers);
      while (monthsCount > 0) {
        let tempvalue = (values[i - 1] + parseInt(category.price) * customers);
        if (ga) {
          if (ga > 0) {
            tempvalue = Math.round(tempvalue + tempvalue * ((ga / 100)));
          }
        }
        values.push(tempvalue);
        i += 1;
        monthsCount -= 1;
      }
      category.amounts = values;
    }
  }

  for (let row of rows) {
    let compoundValues = [];
    for (let i = 0; i < months; i += 1) {
      let compoundVal = 0;
      for (let category of row.categoriesValue) {
        compoundVal += category.amounts[i];
      }
      compoundValues.push(compoundVal);
    }
    row.compoundValues = compoundValues;
  }
  rows.colSpan = months + 1;
  return rows;
}

function getMonthDifference(startDate, endDate) {
  return (
    endDate.getMonth()
    - startDate.getMonth()
    + 12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const stateTaxes = {
  Alabama: '5.00',
  Alaska: '0.00',
  Arizona: '4.50',
  Arkansas: '6.60',
  California: '13.30',
  Colorado: '4.63',
  Connecticut: '6.99',
  Delaware: '6.60',
  Florida: '0.00',
  Georgia: '5.75',
  Hawaii: '11.00',
  Idaho: '6.93',
  Illinois: '4.95',
  Indiana: '3.23',
  Iowa: '8.53',
  Kansas: '5.70',
  Kentucky: '5.00',
  Louisiana: '6.00',
  Maine: '7.15',
  Maryland: '5.75',
  Massachusetts: '5.00',
  Michigan: '4.25',
  Minnesota: '9.85',
  Mississippi: '5.00',
  Missouri: '5.40',
  Montana: '6.90',
  Nebraska: '6.84',
  Nevada: '0.00',
  'New Hampshire': '0.00',
  'New Jersey': '10.75',
  'New Mexico': '4.90',
  'New York': '8.82',
  'North Carolina': '5.25',
  'North Dakota': '2.90',
  Ohio: '4.80',
  Oklahoma: '5.00',
  Oregon: '9.90',
  Pennsylvania: '3.07',
  'Rhode Island': '5.99',
  'South Carolina': '7.00',
  'South Dakota': '0.00',
  Tennessee: '0.00',
  Texas: '0.00',
  Utah: '4.95',
  Vermont: '8.75',
  Virginia: '5.75',
  Washington: '0.00',
  'West Virginia': '6.50',
  Wisconsin: '7.65',
  Wyoming: '0.00',
  DC: '8.95',
};


function createHiringPlanSankey(hiringData) {
  let data = [
    ["From", "To", "Weight"],
  ]
  for (let i = 0; i < hiringData.length; i = i + 1) {
    let item = hiringData[i];
    let xName = item.model;
    let yValues = item.categoriesValue;
    for (let j = 0; j < yValues.length; j = j + 1) {
      let yVal = yValues[j];
      let yName = yVal.role;
      let totalVal = yVal.salary;
      let dataRow = [xName, yName, parseInt(totalVal)];
      data.push(dataRow);
    }
  }


  return data;
}

export { createHiringData, createHiringPlanSankey, createRevenueData, formatter, getMonthDifference, stateTaxes };
