/* eslint-disable */
import { Typography } from "@mui/material";
import React from "react";
import { convertToKMB } from "./Helpers/DataHelper";

function sortExpenses(data) {
    let condensedRows = data;
    // if (condensedRows[0].totalAmount) {
    condensedRows = condensedRows.sort((a, b) => b.totalAmount - a.totalAmount);
    //   } else {
    //     condensedRows = condensedRows.sort((a, b) => b.amount[0] - a.amount[0]);
    //   }

    // condensedRows = condensedRows.sort((a, b) => b.totalAmount - a.totalAmount);
    return condensedRows.slice(0, 5);
}

export default function TopFiveExpenses(props) {
    const [topFiveExpenseValues, setTopFiveExpenseValues] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(props.loading); // Add a loading state

    React.useEffect(() => {
        setTimeout(() => {
            let expenses = sortExpenses(props.data);
            setTopFiveExpenseValues(expenses);
        }, 1000); // Simulate delay for loading (adjust as needed)
    }, [props]);

    return (
        (<div>
            {props.loading ? (
                // Show loading indicator while data is loading
                (<div className="loading-container">
                    <div className="loading-row" />
                    <div className="loading-row" />
                    <div className="loading-row" />
                    <div className="loading-row" />
                </div>)
            ) : (
                // Render data when loading is complete
                (topFiveExpenseValues.map((expense, i) => (
                    <div
                        key={i}
                        style={{
                            background: 'rgba(244, 244, 244, 1)',
                            border: '1px transparent #000000',
                            borderRadius: '10px',
                            padding: '10px 10px 10px 10px ',
                            marginBottom: "5px"
                        }}>
                        <Typography
                            style={{ color: "black" }}
                            gutterBottom
                            sx={{
                                color: "text.secondary",
                                fontSize: 15
                            }}>
                            {expense.name}
                        </Typography>
                        <Typography
                            sx={{ fontSize: 15 }} style={{ color: "blue" }} gutterBottom>
                            {convertToKMB(expense.totalAmount)}
                        </Typography>
                    </div>
                )))
            )}
        </div>)
    );
}
