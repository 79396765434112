const GeneralBoardData = [
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Customer Retention Rate (%)",
                  "data": [
                    90.14,
                    63.68,
                    34.56,
                    35.57,
                    72.87,
                    89.81,
                    88.82,
                    78.21,
                    64.56,
                    9.33,
                    17,
                    89.96
                  ],
                  "backgroundColor": [
                    "rgba(44, 62, 80, 1)",
                    "rgba(52, 73, 94, 1)",
                    "rgba(41, 128, 185, 1)",
                    "rgba(39, 174, 96, 1)",
                    "rgba(192, 57, 43, 1)",
                    "rgba(142, 68, 173, 1)",
                    "rgba(22, 160, 133, 1)",
                    "rgba(243, 156, 18, 1)",
                    "rgba(211, 84, 0, 1)",
                    "rgba(127, 140, 141, 1)",
                    "rgba(149, 165, 166, 1)",
                    "rgba(52, 152, 219, 1)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "type": "linear",
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "offset": true,
                  "grid": {
                    "offset": true,
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate bar charts for Customer Retention Rate (%)"
        }
      ],
      "boardFormulas": [],
      "_id": "675233da81e2a01f1291bdc8",
      "boardType": "graph",
      "title": "Customer Retention Rate (%)",
      "boardView": "graph",
      "index": 3,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Operating Income (in millions)",
                  "data": [
                    31.16,
                    10.67,
                    68.74,
                    44.58,
                    13.08,
                    50.02,
                    4.4,
                    91.02,
                    26.62,
                    66.59,
                    31.86,
                    52.49
                  ],
                  "borderColor": "rgba(44, 62, 80, 1)",
                  "backgroundColor": "rgba(44, 62, 80, 0.5)",
                  "borderWidth": 1,
                  "fill": true
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "ticks": {
                    "callback": "formatCurrency",
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "type": "category",
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate area charts for Operating Income (in millions)"
        }
      ],
      "boardFormulas": [],
      "_id": "6752345a81e2a01f1291bdc9",
      "boardType": "graph",
      "title": "Operating Income (in millions)",
      "boardView": "graph",
      "index": 4,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Debt-to-Equity Ratio",
                  "data": [
                    52.75,
                    43.33,
                    3.52,
                    11.68,
                    4.11,
                    64,
                    32.12,
                    51.35,
                    90.85,
                    25.68,
                    41.63,
                    75.8
                  ],
                  "borderColor": "rgba(75, 72, 192, 1)",
                  "backgroundColor": "rgba(75, 72, 192, 0.2)",
                  "borderWidth": 1,
                  "fill": true
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate area charts for Debt-to-Equity Ratio"
        }
      ],
      "boardFormulas": [],
      "_id": "6752329e81e2a01f1291bdc5",
      "boardType": "graph",
      "title": "Debt-to-Equity Ratio",
      "boardView": "graph",
      "index": 0,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>This template provides a clear and concise overview of essential financial metrics, including Revenue, Profit Margins, Cash Flow, Inventory Turnover, and ROI. It&rsquo;s designed to help you monitor performance, identify trends, and make informed decisions to drive growth and success.</p>\n<p>Empower your business with actionable insights at a glance!</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "6752351381e2a01f1291bdca",
      "index": 5,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Financial Pulse Tracker",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Cash Flow (in millions)",
                  "data": [
                    39.48,
                    27.86,
                    83.05,
                    36.32,
                    28.81,
                    54.73,
                    14.95,
                    80.42,
                    8.38,
                    98.7,
                    77.45,
                    20.67
                  ],
                  "borderColor": "rgba(75, 0, 130, 1)",
                  "backgroundColor": "rgba(75, 0, 130, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "category",
                  "labels": [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                  ],
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                }
              }
            }
          },
          "query": "Generate line chart for cash flow"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Cash Flow (in millions)",
      "boardView": "graph",
      "index": 6,
      "_id": "675a8de6ad4e003d3d386cbe",
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>The Debt-to-Equity Ratio is a critical financial metric that measures the proportion of a company's debt to its shareholders' equity. It reflects the company&rsquo;s financial leverage and its ability to meet long-term financial obligations. A balanced ratio indicates prudent financial management, while a high ratio may signal increased financial risk.</p>\n<p>In this section, we analyze the Debt-to-Equity Ratio over time and across industry benchmarks, providing insights into the company&rsquo;s financial stability and capital structure. This information is vital for evaluating risk, optimizing financial strategy, and ensuring sustainable growth.</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "675a8cccad4e003d3d386cbc",
      "index": 6,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Debt-to-Equity Ratio",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
              ],
              "datasets": [
                {
                  "label": "Revenue (in millions)",
                  "data": [
                    38.08,
                    95.12,
                    73.47,
                    60.27,
                    16.45,
                    16.44,
                    6.75,
                    86.75,
                    60.51,
                    71.1,
                    3.04,
                    97.02
                  ],
                  "borderColor": "rgba(75, 72, 192, 1)",
                  "backgroundColor": "rgba(75, 72, 192, 0.2)",
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate line chart for  Revenue (in millions)"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Revenue (in millions)",
      "boardView": "graph",
      "index": 6,
      "_id": "675a8f00ad4e003d3d386cbf",
      "invited": []
    }
  ]

const GeneralLayout = [
    {
      "w": 6,
      "h": 3,
      "x": 4,
      "y": 3,
      "i": "0",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 10,
      "h": 3,
      "x": 0,
      "y": 9,
      "i": "1",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 7,
      "h": 3,
      "x": 3,
      "y": 6,
      "i": "2",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 0,
      "i": "3",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "4",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 3,
      "x": 0,
      "y": 6,
      "i": "5",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "6",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    }
  ]
export { GeneralBoardData, GeneralLayout };