/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import { useStyles } from './styles.js';
import { Box, Card, Typography, Button, useMediaQuery, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundImage from '../../assets/grid-3.svg';
import warren from '../../assets/product-warren.svg';

function ConfirmRegistration({
  email, alertClass, setAlertClass, msg, setMsg, err, setErr, loader, setLoader,
}) {
  const [confirmationForm, setConfirmationForm] = React.useState({
    code: '',
  });

  const classes = useStyles();
  const [success, setSuccess] = React.useState(false);

  const handleConfirmForm = (e) => {
    const { name, value } = e.target;
    setConfirmationForm((prevState) =>
    ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const verify = await axios.post('/verify', { ...confirmationForm, email });
      if (verify.status === 200 || verify.status === 201) {
        setErr('');
        setAlertClass('show');
        setConfirmationForm({
          code: '',
        });
        setMsg('Successfully Registered');
        setSuccess(true);
        setLoader(false);
      }
    } catch (e) {
      setAlertClass('show');
      setMsg('');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else if (e.response.data.errors && e.response.data.errors.length > 0) {

          setErr(e.response.data.errors[0].msg);
        } else {
          setErr(e.response.data.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };
  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
    setMsg('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
          background: '#00396D',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      >
        <Card
          sx={{
            width: { xs: '100%', md: '70%' },
            padding: { xs: '30px', md: '60px' },
            backgroundColor: '#F8FAFC',
            boxShadow: 0,
          }}
        >
          <Box
            sx={{
              textAlign: 'left',
              marginBottom: '100px',
              padding: '50px 10px',
              width: { xs: '80%', md: '50%' },
              margin: '0 auto',
            }}
          >
            <Box sx={{ 
              textAlign: 'left',
              marginBottom: '100px',
              }}>
                
              <Link to="/">
                <img src={LOGO} alt="logo-black" style={{ width: '130px', height: '20px', marginBottom: '30px' }} />
              </Link>
            </Box>
            
            <Typography
              sx={{
                fontWeight: 'normal',
                fontSize: { xs: '20px', md: '24px' },
                lineHeight: '28px',
                textAlign: 'center',
                color: '#55616E',
                mb: 3,
              }}
            >
              Verify Your Account
            </Typography>

            {msg && (
              <Box className={`alert alert-success alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{msg}</strong>
                <Button onClick={handleCloseAlert} type="button" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}
            {err && (
              <Box className={`alert alert-danger alert-dismissible fade ${alertClass}`} role="alert">
                <strong>{err}</strong>
                <Button onClick={handleCloseAlert} type="button" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}

            {success ? (
              <Box textAlign="center" mt={3}>
                Your account has been successfully created!
                {' '}
                <Link to="/login">Please Login</Link>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                  label="Enter Confirmation Code"
                  name="code"
                  value={confirmationForm.code}
                  onChange={handleConfirmForm}
                  required
                  placeholder="e.g., 123456"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <Button
                  type="submit"
                  disabled={loader}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    width: '100%',
                    mt: 3,
                    '&:hover': {
                      backgroundColor: '#333',
                    }
                  }}
                >
                  {loader ? 'Loading...' : 'Verify'}
                </Button>
              </Box>
            )}
          </Box>
        </Card>

        {/* Warren Image */}
        <Card
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            width: '30%',
            padding: 3,
            boxShadow: 0,
            background: `url(${backgroundImage}) no-repeat`,
          }}
        >
          <img src={warren} alt="warren" style={{ maxWidth: '80%', height: 'auto' }} />
        </Card>
      </Box>
    </Box>
  );
}

export default ConfirmRegistration;