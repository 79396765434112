/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../context/context';
import { setListItemsParsed, setListItemsLine } from '../helpers'; // Ensure correct import

// Import Chart.js components for configuration
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AreaGraphBoard = (props) => {
  const {
    state: {
      integrations,
      integrationInfo,
      selectedIntegration,
      selectedBoard,
    },
    dispatch,
  } = useContext(AuthContext);

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    // Ensure props.board and props.board.boardData are defined before proceeding
    if (props.board && props.board.boardData) {
      setListItems(props.data, props.board.boardData, props.board.boardFormulas, props.headers);
    }
  }, [props.board, props.data]);

  const setListItems = (allData, data, formulas, headers) => {
    try {
      // Process data using helper function
      let flippedData = setListItemsLine(allData, data, formulas, headers);
      
      // Define a list of colors for the area chart
      const colors = [
        "rgba(43, 85, 133, 0.5)",
        "rgba(128, 51, 100, 0.5)",
        "rgba(75, 124, 92, 0.5)",
        "rgba(50, 130, 40, 0.5)",
        "rgba(12, 25, 54, 0.5)",
        "rgba(115, 40, 89, 0.5)",
        "rgba(78, 12, 99, 0.5)",
        "rgba(7, 64, 58, 0.5)",
        "rgba(152, 84, 72, 0.5)",
        "rgba(33, 92, 55, 0.5)",
        "rgba(21, 15, 41, 0.5)",
        "rgba(118, 45, 108, 0.5)",
        "rgba(14, 72, 140, 0.5)",
        "rgba(90, 144, 73, 0.5)",
        "rgba(151, 29, 87, 0.5)",
        "rgba(130, 111, 15, 0.5)",
        "rgba(97, 142, 103, 0.5)",
        "rgba(56, 51, 117, 0.5)",
        "rgba(40, 112, 88, 0.5)",
        "rgba(121, 62, 19, 0.5)",
        "rgba(138, 75, 93, 0.5)",
        "rgba(102, 19, 62, 0.5)",
        "rgba(24, 47, 75, 0.5)",
        "rgba(51, 89, 13, 0.5)",
        "rgba(134, 44, 50, 0.5)",
        "rgba(75, 31, 150, 0.5)",
        "rgba(47, 95, 125, 0.5)",
        "rgba(65, 105, 57, 0.5)",
        "rgba(100, 12, 82, 0.5)",
        "rgba(144, 62, 110, 0.5)"
      ];

      // Check if flippedData has sufficient data
      if (flippedData && flippedData.length > 1) {
        const headers = flippedData[0]; // First row as headers
        const dataRows = flippedData.slice(1); // Data rows excluding headers

        const labels = dataRows.map(row => row[0]); // x-axis labels from first column

        const datasets = [];

        // Iterate through each dataset
        for (let i = 1; i < headers.length; i++) {
          const datasetLabel = headers[i]; // Dataset labels from headers
          const dataPoints = dataRows.map(row => parseFloat(row[i]));

          const backgroundColor = colors[(i - 1) % colors.length];
          const borderColor = backgroundColor.replace('0.5', '1');

          datasets.push({
            label: datasetLabel,
            data: dataPoints,
            fill: true, 
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            tension: 0.5, 
          });
        }

        // Set chart data state
        setChartData({
          labels: labels,
          datasets: datasets,
        });

        // Set chart options state
        setChartOptions({
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    if (props.board.format === '$') {
                      label += '$' + context.parsed.y.toLocaleString();
                    } else {
                      label += context.parsed.y.toLocaleString() + '%';
                    }
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: props.headers ? props.headers.xAxis : '',
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: props.headers ? props.headers.yAxis : '',
              },
              ticks: {
                callback: function (value) {
                  if (props.board.format === '$') {
                    return '$' + value.toLocaleString();
                  }
                  return value.toLocaleString() + '%';
                },
                autoSkip: false,
              },
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
        });
      } else {
        // Handle case where result is empty or has insufficient data
        setChartData(null);
        setChartOptions(null);
      }
    } catch (error) {
      console.error('Error in setListItems:', error);
      setChartData(null);
      setChartOptions(null);
    }
  };

  useEffect(() => {
    // Ensure Chart.js code runs only on the client side
    if (typeof window !== 'undefined' && canvasRef.current && chartData && chartData.labels && chartOptions) {
      // Destroy existing chart instance if it exists to prevent duplication
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
  
      // Initialize new chart instance
      chartInstanceRef.current = new ChartJS(canvasRef.current, {
        type: 'line',
        data: chartData,
        options: chartOptions,
      });
    }
  
    // Cleanup function to destroy the chart instance on unmount or before re-initializing
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [chartData, chartOptions]);

  return (
    <>
      {chartData && chartData.labels && chartOptions && (
        <div style={{ width: '100%', height: '300px' }}>
          <canvas ref={canvasRef}/>
        </div>
      )}
    </>
  );
};

export default AreaGraphBoard;
