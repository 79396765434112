import axios from './axios';

export const getUser = async (token) => new Promise((resolve, reject) => {
  axios.defaults.headers.common.authorization = `${token}`;
  axios
    .get('/user')
    .then((user) => {
      resolve(user.data.user);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getUserPaymentMethods = async (token) => new Promise((resolve, reject) => {
  axios
    .get('/userPayment')
    .then((payment) => {
      resolve(payment.data.paymentMethods);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getUserPurchasing = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/userPurchasing', data)
    .then((purchasing) => {
      resolve(purchasing.data.purchasing);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getRevenue = async (sharedId, scenarioId) => new Promise((resolve, reject) => {
  axios
    .get('/userRevenue', { params: { sharedId, scenarioId } })
    .then((reve) => {
      resolve(reve.data.allRevenus);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getCompany = async (sharedId) => new Promise((resolve, reject) => {
  axios
    .get('/mycompany', { params: { sharedId } })
    .then((reve) => {
      resolve(reve.data.mycompany);
    })
    .catch((err) => {
      reject(err);
    });
});

export const fileSearchAssistant = async (userInput, date_change) => new Promise((resolve, reject) => {
  axios
    .post('/set-up-assistant', { input: userInput, date: date_change })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const uploadFiles = async (gptData) => new Promise((resolve, reject) => {
  axios
    .post('/upload-files', { gptData })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const fetchGraphAssistant = async (data) => new Promise((resolve, reject) => {
  axios
    .post('/fetch-graph-assistant', { data })
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getProjects = async (sharedId) => new Promise((resolve, reject) => {
  axios
    .get('/getProjects', { params: { sharedId } })
    .then((projects) => {
      resolve(projects.data.projects);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getAllBoards = async (sharedId) => new Promise((resolve, reject) => {
  axios
    .get('/getAllBoards', { params: { sharedId } })
    .then((allBoards) => {
      resolve(allBoards.data.allBoards);
    })
    .catch((err) => {
      reject(err);
    });
});

export const updateGraphBoards = async (boardSchema) => new Promise((resolve, reject) => {
  axios
    .post('/updateGraphBoards', boardSchema)
    .then((boards) => {
      resolve(boards);
    })
    .catch((err) => {
      reject(err);
    });
});


export const getInputs = async (sharedId, scenarioId) => new Promise((resolve, reject) => {
  axios
    .get('/userInputs', { params: { sharedId, scenarioId } })
    .then((input) => {
      resolve(input.data.allInputs);
    })
    .catch((err) => {
      reject(err);
    });
});
