/* eslint-disable */

import React, { useState } from 'react';
import ReportsTable from '../../Workstation/ReportsComponents/ReportsTable';
import { doFormulas } from '../helpers';
export const options = {
    title: "",
};

const ParsedDataTable = (props) => {
    const [parsedDataTable, setParsedDataTable] = useState([])

    React.useEffect(() => {
        setListItems(props.data, props.board.boardData, props.board.boardFormulas)
    }, [props.board, props.data])

    const setListItems = (allData, data, formulas) => {
        const filteredArr2 = allData.filter(item2 => {
            return data.some(item1 => item1.name === item2.name);
        });

        const filteredArr3 = data.filter(item2 => {
            const matchedItem = allData.find(item1 => item1.name === item2.name);
            if (matchedItem) {
                // Assign the matchedItem to the 'object' field of item2
                item2.object = matchedItem;
                item2.object.amounts = item2.object.amount
                return true;
            } else {
                return false;
            }
        });
        let temp = doFormulas(filteredArr3, formulas);
        for (const item of formulas) {

            let tempItem = {
                "name": item.name,
                "title": item.name,
                "header": true,
                "headerVal": item.name,
                "amount": [
                    ...item.amounts
                ],
                "totalAmount": item.totalAmount
            }
            filteredArr2.push(tempItem);

        }


        setParsedDataTable(filteredArr2);
    }
    return (
        <>
            {parsedDataTable &&
                <ReportsTable data={parsedDataTable} headers={props.tableHeaders} toggleOff={true} />
            }
        </>
    );
};

export default ParsedDataTable;