import axios from './axios';

export const addProjects = async (sharedId, projectName, scenarioName) => new Promise((resolve, reject) => {
  axios
    .post('/addProjects', sharedId, projectName, scenarioName)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});
export const updateProject = async (projectId, projectName, currency, fiscalYear, formData) => new Promise((resolve, reject) => {
  axios
    .post('/updateProject', projectId, projectName, currency, fiscalYear, formData)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addScenario = async (sharedId, projectId, scenarioName) => new Promise((resolve, reject) => {
  axios
    .post('/addScenario', sharedId, projectId, scenarioName)
    .then((rev) => {
      resolve(rev);
    })
    .catch((err) => {
      reject(err);
    });
});
